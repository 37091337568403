import React, { useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

import { defineFlowStatusBasedOnScreensVerification } from "./helpers";
import { setFlowStatus, setScreenshotVerification } from "models/getVerifications";
import { IVerificationButtonPropsTypes } from "./interfaces";

import { Button } from "react-bootstrap";

import "./VerificationButton.scss";

const VerificationButton: React.FunctionComponent<IVerificationButtonPropsTypes> = ({
    isDisabled,
    isTypeFailed,
    screenshotVerificationData,
}) => {
    const handleVerification = useCallback(() => {
        const {
            screenshotId,
            screenshotName,
            flowId,
            taskId,
            comment,
            setScreenshotsVerificationData,
            screenshotsVerificationData,
            setIsAllowedToSave,
            screenshotsIds,
            setFlowPageStateFlowStatus,
            setIsTaskFinishedStateStatus,
        } = screenshotVerificationData;

        const screenshot = {
            task_id: taskId,
            screenshot_id: screenshotId,
            screenshot_status: isTypeFailed ? "rejected" : "approved",
            comment,
            flow_id: flowId,
        };

        if (isTypeFailed && !comment) {
            setIsAllowedToSave(false);
        } else {
            setIsAllowedToSave(true);
            setScreenshotVerification(screenshot)
                .then((result) => {
                    const { screenshot_status } = result.verified_screenshot;

                    const screenshotsVerification: {
                        [key: string]: {
                            status: string;
                            comment: string;
                            name: string;
                        };
                    } = {
                        ...screenshotsVerificationData,
                        [screenshotId]: {
                            status: screenshot_status,
                            comment,
                            name: screenshotName,
                        },
                    };

                    setScreenshotsVerificationData(screenshotsVerification);
                    const flowStatus = defineFlowStatusBasedOnScreensVerification(
                        screenshotsVerification,
                        screenshotsIds,
                    );

                    if (flowStatus) {
                        setFlowStatus({
                            flow_id: flowId,
                            flow_status: flowStatus,
                        })
                            .then((res) => {
                                setIsTaskFinishedStateStatus(res.task_status);
                            })
                            .catch((err) => {
                                console.error(err);
                            });

                        setFlowPageStateFlowStatus(flowStatus);
                    }
                })
                .catch((error: string) => {
                    // eslint-disable-next-line no-console
                    console.error(error);
                });
        }
    }, [isTypeFailed, screenshotVerificationData]);

    const verificationButton = (
        <Button
            className="VerificationButton"
            as="button"
            type="button"
            variant={isTypeFailed ? "danger" : "success"}
            disabled={isDisabled}
            onClick={handleVerification}>
            <FontAwesomeIcon size="lg" icon={isTypeFailed ? faTimes : faCheck} />
        </Button>
    );

    return verificationButton;
};
export default VerificationButton;
