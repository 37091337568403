import React, { useState, useRef, useCallback, useEffect } from "react";

import createDropdownOptionLink from "utils/createDropdownOptionLink";

import { IFlowExplorerDropdownPropsTypes } from "./interfaces";
import { IOptions } from "components/SearchInput/interfaces";

import SearchInput from "components/SearchInput";
import FlowExplorerDropdownItem from "components/FlowExplorerDropdownItem";
import { Dropdown } from "react-bootstrap";

import "./FlowExplorerDropdown.scss";

export const FlowExplorerDropdown: React.FunctionComponent<IFlowExplorerDropdownPropsTypes> = ({
    baseUrl,
    currentFlows,
    navigate,
}) => {
    const [filteredOptions, setFilteredOptions] = useState<IOptions[]>([]);

    const [searchText, setSearchText] = useState("");

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        setFilteredOptions(currentFlows);
    }, [currentFlows]);

    const toggleDropdown = useCallback(() => {
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen]);

    return (
        <div className="FlowExplorerDropdown">
            <Dropdown show={isDropdownOpen} onToggle={toggleDropdown}>
                <Dropdown.Toggle>
                    <span>Select Flow</span>
                </Dropdown.Toggle>
                <Dropdown.Menu ref={dropdownMenuRef}>
                    {currentFlows?.length > 9 && (
                        <SearchInput
                            options={currentFlows}
                            setFilteredOptions={setFilteredOptions}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            dropdownMenu={dropdownMenuRef}
                            isDropdownOpen={isDropdownOpen}
                        />
                    )}
                    {filteredOptions?.map(({ id, name }) => {
                        const linkUrl = baseUrl.concat(name);
                        const link = createDropdownOptionLink(linkUrl);

                        return (
                            <FlowExplorerDropdownItem
                                key={id}
                                navigate={navigate}
                                link={link}
                                searchText={searchText}
                                name={name}
                            />
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    );
};

export default FlowExplorerDropdown;
