import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import { getVerification } from "models/getVerifications";

import FlowNavigator from "utils/services/FlowNavigator";
import { removeAdditionalParametersFromSearch } from "utils/removeAdditionalParametersFromSearch";

import SearchBar from "components/SearchBar";
import BreadcrumbNavigationBreadcrumb from "./BreadcrumbsNavigationBreadcrumb";
import { Row, Col, Alert } from "react-bootstrap";

import "./BreadcrumbsNavigation.scss";

class BreadcrumbsNavigation extends Component {
    constructor(props) {
        super();
        this.state = { ...props };
        this.navigateToPreviousFlow = this.navigateToPreviousFlow.bind(this);
        this.navigateToNextFlow = this.navigateToNextFlow.bind(this);
        this.navigateTo = this.navigateTo.bind(this);
        this.handleFLowsNavigation = this.handleFLowsNavigation.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({ ...props });
    }

    componentDidMount() {
        if (!this.props.isComparison) {
            document.addEventListener("keydown", this.handleKeyDown);
            document.addEventListener("keyup", this.handleKeyUp);
        }
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("keyup", this.handleKeyUp);
    }

    handleKeyDown = (event) => {
        // keyCode 17 is Control
        if (event.keyCode === 17) {
            document.addEventListener("keydown", this.handleFLowsNavigation);
        }
    };

    handleKeyUp = (event) => {
        // keyCode 17 is Control
        if (event.keyCode === 17) {
            document.removeEventListener("keydown", this.handleFLowsNavigation);
        }
    };

    handleFLowsNavigation(event) {
        if (
            event.keyCode === 188 &&
            !FlowNavigator.flowIsFirst(this.state.crumbs, this.state.match.params.flowId)
        ) {
            // keyCode 188 is "<" (left angle bracket)
            this.navigateToPreviousFlow();
        } else if (
            event.keyCode === 190 &&
            !FlowNavigator.flowIsLast(this.state.crumbs, this.state.match.params.flowId)
        ) {
            // keyCode 190 is ">" (right angle bracket)
            this.navigateToNextFlow();
        }
    }

    navigateToPreviousFlow(event) {
        this.navigateTo(event, -1);
    }

    navigateToNextFlow(event) {
        this.navigateTo(event, 1);
    }

    navigateTo(event, delta) {
        // Prevents focus from being stuck
        if (event) {
            event.currentTarget.blur();
        }

        const flowId = FlowNavigator.getFlowId(
            this.state.crumbs,
            this.state.match.params.flowId,
            delta,
        );

        this.props.history.push({
            pathname: `/flow/${flowId}`,
            search: this.props.location.search,
        });
    }

    checkFlowVerification() {
        const flowId = FlowNavigator.getFlowId(this.state.crumbs, this.state.match.params.flowId);
        return getVerification(flowId).then((response) => {
            this.setState({
                flowStatus: response.status,
                commentText: response.comments === null ? "" : response.comments,
            });
        });
    }

    handleOpen() {
        this.setState({ verificationDropdownOpened: true });
    }

    handleClose(e) {
        const domNode = ReactDOM.findDOMNode(this.node);
        if (domNode !== null && !domNode.contains(e.target)) {
            this.setState({ verificationDropdownOpened: false });
        }
    }

    render() {
        const { crumbs = [], match, flowStatus } = this.state;
        const {
            isComparison,
            location,
            flow,
            taskId,
            currentFlowVerification,
            showSearch,
            isFlowVerificationLoading,
        } = this.props;

        const isFirst = !!(crumbs.length > 0 && !isComparison
            ? FlowNavigator.flowIsFirst(crumbs, match.params.flowId)
            : null);

        const isLast = !!(crumbs.length > 0 && !isComparison
            ? FlowNavigator.flowIsLast(crumbs, match.params.flowId)
            : null);

        const search = removeAdditionalParametersFromSearch(location.search);

        return (
            <>
                <Row className={`BreadcrumbsNavigation${isComparison ? " comparison" : ""}`}>
                    <Col>
                        <BreadcrumbNavigationBreadcrumb
                            isFirst={isFirst}
                            isLast={isLast}
                            search={`?${search}`}
                            showSearch={showSearch}
                            navigateToPreviousFlow={this.navigateToPreviousFlow}
                            navigateToNextFlow={this.navigateToNextFlow}
                            isTask={taskId}
                            flowStatus={flowStatus}
                            handleOpen={this.handleOpen}
                            crumbs={crumbs}
                            isComparison={isComparison}
                            flow={flow}
                            currentFlowVerification={currentFlowVerification}
                            isFlowVerificationLoading={isFlowVerificationLoading}
                        />
                        {showSearch ? <SearchBar {...this.props} /> : null}
                    </Col>
                </Row>

                {taskId && currentFlowVerification.comments ? (
                    <Row>
                        <Col>
                            <Alert variant="info" className="FlowVerificationComment">
                                <h5>Flow verification comment:</h5>
                                <p>{currentFlowVerification.comments} </p>
                            </Alert>
                        </Col>
                    </Row>
                ) : null}
            </>
        );
    }
}

export default withRouter(BreadcrumbsNavigation);
