import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { withRouter } from "react-router-dom";

import appState from "appState";

import { getLastTaskStatus } from "models/getTasks";
import { removeAdditionalParametersFromSearch } from "utils/removeAdditionalParametersFromSearch";
import Auth from "utils/services/Auth";

import Spacer from "components/Spacer";
import { Navbar, Nav } from "react-bootstrap";

const logoBadoo: string = require("../../public/logos/logo-badoo.svg");
const logoBumble: string = require("../../public/logos/logo-bumble.svg");
const logoFiesta: string = require("../../public/logos/logo-fiesta.svg");
const logoHon: string = require("../../public/logos/logo-hon.svg");
const logoCad: string = require("../../public/logos/logo-cad.svg");
const logoFruitz: string = require("../../public/logos/fruitz-logo.svg");

const LOGOS = {
    badoo: logoBadoo,
    bumble: logoBumble,
    fiesta: logoFiesta,
    hon: logoHon,
    chat_and_date: logoCad,
    fruitz: logoFruitz,
    maya: logoBumble,
};

class Navigation extends Component {
    _isMounted = false;

    constructor() {
        super();

        this.logout = this.logout.bind(this);
        this.state = { newTask: false, isUserManager: false };
    }

    override componentDidMount() {
        this._isMounted = true;
        this.checkTaskStatus();
    }

    override componentWillUnmount() {
        this._isMounted = false;
    }

    checkTaskStatus() {
        getLastTaskStatus().then(
            (status: { task: { status: string }; user_is_manager: boolean }) => {
                if (this._isMounted) {
                    if (status.task && status.task.status !== "finished")
                        this.setState({ newTask: status.task });
                    this.setState({ isUserManager: status.user_is_manager });
                }
            },
        );
    }

    logout() {
        Auth.logout(
            function () {
                this.props.history.push({
                    pathname: `/login`,
                    search: window.location.search,
                });
            }.bind(this),
        );
    }

    render() {
        // We don't really want "altFlow" parameter to stay in URL and be used in next comparison. So we remove it.
        const search = removeAdditionalParametersFromSearch(this.props.location.search);

        const selectedFilters = appState.get("flow");
        const product = selectedFilters ? selectedFilters.product : null;

        const podPattern = /%2C%22pod%22%3A%22\d+%22/;
        const removedPod = search.replace(podPattern, "");

        return (
            <>
                <Navbar variant="dark" bg="dark" collapseOnSelect={true}>
                    <Navbar.Brand>
                        <LinkContainer to={`/flows?${search}`}>
                            <Nav.Link href="#">
                                {product ? (
                                    <img src={LOGOS[product]} alt="logo" />
                                ) : (
                                    <span>LiveShots</span>
                                )}
                            </Nav.Link>
                        </LinkContainer>
                    </Navbar.Brand>

                    <Navbar.Toggle />
                    <Nav className="mr-auto">
                        <LinkContainer to={`/flows?${search}`}>
                            <Nav.Link href="#">Home</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to={`/flow-explorer?${removedPod}`}>
                            <Nav.Link href="#">Flow Explorer</Nav.Link>
                        </LinkContainer>
                        {this.state.isUserManager ? (
                            <LinkContainer to={`/taskmanager`}>
                                <Nav.Link href="#">Tasks list</Nav.Link>
                            </LinkContainer>
                        ) : null}
                        {this.state.newTask && this.state.newTask.id ? (
                            <LinkContainer to={`/task/${this.state.newTask.id}`}>
                                <Nav.Link href="#">Open task</Nav.Link>
                            </LinkContainer>
                        ) : null}
                    </Nav>
                    <Nav className="justify-content-end">
                        {Auth.isLoggedIn() ? (
                            <Nav.Link onClick={this.logout}>Logout</Nav.Link>
                        ) : null}
                    </Nav>
                </Navbar>

                <Spacer bottom="xlg" />
            </>
        );
    }
}

export default withRouter(Navigation);
