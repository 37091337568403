import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

import downloadImage from "utils/downloadImage";
import addProtocol from "utils/addProtocol";

import DropdownWithFilter from "components/DropdownWithFilter";
import Screenshot from "components/Screenshot";
import ScreenshotLexemes from "components/ScreenshotLexemes";
import { Col, Button } from "react-bootstrap";

class ScreenshotWithDropdown extends Component {
    constructor() {
        super();
        this.downloadImage = this.downloadImage.bind(this);
    }

    downloadImage() {
        const { screenshot } = this.props;
        downloadImage(
            screenshot.file,
            `${screenshot.name} - ${screenshot.locale} - ${screenshot.device}`,
        );
        window.gtag("event", "click", {
            event_label: "DownloadScreenshot",
        });
    }

    render() {
        const { screenshot, screens, onSelect, field, secondField } = this.props;
        const src = addProtocol(screenshot.file);

        const overlayItems = [
            <Button
                size="lg"
                key="download"
                title="Download"
                variant="dark"
                onClick={this.downloadImage}>
                <FontAwesomeIcon size="lg" icon={faDownload} />
            </Button>,
        ];

        const lexemes =
            screenshot.metadata && field === "locale" ? screenshot.metadata.lexemes : [];

        return (
            <Col xs={12} md={3} className="ScreenshotWithDropdown">
                <DropdownWithFilter
                    title="Language"
                    items={screens}
                    selected={screenshot[field]}
                    onSelect={onSelect}
                />
                <div className="screenshot-container">
                    <Screenshot
                        id={screenshot.id}
                        name={screenshot[field]}
                        info={screenshot[secondField]}
                        overlayItems={overlayItems}
                        src={src}
                        width={screenshot.width}
                        height={screenshot.height}
                        lexemes={lexemes}
                    />
                    <ScreenshotLexemes
                        lexemes={lexemes}
                        isExpandedDown={lexemes && lexemes.length}
                    />
                </div>
            </Col>
        );
    }
}

export default ScreenshotWithDropdown;
