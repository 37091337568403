import { get } from "../api";
import { cache } from "utils/cache";

import prettyBrand from "utils/prettyBrand";
import prettyPlatform from "utils/prettyPlatform";
import prettyLocale from "utils/prettyLocale";
import formatDate from "utils/formatDate";

const getFiltersCached = cache(function (selectedFilters) {
    return get("filters", selectedFilters).then((filters) => {
        const version = Object.keys(filters.version)
            .sort(sortVersion)
            .map((versionStr) => {
                let formatted_date = "";
                if (filters.version[versionStr] != null) {
                    formatted_date = ` (${formatDate(
                        filters.version[versionStr],
                        "dd/mm/yy HH:MM",
                    )})`;
                }
                return {
                    display: `${versionStr}${formatted_date}`,
                    value: versionStr,
                };
            });

        const locale = filters.locale
            .sort(sortLocale)
            .map(toDisplayValuePair)
            .map((item) => ({ ...item, display: prettyLocale(item.display) }));
        const platform = filters.platform
            .map(toDisplayValuePair)
            .map((item) => ({ ...item, display: prettyPlatform(item.display) }));
        const product = filters.product
            .map(toDisplayValuePair)
            .map((item) => ({ ...item, display: prettyBrand(item.display) }));
        const device = (filters.device || []).map(toDisplayValuePair);
        let pod;
        if (filters.pod) {
            pod = Object.keys(filters.pod)
                .map(toDisplayValuePair)
                .map((item) => ({ ...item, display: filters.pod[item.display] }));
        }

        return {
            ...filters,
            version,
            locale,
            platform,
            product,
            device,
            pod,
        };
    });
});

export function getFilters(selectedFilters) {
    return getFiltersCached(getFiltersRequest(selectedFilters));
}

function toDisplayValuePair(item) {
    return {
        display: item,
        value: item,
    };
}

export const getFiltersToCreateTask = cache(function () {
    return get(`filters/new_task`).then((filters) => {
        const locale = filters.locale
            .sort(sortLocale)
            .map(toDisplayValuePair)
            .map((item) => ({ ...item, display: prettyLocale(item.display) }));
        const platform = filters.platform
            .map(toDisplayValuePair)
            .map((item) => ({ ...item, display: prettyPlatform(item.display) }));
        const product = filters.product
            .map(toDisplayValuePair)
            .map((item) => ({ ...item, display: prettyBrand(item.display) }));
        const translator = Object.keys(filters.translator).map((key) => ({
            display: key,
            defaultLocale: filters.translator[key],
        }));
        return {
            ...filters,
            locale,
            platform,
            product,
            translator,
        };
    });
});

export const getLocalesToCreateTask = cache(function (product, platform) {
    return get(`filters/new_task_locales`, { product, platform }).then((locales) => {
        return locales
            .sort(sortLocale)
            .map(toDisplayValuePair)
            .map((item) => ({ ...item, display: prettyLocale(item.display) }));
    });
});

export const getFiltersForTCBuildId = cache(function (tcBuildId, selectedFilters) {
    return get(`filters/tc_build/${tcBuildId}`, selectedFilters);
});

export const getListOfTranslators = cache(function () {
    return get(`filters/translators_list`);
});

// Converts
// 0.5.1234-fdsdfsfdf to 0.5.1234
function removeShitFromVersion(versionStr) {
    return versionStr.replace(/[^0-9.]+/g, "").split(".");
}

function getFiltersRequest(filters) {
    const out = {};

    if (filters.platform) {
        out.platform = filters.platform;
    }

    if (filters.product) {
        out.product = filters.product;
    }

    if (filters.mode) {
        out.mode = filters.mode;
    }

    return out;
}

function sortVersion(v1, v2) {
    v1 = removeShitFromVersion(v1);
    v2 = removeShitFromVersion(v2);

    const maxLen = Math.max(v1.length, v2.length);

    for (let i = 0; i < maxLen; i++) {
        const part1 = parseInt(v1[i], 10) || 0;
        const part2 = parseInt(v2[i], 10) || 0;

        // Special case for 0.xxx versions to come last
        if (i === 0) {
            if (part1 === 0) {
                return 1;
            } else if (part2 === 0) {
                return -1;
            }
        }

        if (part1 !== part2) {
            return part2 - part1;
        }
    }

    return 0;
}

function sortLocale(a, b) {
    if (prettyLocale(a) < prettyLocale(b)) {
        return -1;
    }
    if (prettyLocale(a) > prettyLocale(b)) {
        return 1;
    }
    return 0;
}
