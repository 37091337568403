import React, { useCallback, useEffect, useRef, useState } from "react";

import { IFlowFlowPageFlowDescriptionFlowCommentPropsTypes } from "./interfaces";

import { Button, Form } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faComment, faCommentDots, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { deleteFlowComment, getFlowComment, saveFlowComment } from "models/getFlow";
import ErrorMessage from "components/ErrorMessage";
import makeTextLinksClickable from "utils/makeTextLinksClickable";

const FlowPageFlowDescriptionFlowComment: React.FunctionComponent<
    IFlowFlowPageFlowDescriptionFlowCommentPropsTypes
> = ({ flowId }) => {
    const commentRef = useRef<HTMLTextAreaElement | null>(null);
    const [isHiddenCommentTextField, setIsHiddenCommentTextField] = useState<boolean>(true);
    const [existingFlowComment, setExistingFlowComment] = useState<string>("");
    const [currentFlowComment, setCurrentFlowComment] = useState<string>("");
    const [commentError, setCommentError] = useState<string | null>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setCommentHeight = () => {
        if (commentRef.current) {
            commentRef.current.style.height = "auto";
            commentRef.current.style.height = `calc(${commentRef.current.scrollHeight}px + 0.375rem)`;
        }
    };

    useEffect(() => {
        setCommentError(null);
        getFlowComment(flowId)
            .then((result: { error: string; flow_comment: string }) => {
                if (result.error) {
                    setCommentError(result.error);
                } else {
                    setExistingFlowComment(result.flow_comment);
                    setCurrentFlowComment("");
                }
            })
            .catch((error) => {
                setCommentError(error.message);
            });
    }, [flowId]);

    useEffect(() => {
        setCommentHeight();
    }, [setCommentHeight]);

    const handleChange = useCallback((event) => {
        setCurrentFlowComment(event.target.value);
        if (event.target.value) {
            commentRef.current?.classList.remove("required");
        }
    }, []);

    const toggleCommentTextField = useCallback(
        (_) => {
            setIsHiddenCommentTextField(!isHiddenCommentTextField);
        },
        [isHiddenCommentTextField],
    );

    const handleCommentDelete = useCallback(
        (_) => {
            deleteFlowComment(flowId)
                .then((result) => {
                    console.log(result);
                    setExistingFlowComment(currentFlowComment);
                    setExistingFlowComment("");
                })
                .catch((error) => {
                    alert(error.error);
                });
        },
        [currentFlowComment, flowId],
    );

    const handleCommentSave = useCallback(
        (_) => {
            if (currentFlowComment) {
                saveFlowComment(flowId, currentFlowComment)
                    .then((result) => {
                        console.log(result);
                        setExistingFlowComment(currentFlowComment);
                        setCurrentFlowComment("");
                    })
                    .catch((error) => {
                        alert(error.error);
                    });
            } else {
                commentRef.current?.classList.add("required");
            }
        },
        [currentFlowComment, flowId],
    );

    let buttonColour = "secondary";

    if (isHiddenCommentTextField) {
        if (existingFlowComment) {
            buttonColour = "warning";
        } else {
            buttonColour = "info";
        }
    }

    return (
        <div className="FlowComment">
            {commentError ? (
                <ErrorMessage error={commentError} />
            ) : (
                <>
                    <Button
                        className="add-comment-button"
                        onClick={toggleCommentTextField}
                        type="button"
                        aria-label="Add a comment"
                        aria-pressed={isHiddenCommentTextField}
                        variant={buttonColour}>
                        <FontAwesomeIcon
                            size="lg"
                            icon={existingFlowComment ? faCommentDots : faComment}
                            aria-hidden="true"
                        />
                    </Button>
                    <Form
                        className={`flow-comment-field${
                            isHiddenCommentTextField ? " hidden" : ""
                        }`}>
                        {!!existingFlowComment ? (
                            <div className="flow-comment-static-text">
                                {makeTextLinksClickable(existingFlowComment)}
                            </div>
                        ) : (
                            <Form.Control
                                as="textarea"
                                ref={commentRef}
                                className="flow-comment-textarea"
                                placeholder="add comment for flow..."
                                aria-label="add comment for flow"
                                rows={1}
                                onChange={handleChange}
                                value={currentFlowComment}
                            />
                        )}

                        <Form.Group className="flow-comment-buttons">
                            {!existingFlowComment ? (
                                <Button
                                    className="save-comment-button"
                                    onClick={handleCommentSave}
                                    type="button"
                                    aria-label="Save your comment"
                                    variant="success">
                                    <FontAwesomeIcon size="lg" icon={faCheck} aria-hidden="true" />
                                </Button>
                            ) : (
                                <Button
                                    className="delete-comment-button"
                                    onClick={handleCommentDelete}
                                    type="button"
                                    aria-label="Delete your comment"
                                    variant="danger">
                                    <FontAwesomeIcon
                                        size="lg"
                                        icon={faTrashAlt}
                                        aria-hidden="true"
                                    />
                                </Button>
                            )}
                        </Form.Group>
                    </Form>
                </>
            )}
        </div>
    );
};

export default FlowPageFlowDescriptionFlowComment;
