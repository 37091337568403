import React, { useCallback, useRef } from "react";
import { ISearchInputTypes } from "./interfaces";

import "./SearchInput.scss";
import { FormControl } from "react-bootstrap";

export const SearchInput: React.FunctionComponent<ISearchInputTypes> = ({
    options,
    setFilteredOptions,
    searchText,
    setSearchText,
    dropdownMenu,
    isDropdownOpen,
}) => {
    const searchInputRef = useRef(null);

    const handleSearch = useCallback(
        (event) => {
            const searchTextValue = event.target.value;
            const filteredFlowsOptions = options.filter((option) =>
                option.name.toLowerCase().includes(searchTextValue.toLowerCase()),
            );

            if (filteredFlowsOptions.length !== options.length) {
                filteredFlowsOptions.sort((prev, next) =>
                    prev.name.toLowerCase().localeCompare(next.name.toLowerCase()),
                );
            }

            setSearchText(searchTextValue);
            setFilteredOptions(filteredFlowsOptions);
        },
        [options, setFilteredOptions, setSearchText],
    );

    const handleKeyDown = useCallback(
        (event: { keyCode: number; preventDefault: () => void }) => {
            if (event.keyCode === 40 && isDropdownOpen) {
                // 40 == "ArrowDown"
                event.preventDefault();
                if (dropdownMenu && dropdownMenu.current) {
                    const firstVisibleItem: HTMLElement | null = dropdownMenu.current.querySelector(
                        ".dropdown-item:not(.d-none)",
                    );
                    if (firstVisibleItem) {
                        firstVisibleItem.focus();
                    }
                }
            }
        },
        [dropdownMenu, isDropdownOpen],
    );
    return (
        <div className="SearchInput">
            <FormControl
                type="text"
                placeholder="Search..."
                value={searchText}
                onChange={handleSearch}
                autoFocus={true}
                onKeyDown={handleKeyDown}
                ref={searchInputRef}
            />
        </div>
    );
};

export default SearchInput;
