export const defineFlowStatusBasedOnScreensVerification = (
    verifiedScreens: {
        [key: number]: { comment: string; name: string; status: string };
    },
    screenshotsIds: number[],
): string => {
    let flowStatus = "";
    const allScreens = Object.entries(verifiedScreens);
    const taskVerifiedScreenshots = allScreens.filter(
        (screen) => screen[1].status && screenshotsIds.includes(Number(screen[0])),
    ); // because there could be screenshots from previous run
    // which had different ids and are not visible inside the task

    if (screenshotsIds.length === taskVerifiedScreenshots.length) {
        const isFlowApproved = taskVerifiedScreenshots.every(
            (screen) => screen[1].status === "approved",
        );

        flowStatus = isFlowApproved ? "approved" : "failed";
    } else {
        const isFlowInProgress = allScreens.some((screen) => screen[1].status);
        const isFlowReassigned = allScreens.some((screen) => screen[1].status === "");

        if (isFlowInProgress) {
            flowStatus = "in_progress";
            if (isFlowReassigned) flowStatus = "reassigned_in_progress";
        } else if (!isFlowInProgress && isFlowReassigned) {
            flowStatus = "reassigned";
        }
    }

    return flowStatus;
};
