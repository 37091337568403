import React from "react";

import formatDate from "utils/formatDate";
import { IFlowDateAddedPropsTypes } from "./interfaces";

import "./FlowDateAdded.scss";

const FlowDateAdded: React.FunctionComponent<IFlowDateAddedPropsTypes> = ({
    date,
    isFlowForTask,
}) => {
    let formattedDate = date;
    if (date) formattedDate = formatDate(date, "d mmm yyyy");

    const flowTaskClass = isFlowForTask ? " task-flow" : "";
    const flowAddedText = isFlowForTask ? "" : "Added: ";

    return (
        <p className={`FlowDateAdded${flowTaskClass} `}>
            {flowAddedText}
            <strong>{formattedDate || "Unknown"}</strong>
        </p>
    );
};
export default FlowDateAdded;
