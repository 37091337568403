import { cache } from "utils/cache";

const preloadImage = (url: string) => {
    return new Promise((resolve, reject) => {
        if (!url) {
            reject(new Error("No valid image url provided to load"));
            return;
        }

        const imageObj = new window.Image();

        imageObj.crossOrigin = "use-credentials";

        imageObj.onload = function () {
            let width = 0;
            let height = 0;

            // check image really loaded
            // naturalHeight + naturalWidth is preferred if supported
            if ("naturalHeight" in this) {
                if (this.naturalHeight + this.naturalWidth === 0) {
                    this.onerror();
                    return;
                }

                width = this.naturalWidth;
                height = this.naturalHeight;
            } else {
                // fall back to width + height
                if (this.width + this.height === 0) {
                    this.onerror();
                    return;
                }

                width = this.width;
                height = this.height;
            }

            resolve({ url, width, height, imageObj });
        };

        imageObj.onerror = function () {
            reject(new Error(`Image "${url}" not found`));
        };

        imageObj.src = url;
    });
};

export default cache(preloadImage);
