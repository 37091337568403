import React, { useState, useEffect, useRef, useCallback } from "react";
import ReactLoading from "react-loading";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import prettyLocale from "utils/prettyLocale";

import {
    handleSelectAllChange,
    setInitialSelectAllValue,
    createSelectedFiltersFromDefaultForEachTranslator,
} from "./helpers";
import { IFlowMultiChoosePropsTypes } from "./interfaces";

import SectionsList from "components/SectionsList";
import ModalWindow from "components/ModalWindow";
import Spacer from "components/Spacer";
import SelectAll from "components/SelectAll";
import { Card } from "react-bootstrap";

import "./FlowMultiChoose.scss";

const FlowMultiChoose: React.FunctionComponent<IFlowMultiChoosePropsTypes> = ({
    currentFilters,
    disabled,
    selectedFlowsForTaskCreation,
    handleChooseFlowsApplyButton,
}) => {
    const [translatorsFlows, setTranslatorsFlows] = useState<{ [key: string]: number[] }>({}); // I had to add types because linter had issues with translatorsFlows  type
    const [translatorsSelectedFlows, setTranslatorsSelectedFlows] = useState(
        selectedFlowsForTaskCreation,
    );
    const [areTranslatorAllFlowsSelected, setAreTranslatorAllFlowsSelected] = useState<{
        [key: string]: boolean;
    }>({});

    const [translatorsDefaultFilters, setTranslatorsDefaultFilters] = useState({});

    const createSelectedFiltersFromDefaultForEachTranslatorRef = useRef<(() => void) | null>(null);

    useEffect(() => {
        createSelectedFiltersFromDefaultForEachTranslatorRef.current = () =>
            createSelectedFiltersFromDefaultForEachTranslator(
                currentFilters,
                setTranslatorsDefaultFilters,
            );
    });

    useEffect(() => {
        if (createSelectedFiltersFromDefaultForEachTranslatorRef.current) {
            createSelectedFiltersFromDefaultForEachTranslatorRef.current();
        }
    }, [currentFilters]);

    const handleSelectAll = useCallback(
        (translator: string, input: { id: string; checked: boolean }, newFlows: number[]) => {
            if (input.id.includes("select-all")) {
                setAreTranslatorAllFlowsSelected((prevState) => ({
                    ...prevState,
                    [translator]: input.checked,
                }));

                const areAllFlowsSelected = input.checked ? translatorsFlows[translator] : [];
                setTranslatorsSelectedFlows((prevState) => ({
                    ...prevState,
                    [translator]: areAllFlowsSelected,
                }));
            } else {
                const areAllFlowsSelected = !!(
                    newFlows.length &&
                    JSON.stringify(translatorsFlows[translator].sort()) ===
                        JSON.stringify(newFlows.sort())
                );

                setAreTranslatorAllFlowsSelected((prevState) => ({
                    ...prevState,
                    [translator]: areAllFlowsSelected,
                }));
            }
        },
        [translatorsFlows],
    );

    const setInitialTranslatorsSelectedFlows = useCallback(
        (translatorFlowsData: { [key: string]: number[] }) => {
            const newObject: {
                [key: string]: number[];
            } = {};
            Object.keys(translatorFlowsData).forEach((translator) => {
                newObject[translator] = selectedFlowsForTaskCreation[translator] || [];
            });

            return newObject;
        },
        [selectedFlowsForTaskCreation],
    );

    const fillTranslatorsFlowsData = useCallback(
        (translatorFlowsData: { [key: string]: number[] }) => {
            setTranslatorsFlows((prevState) => ({ ...prevState, ...translatorFlowsData }));
            setTranslatorsSelectedFlows((prevState) => ({
                ...prevState,
                ...setInitialTranslatorsSelectedFlows(translatorFlowsData),
            }));
            setAreTranslatorAllFlowsSelected((prevState) => ({
                ...prevState,
                ...setInitialSelectAllValue(selectedFlowsForTaskCreation, translatorFlowsData),
            }));
        },
        [selectedFlowsForTaskCreation, setInitialTranslatorsSelectedFlows],
    );

    const fillTranslatorsSelectedFlowsData = useCallback(
        (translatorsSelectedFlowsData: { [key: string]: number[] }) => {
            setTranslatorsSelectedFlows((prevState) => ({
                ...prevState,
                ...translatorsSelectedFlowsData,
            }));
        },
        [],
    );

    const getSelectedFlowsFromSectionsList = useCallback(() => {
        handleChooseFlowsApplyButton(translatorsSelectedFlows);
    }, [handleChooseFlowsApplyButton, translatorsSelectedFlows]);

    const sectionsList = Object.keys(translatorsDefaultFilters)?.length ? (
        Object.entries(translatorsDefaultFilters).map(([objKey, objValue]) => {
            const translatorId = `-${objKey.toLowerCase().replaceAll(" ", "-")}`;
            const flowsSections = objValue.error || (
                <>
                    <ul className="selected-filters">
                        <li>{objValue.platform}</li>
                        <li>{objValue.product}</li>
                        <li>{objValue.device}</li>
                        <li className="locale">{prettyLocale(objValue.locale)}</li>
                    </ul>
                    <SelectAll
                        handleSelectAll={handleSelectAllChange(
                            objKey,
                            translatorsSelectedFlows[objKey],
                            handleSelectAll,
                        )}
                        checked={areTranslatorAllFlowsSelected[objKey]}
                        translatorId={translatorId}
                    />
                    <SectionsList
                        selectedFilters={objValue}
                        isChooseFlowsModalOpen={true}
                        search={`?flow=${JSON.stringify(objValue)}`}
                        handleSelectAll={handleSelectAll}
                        translator={objKey}
                        translatorId={translatorId}
                        translatorsFlows={translatorsFlows}
                        translatorSelectedFlows={translatorsSelectedFlows[objKey] || []}
                        fillTranslatorsFlowsData={fillTranslatorsFlowsData}
                        fillTranslatorsSelectedFlowsData={fillTranslatorsSelectedFlowsData}
                    />
                </>
            );

            return (
                <div key={objKey}>
                    <h2>{objKey}</h2>
                    <div className="flows-response">{flowsSections}</div>
                </div>
            );
        })
    ) : (
        <ReactLoading type="bubbles" color="#888" className="AbsoluteCentered" delay={300} />
    );

    const modalBody = (
        <>
            <Card body={true} bg="info">
                <FontAwesomeIcon size="lg" icon={faInfoCircle} /> Selecting
                <strong> custom flows</strong> means that the task will be created
                <strong> only for selected</strong> flows.
                <br />
                If <strong>nothing</strong> is selected for the translator - the task will include
                all flows by default.
            </Card>
            <Spacer bottom="xlg" />
            {sectionsList}
        </>
    );
    return (
        <ModalWindow
            modalButtonName="Choose flows"
            isModalButtonDisabled={disabled}
            modalSize="xl"
            modalTitle="Choose flows for translators"
            modalBody={modalBody}
            modalCustomClass="choose-flows"
            onApplyChanges={getSelectedFlowsFromSectionsList}
            buttonCustomClass="flow-selector-button flow-selector-button--choose"
        />
    );
};

export default FlowMultiChoose;
