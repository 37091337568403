import React, { Component } from "react";
import appState from "appState";

import { getFlowExplorerTree } from "models/getFlowExplorerTree";
import checkObjectsShallowEquality from "utils/checkObjectsShallowEquality";

import ModePanel from "components/ModePanel";
import ErrorMessage from "components/ErrorMessage";
import FlowSelector from "components/FlowSelector";
import FlowExplorerRow from "components/FlowExplorerRow";
import { Col, Container, Row } from "react-bootstrap";

class FlowExplorerPage extends Component {
    constructor() {
        super();
        this.navigate = this.navigate.bind(this);
        this.changeMode = this.changeMode.bind(this);

        this.state = {
            flows: [],
            showLexemes: appState.get("showLexemes") || false,
        };
    }

    reloadWithoutSearchParams = () => {
        const { location, history } = this.props;
        history.push({
            pathname: "/flow-explorer/",
            search: location.search,
        });
    };

    override componentDidMount() {
        if (this.props.location.search) this.fetchFlow(this.props);
    }

    override componentDidUpdate(prevProps: Record<string, any>) {
        if (this.props.location.search !== prevProps.location.search) {
            this.reloadWithoutSearchParams();
        } else if (
            !checkObjectsShallowEquality(prevProps, this.props) &&
            this.props.location.search
        ) {
            this.fetchFlow(this.props);
        }
    }

    fetchFlow(props) {
        const { match } = props;

        const { flows } = match.params;
        const queryParams = appState.get("flow");

        // Root flows
        const flowPromises = [getFlowExplorerTree("", queryParams)];

        // We have more flows to fetch so add them to the list
        if (flows && flows.length > 0) {
            flows.split(",").forEach((flowName) => {
                flowPromises.push(getFlowExplorerTree(flowName, queryParams));
            });
        }

        Promise.all(flowPromises)
            .then((flows) => {
                const { hasLexemes, flows: contextFlows } = getFlowsContext(flows);
                const selectedFlowNames = flows.map((flow) => {
                    return flow.selectedFlowName;
                });
                // If there is only one flow to choose from and we haven't selected it
                // we automatically navigate to the next available flow
                const lastFlow = contextFlows[contextFlows.length - 1];
                if (
                    lastFlow.flows.length === 1 &&
                    !selectedFlowNames.includes(lastFlow.flows[0].name)
                ) {
                    const nextFlowToLoad = lastFlow.flows[0];

                    this.props.history?.replace({
                        pathname: `/flow-explorer/${lastFlow.baseUrl
                            .concat(nextFlowToLoad.name)
                            .join(",")}`,
                        search: this.props.location.search,
                    });
                } else {
                    this.setState({
                        flows: contextFlows,
                        hasLexemes,
                        showLexemes: this.state.showLexemes && hasLexemes,
                        error: false,
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    flows: [],
                    error,
                });
            });
    }

    navigate(pathname: string) {
        window.gtag("event", "click", {
            event_label: "NextFlowScreenshot",
        });

        this.props.history.push({
            pathname,
            search: this.props.location.search,
        });
    }

    changeMode(props) {
        const { showLexemes } = props;

        this.setState({
            showLexemes,
        });
    }

    render() {
        const location = this.props.location;
        const search = location.search;
        const { flows, showLexemes, hasLexemes } = this.state;

        return (
            <Container fluid={true}>
                <Row>
                    <Col xs={12} md={2} className="StickyFlow">
                        <FlowSelector flowKey="flow" isFlowExplorer={true} />
                        <ModePanel hasLexemes={hasLexemes} onChange={this.changeMode} />
                    </Col>
                    <Col xs={12} md={10} className="FlowExplorerPage">
                        {this.state.error && <ErrorMessage error={this.state.error} />}

                        {flows.map((flow, idx) => (
                            <FlowExplorerRow
                                key={idx}
                                flow={flow}
                                search={search}
                                navigate={this.navigate}
                                showLexemes={showLexemes}
                            />
                        ))}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default FlowExplorerPage;

function getFlowsContext(flows) {
    // Used to get the URL for each level of the flow
    const baseUrl = [];

    let hasLexemes = false;

    const flowsContext = flows.map((flow, idx) => {
        const { selectedFlowName, foundFlows } = flow;

        // If no flow name is selected it's just the root one which we don't care about
        if (selectedFlowName !== "") {
            baseUrl.push(selectedFlowName);
        }

        hasLexemes = hasLexemes || flow.hasLexemes;

        // Last one always renders flows
        if (idx === flows.length - 1) {
            return {
                baseUrl: baseUrl.slice(0),
                lastFlow: true,
                flows: foundFlows,
            };
        }

        // If we are not the last flow the we are somethingn the user selected
        // so find out which one and render it alone
        const nextFlowName = flows[idx + 1].selectedFlowName;
        const currentFlow = foundFlows.filter((flowItem) => flowItem.name === nextFlowName)[0];

        return {
            baseUrl: idx === 0 ? [] : baseUrl.slice(0),
            flows: currentFlow ? [currentFlow] : [],
            lastFlow: idx === flows.length - 2 && flows[idx + 1].foundFlows.length === 0,
        };
    });

    return {
        flows: flowsContext,
        hasLexemes,
    };
}
