export const createStaffpassLexemeURL = (lexeme: string) => {
    const urlFormat = `https://staffpass.com/translate/lexems/#lang_id=0&query=`;

    const systemNamePattern = /^[a-zA-Z0-9_]+(\.[a-zA-Z0-9_]+)+$/; //System names are like tabbar.label.connections
    const otherPatterns = [
        /\{!(\d+)\}/i, // SRV lexemes are like {!1111}something
        /^(\d+):/i, // Android lexemes are like 1111:something
        /^mw@(.+)/i, // New MW lexemes are like: mw@profile_info_tiw_idea
        /^(\d+)$/, // Some SRV lexemes are like 1245
    ];

    // Check for System names
    const systemNameMatch = lexeme.match(systemNamePattern);
    if (systemNameMatch) {
        const query = encodeURIComponent(systemNameMatch[0]);
        return urlFormat + query;
    }

    // Check for other patterns
    for (let i = 0; i < otherPatterns.length; i++) {
        const pattern = otherPatterns[i];
        const match = lexeme.match(pattern);
        if (match) {
            const query = encodeURIComponent(match[1] || match[0]);
            return urlFormat + query;
        }
    }

    return `https://staffpass.com/BMA/entries.phtml?status=All&search=${encodeURIComponent(
        lexeme,
    )}`;
};
