import React, { useCallback } from "react";
import { Link } from "react-router-dom";
import { Highlighter } from "react-bootstrap-typeahead";

import { IBreadcrumbDropdownItemPropsTypes } from "./interfaces";
import { Dropdown } from "react-bootstrap";

import "./BreadcrumbDropdownItem.scss";

export const BreadcrumbDropdownItem: React.FunctionComponent<IBreadcrumbDropdownItemPropsTypes> = ({
    crumb,
    searchText,
    handleItemSelect,
}) => {
    const handleClick = useCallback(() => {
        handleItemSelect(crumb.name);
    }, [crumb.name, handleItemSelect]);

    return (
        <Dropdown.Item
            as={Link}
            to={crumb.url}
            eventKey={crumb.id}
            active={crumb.isCurrent}
            onClick={handleClick}
            data-item={`breadcrumb-${crumb.id}`}>
            <Highlighter search={searchText}>{crumb.name}</Highlighter>
        </Dropdown.Item>
    );
};
export default BreadcrumbDropdownItem;
