import React, { Component } from "react";

import { Dropdown, DropdownButton } from "react-bootstrap";

import "./DropdownWithFilter.scss";

class DropdownWithFilter extends Component {
    constructor() {
        super();
        this.state = {
            focusLocked: false,
            filter: "",
        };
    }

    lockFocus() {
        this.setState({
            focusLocked: true,
        });
    }

    updateFilter({ target: { value } }) {
        this.setState({
            filter: value,
        });
    }

    onSelect() {
        this.setState({
            focusLocked: false,
        });

        this.props.onSelect.apply(null, arguments);
    }

    onDropdownToggle(open, event, { source }) {
        if (source === "rootClose") {
            this.setState({
                focusLocked: false,
            });
        } else if (open && this.filterInput) {
            // @hack I don't know of a better way, component is never updated
            // when menu is opened
            setTimeout(() => {
                this.filterInput.focus();
            }, 0);
        }
    }

    render() {
        const { title, items, selected } = this.props;
        const { focusLocked, filter } = this.state;

        if ((items && items.length === 1) || !items) {
            return (
                <DropdownButton
                    className="DropdownWithFilter"
                    variant="light"
                    title={<span>{(items && items[0].display) || "Not available"}</span>}
                    disabled={true}
                />
            );
        }

        const selectedItem = items.find((item) => item.value === selected);
        const titleText = <span>{selectedItem ? selectedItem.display : title}</span>;
        let filterRegex;

        try {
            filterRegex = new RegExp(filter, "i");
        } catch (e) {
            filterRegex = new RegExp("", "i");
        }

        return (
            <DropdownButton
                id={title}
                title={titleText}
                className="DropdownWithFilter"
                open={focusLocked ? true : undefined}
                variant={this.props.variant || "light"}
                onToggle={this.onDropdownToggle.bind(this)}
                disabled={!!this.props.disabled}>
                {items.length > 10 ? (
                    <input
                        type="text"
                        placeholder="Filter..."
                        className="form-control FilterDropdown"
                        onFocus={this.lockFocus.bind(this)}
                        onChange={this.updateFilter.bind(this)}
                        ref={(input) => {
                            this.filterInput = input;
                        }}
                    />
                ) : null}
                {items
                    .filter((item) => filterRegex.test(item.display))
                    .map((item) => (
                        <Dropdown.Item
                            variant="dark"
                            key={item.value}
                            eventKey={item.value}
                            onSelect={this.onSelect.bind(this, item.value)}
                            active={item.value === selected}>
                            {item.display}
                        </Dropdown.Item>
                    ))}
            </DropdownButton>
        );
    }
}

export default DropdownWithFilter;
