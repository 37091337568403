import React, { useState, useEffect, useCallback, useRef } from "react";

import SearchInput from "components/SearchInput";
import BreadcrumbDropdownItem from "components/BreadcrumbDropdownItem";

import { IBreadcrumbDropdownPropsTypes } from "./interfaces";
import { Dropdown } from "react-bootstrap";

import "./BreadcrumbDropdown.scss";

export const BreadcrumbDropdown: React.FunctionComponent<IBreadcrumbDropdownPropsTypes> = ({
    crumbsList,
}) => {
    const [filteredOptions, setFilteredOptions] = useState(crumbsList);
    const [searchText, setSearchText] = useState("");

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownMenuRef = useRef<HTMLDivElement | null>(null);
    const currentCrumb = crumbsList.find((crumb) => crumb.isCurrent);

    useEffect(() => {
        // show the last selected item in focus when dropdown is open net time
        if (dropdownMenuRef.current && currentCrumb && isDropdownOpen) {
            const dropdownMenu = dropdownMenuRef.current;
            if (dropdownMenu) {
                const selectedItemElement = dropdownMenu.querySelector(
                    `[data-item='breadcrumb-${currentCrumb.id}']`,
                );
                if (selectedItemElement) {
                    selectedItemElement.scrollIntoView({ behavior: "instant", block: "center" });
                }
            }
        }
    }, [isDropdownOpen, crumbsList, currentCrumb]);

    useEffect(() => {
        // show the full list when component re-renders and receives crumbsList or when crumbsList is changed
        setFilteredOptions(crumbsList);
    }, [crumbsList]);

    const title = currentCrumb ? currentCrumb.name : null;

    const handleSearchAtDropdownItemSelect = useCallback(() => {
        // when item selected it cleans up the search
        setSearchText("");
    }, []);

    const toggleDropdown = useCallback(() => {
        setIsDropdownOpen(!isDropdownOpen);
    }, [isDropdownOpen]);

    const breadcrumbDropdown =
        crumbsList.length === 1 ? (
            <div className="single-item">{crumbsList[0].name}</div>
        ) : (
            <Dropdown show={isDropdownOpen} onToggle={toggleDropdown}>
                <Dropdown.Toggle aria-haspopup="true">
                    <span>{title}</span>
                </Dropdown.Toggle>
                <Dropdown.Menu ref={dropdownMenuRef}>
                    {crumbsList.length > 9 && (
                        <SearchInput
                            options={crumbsList}
                            setFilteredOptions={setFilteredOptions}
                            searchText={searchText}
                            setSearchText={setSearchText}
                            dropdownMenu={dropdownMenuRef}
                            isDropdownOpen={isDropdownOpen}
                        />
                    )}
                    {filteredOptions.map((crumb, idx) => (
                        <BreadcrumbDropdownItem
                            key={idx}
                            crumb={crumb}
                            handleItemSelect={handleSearchAtDropdownItemSelect}
                            searchText={searchText}
                        />
                    ))}
                </Dropdown.Menu>
            </Dropdown>
        );
    return <div className="BreadcrumbDropdown">{breadcrumbDropdown}</div>;
};

export default BreadcrumbDropdown;
