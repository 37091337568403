import React, { useCallback } from "react";

import { LinkContainer } from "react-router-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faLanguage, faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import downloadImage from "utils/downloadImage";
import addProtocol from "utils/addProtocol";
import formatDate from "utils/formatDate";

import { IScreenshotListItemTypes } from "./interfaces";

import ScreenshotWithPane from "components/ScreenshotWithPane";
import ScreenshotWithoutPane from "components/ScreenshotWithoutPane";
import { Button } from "react-bootstrap";

const ScreenshotListItem: React.FunctionComponent<IScreenshotListItemTypes> = ({
    index,
    flowItem,
    url,
    search,
    hasOverlay,
    caption,
    showLexemes,
    disableStats,
    colWidth,
    onClickScreenshot,
    comparisonData,
    currentFilter,
    buildFlowLink,
    taskId,
    flowId,
    setScreenshotsVerificationData,
    screenshotsVerificationData,
    screenshotsIds,
    setFlowPageStateFlowStatus,
    setIsTaskFinishedStateStatus,
    isTaskFinished,
    isScreenshotsVerificationLoading,
}) => {
    const { metadata, id, name, width, height, file, created } = flowItem;

    const src = addProtocol(file);
    const lexemes = metadata && metadata.lexemes;
    const Component = disableStats ? ScreenshotWithoutPane : ScreenshotWithPane;

    const handleDownloadClick = useCallback(() => {
        downloadImage(file, `${name} - ${currentFilter.locale}`);
        window.gtag("event", "click", {
            event_label: "DownloadScreenshot",
        });
    }, [currentFilter.locale, file, name]);

    const overlayItems = hasOverlay
        ? [
              <Button key="download" title="Download" variant="dark" onClick={handleDownloadClick}>
                  <FontAwesomeIcon size="lg" icon={faDownload} />
              </Button>,

              <LinkContainer
                  key="locale"
                  title="Compare languages"
                  className="icon"
                  to={`${url}/${flowItem.id}/locale${search}`}>
                  <Button variant="dark" as="button">
                      <FontAwesomeIcon size="lg" icon={faLanguage} />
                  </Button>
              </LinkContainer>,

              <LinkContainer
                  key="resolution"
                  title="Compare resolutions"
                  className="icon"
                  to={`${url}/${flowItem.id}/resolution${search}`}>
                  <Button variant="dark" as="button">
                      <FontAwesomeIcon size="lg" icon={faMobileAlt} />
                  </Button>
              </LinkContainer>,
          ]
        : [];

    return (
        <Component
            comparisonData={comparisonData}
            id={id}
            index={index}
            name={buildFlowLink || name}
            info={formatDate(created, "h:MM dS mmm yy")}
            caption={caption}
            src={src}
            width={width}
            height={height}
            overlayItems={overlayItems}
            lexemes={lexemes}
            showLexemes={showLexemes}
            colWidth={colWidth}
            onClick={onClickScreenshot}
            taskId={taskId}
            flowId={flowId}
            setScreenshotsVerificationData={setScreenshotsVerificationData}
            screenshotsVerificationData={screenshotsVerificationData}
            screenshotsIds={screenshotsIds}
            setFlowPageStateFlowStatus={setFlowPageStateFlowStatus}
            setIsTaskFinishedStateStatus={setIsTaskFinishedStateStatus}
            isTaskFinished={isTaskFinished}
            isScreenshotsVerificationLoading={isScreenshotsVerificationLoading}
        />
    );
};

export default ScreenshotListItem;
