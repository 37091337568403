import React from "react";

import { DropdownButton, Dropdown } from "react-bootstrap";
import "./AdminTable.scss";

const AdminTable = ({ users, changeDropdownValue }) => {
    const userRoles = ["admin", "user", "translator_manager", "translator_translator"];

    const rolesDropdown = (userId, currentRole) => {
        return (
            <DropdownButton
                id={userId}
                size="sm"
                title={<span>{currentRole}</span>}
                variant={
                    currentRole === "user"
                        ? "info"
                        : currentRole === "translator_translator"
                        ? "success"
                        : currentRole === "translator_manager"
                        ? "danger"
                        : "primary"
                }
                onSelect={(selectedValue) => changeDropdownValue(userId, selectedValue)}>
                {userRoles.map(function (role) {
                    return (
                        <Dropdown.Item key={role} eventKey={role} active={currentRole === role}>
                            {role}
                        </Dropdown.Item>
                    );
                })}
            </DropdownButton>
        );
    };

    const userRow = (userId: string, user: { name: string; role: string }) => {
        return (
            <tr key={userId} className="tr-body">
                <td>{userId}</td>
                <td title={user.name}>{user.name}</td>
                <td>{rolesDropdown(userId, user.role)}</td>
            </tr>
        );
    };

    const userList = Object.keys(users).map((key) => userRow(key, users[key]));

    return (
        <table className="AdminTable task-table">
            <tr key="header" className="tr-header">
                <th>
                    <b>ID</b>
                </th>
                <th>
                    <b>User name</b>
                </th>
                <th>
                    <b>User role</b>
                </th>
            </tr>

            <tbody>{userList}</tbody>
        </table>
    );
};

export default AdminTable;
