import React from "react";

import appState from "appState";

import { ISectionsListPageProps } from "./interfaces";

import parseSearchString from "utils/parseSearchString";

import FlowSelector from "components/FlowSelector";
import SectionsList from "components/SectionsList";
import BreadcrumbsNavigation from "components/BreadcrumbsNavigation";
import Jumbotron from "components/Jumbotron";

import { Container, Row, Col /* Button */ } from "react-bootstrap";

/*
import {encodeFlowParams} from "utils/encode-flow-params";
import {encodeIntoQueryParams} from "utils/encode-into-query-params";
import prettyLocale from "../utils/prettyLocale";
 */

const SectionsListPage: React.FunctionComponent<ISectionsListPageProps> = (props) => {
    const { search } = props.location;

    const selectedFilters = appState.get("flow");

    const params = parseSearchString();
    const taskId = params.get("taskId");

    return (
        <Container>
            <Row>
                {taskId ? null : (
                    <Col xs={6} md={3} className="StickyFlow">
                        <FlowSelector allowChoosingPods={true} isSectionsListPage={true} />
                        {/*  <Button
                            disabled={true}
                            className="flow-actions"
                            variant="dark"
                            block={true}
                            onClick={downloadAllFlows}>
                            Download All Flows*
                        </Button> */}
                    </Col>
                )}
                <Col xs={12} md={taskId ? 12 : 9}>
                    {taskId
                        ? null
                        : [
                              <BreadcrumbsNavigation
                                  key="breadcrumb"
                                  showSearch={true}
                                  taskId={taskId}
                              />,
                              <Jumbotron key="jumbotron" />,
                          ]}
                    <SectionsList
                        selectedFilters={selectedFilters}
                        search={search}
                        taskId={taskId}
                    />
                </Col>
            </Row>
        </Container>
    );
};

/* Disabling Download All flows till CAL-28102 is done.

function downloadAllFlows() {
    window.gtag('event', 'click', {
               event_label: 'DownloadAllFlows',
});
    const flow = appState.get('flow');
    var flowParams = encodeFlowParams(flow);
    flowParams['fullLocale'] = prettyLocale(flow.locale);
         window.open(
       `${process.env.LIVESHOTS_APP_URL}/api/flows/zip?${encodeIntoQueryParams(flowParams)}`,
                'Download'
                     );
} */
export default SectionsListPage;
