import React, { useCallback, useEffect, useState } from "react";

import ReactLoading from "react-loading";
import ReactModal from "react-modal";

import calcFlowImagePadding from "utils/calcFlowImagePadding";
import preloadImage from "utils/preloadImage";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faExclamation } from "@fortawesome/free-solid-svg-icons";

import { getDifferenceIndicatorColour, openDataUrl } from "./helpers";
import { IScreenshotPropsTypes, IDimensions } from "./interfaces";

import ScreenshotVerification from "./ScreenshotVerification";
import { ButtonGroup } from "react-bootstrap";

import "./Screenshot.scss";

ReactModal.setAppElement("#root");

const Screenshot: React.FunctionComponent<IScreenshotPropsTypes> = ({
    id,
    name,
    info,
    caption,
    taskId,
    comparisonData,
    overlayItems = [],
    flowId,
    setScreenshotsVerificationData,
    screenshotsVerificationData,
    src,
    width = 0,
    height = 0,
    onClick,
    index,
    screenshotsIds,
    setFlowPageStateFlowStatus,
    setIsTaskFinishedStateStatus,
    isTaskFinished,
    isScreenshotsVerificationLoading,
}) => {
    const [openModal, setOpenModal] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const [screenshotLoadingStatus, setScreenshotLoadingStatus] = useState("LOADING");
    const [screenshotSize, setScreenshotSize] = useState({
        width,
        height,
    });

    const handleClose = useCallback(() => setOpenModal(false), []);
    const handleOpen = useCallback(() => {
        window.gtag("event", "click", {
            event_label: "Screenshot",
        });

        if (onClick) {
            onClick(index);
        } else {
            setIsFullscreen(!isFullscreen);
            setOpenModal(true);
        }
    }, [index, isFullscreen, onClick]);

    const dataUrl = comparisonData?.dataUrl;
    const handleScreenshotClick = useCallback(() => {
        if (dataUrl) openDataUrl(dataUrl);
    }, [dataUrl]);

    useEffect(() => {
        if (screenshotLoadingStatus === "LOADING") {
            preloadImage(src)
                .then(({ width: realWidth, height: realHeight }: IDimensions) => {
                    setScreenshotLoadingStatus("LOADED");
                    setScreenshotSize({ width: realWidth, height: realHeight });
                })
                .catch((error: string) => {
                    console.error(error);
                    setScreenshotLoadingStatus("FAILED");
                    setScreenshotSize({ width, height });
                });
        }
    }, [height, screenshotLoadingStatus, screenshotSize, src, width]);

    const isComparable = comparisonData?.isComparable;
    const diffPixelRatio = comparisonData?.diffPixelRatio;

    // status LOADING
    let imgElement = (
        <div className="loader-container">
            <ReactLoading type="bubbles" color="#888" />
        </div>
    );

    // eslint-disable-next-line default-case
    switch (screenshotLoadingStatus) {
        case "LOADED":
            imgElement = (
                <img alt={name} src={src} onClick={handleOpen} crossOrigin="use-credentials" />
            );

            break;
        case "FAILED":
            imgElement = (
                <div className="img-failed">
                    <span>Failed to load image</span>
                    <span>/ᐠ｡ꞈ｡ᐟ\</span>
                </div>
            );

            break;
    }

    const differenceIndicatorColour = getDifferenceIndicatorColour(
        comparisonData,
        isComparable,
        diffPixelRatio,
    );

    const screenshotStatus = screenshotsVerificationData?.[id]?.status;
    let screenshotStatusClass = "";
    let screenshotStatusIcon = null;

    // eslint-disable-next-line default-case
    switch (screenshotStatus) {
        case "approved":
            screenshotStatusClass = "screenshot-approved";
            screenshotStatusIcon = <FontAwesomeIcon icon={faCheck} />;
            break;
        case "rejected":
            screenshotStatusClass = "screenshot-needs-retest";
            screenshotStatusIcon = <FontAwesomeIcon icon={faTimes} />;
            break;
        case "":
            screenshotStatusClass = "screenshot-reassigned";
            screenshotStatusIcon = <FontAwesomeIcon icon={faExclamation} />;
    }

    return openModal ? (
        <ReactModal
            contentLabel="Modal"
            className="FullScreenshot"
            overlayClassName="FullScreenshotOverlay"
            isOpen={true}>
            <div
                className="ScreenshotContent"
                style={{
                    backgroundImage: `url('${src}')`,
                }}
                onClick={handleClose}
            />
        </ReactModal>
    ) : (
        <>
            <div className="Screenshot" id={`${id}`}>
                <div
                    className={`img-container ${screenshotStatusClass}`}
                    style={{
                        paddingBottom: `${calcFlowImagePadding(
                            screenshotSize.width,
                            screenshotSize.height,
                        )}%`,
                    }}>
                    {imgElement}
                    {screenshotLoadingStatus === "LOADED" ? screenshotStatusIcon : null}
                </div>

                {caption ? <div className="ScreenshotCaption">{caption}</div> : null}

                <div className="ScreenshotFooter">
                    <span className="left">{name} </span>
                    <span
                        className={`center${differenceIndicatorColour}`}
                        onClick={handleScreenshotClick}></span>
                    <span className="right"> {info}</span>
                </div>
                {overlayItems &&
                    overlayItems.length > 0 &&
                    screenshotLoadingStatus === "LOADED" && (
                        <div className="ScreenshotBottomOverlay">
                            <ButtonGroup size="lg" className={"overlay-buttons"}>
                                {overlayItems}
                            </ButtonGroup>
                        </div>
                    )}
            </div>

            {taskId && screenshotLoadingStatus === "LOADED" && !isScreenshotsVerificationLoading ? (
                <ScreenshotVerification
                    screenshotId={id}
                    taskId={taskId}
                    flowId={flowId}
                    screenshotName={name}
                    setScreenshotsVerificationData={setScreenshotsVerificationData}
                    screenshotsVerificationData={screenshotsVerificationData}
                    screenshotsIds={screenshotsIds}
                    setFlowPageStateFlowStatus={setFlowPageStateFlowStatus}
                    setIsTaskFinishedStateStatus={setIsTaskFinishedStateStatus}
                    isTaskFinished={isTaskFinished}
                />
            ) : null}
        </>
    );
};

export default Screenshot;
