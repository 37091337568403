import React, { Component } from "react";

import { getRun } from "models/getRun";

import FlowSelector from "components/FlowSelector";
import getScreenshotsList from "components/getScreenshotsList";
import ModePanel from "components/ModePanel";
import Spacer from "components/Spacer";
import { Col, Container, Row, Card } from "react-bootstrap";

class RunResultPage extends Component {
    constructor() {
        super();
        this.state = {
            flow: {},
            sections: [],
        };

        this.changeMode = this.changeMode.bind(this);
    }

    componentDidMount() {
        this.fetchFlow(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.fetchFlow(props);
    }

    fetchFlow(props) {
        const { runId } = props.match.params;

        getRun(runId).then((run) => {
            this.setState({
                run,
            });
        });
    }

    changeMode(props) {
        const { showLexemes } = props;
        const state = {
            showLexemes,
        };
        this.setState(state);
    }

    render() {
        const { run, showLexemes } = this.state;

        if (!run) {
            return false;
        }

        const filter = {
            product: run.product,
            platform: run.platform,
            version: run.version,
            device: run.device,
            locale: run.locale,
            branch: run.branch,
        };

        const screenshotList = getScreenshotsList({
            screenshots: run.screenshots,
            baseUrl: this.props.match.url,
            searchQuery: this.props.location.search,
            showLexemes,
        });

        return (
            <Container className="RunResultPage">
                <Row>
                    <Col xs={12} md={2} className="StickyFlow">
                        <FlowSelector flowKey="flow" filter={filter} />
                        <ModePanel hasLexemes={run.hasLexemes} onChange={this.changeMode} />
                    </Col>
                    <Col xs={12} md={10}>
                        <Card body={true} size="lg">
                            {run.description}
                        </Card>
                        <Spacer bottom="lg" />
                        <Row className="ScreenshotList">{screenshotList}</Row>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default RunResultPage;
