import React, { useState, useEffect, useCallback } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation } from "@fortawesome/free-solid-svg-icons";

import { LinkContainer } from "react-router-bootstrap";

import prettyString from "utils/prettyString";

import { statusToStyle } from "./helpers";
import { IFlowItemPropsTypes } from "./interfaces";

import FlowDateAdded from "components/FlowDateAdded";
import { InputGroup, ListGroupItem, OverlayTrigger, Tooltip } from "react-bootstrap";

import "./FlowItem.scss";

const FlowItem: React.FunctionComponent<IFlowItemPropsTypes> = ({
    flowItem,
    search,
    reassignedFlowsStatus,
    handleCheckbox,
    isChooseFlowsModalOpen,
    translatorSelectedFlows,
    translatorId,
    showReassigning,
    taskId,
}) => {
    const [checkedIds, setCheckedIds] = useState(translatorSelectedFlows);

    useEffect(() => {
        setCheckedIds(translatorSelectedFlows);
    }, [translatorSelectedFlows]);

    const handleChange = useCallback(
        (event) => {
            handleCheckbox(event.target);
        },
        [handleCheckbox],
    );

    const reassignedFlowStatus: boolean = !!(
        reassignedFlowsStatus && reassignedFlowsStatus.includes(flowItem.id)
    );

    const isInsideReassigningTask: boolean = !!taskId && showReassigning;
    const isDateAddedVisible = isChooseFlowsModalOpen || isInsideReassigningTask;

    const flowItemBody = (
        <ListGroupItem className="FlowItem" variant={statusToStyle(flowItem.status)}>
            <div className="flow-item-description">
                {prettyString(flowItem.name)}
                {isDateAddedVisible ? (
                    <FlowDateAdded date={flowItem.date_added} isFlowForTask={isDateAddedVisible} />
                ) : null}
            </div>
            {flowItem.rejected_screens_count && flowItem.status === "failed" ? (
                <div className="flow-item-verification-info">
                    <span>{flowItem.rejected_screens_count}</span>
                </div>
            ) : null}
            {flowItem.status === "reassigned" || flowItem.status === "reassigned_in_progress" ? (
                <div className="flow-item-verification-info">
                    <FontAwesomeIcon size="lg" icon={faExclamation} />
                </div>
            ) : null}
        </ListGroupItem>
    );

    const flowLink = `/flow/${flowItem.id}${search}`;

    const renderedFlowItem = isChooseFlowsModalOpen ? (
        <a className="flow-link" href={flowLink} target="_blank" rel="noopener noreferrer">
            {flowItemBody}
        </a>
    ) : (
        <LinkContainer to={flowLink}>{flowItemBody}</LinkContainer>
    );

    const isFlowChecked =
        isChooseFlowsModalOpen && Object.values(checkedIds).includes(Number(flowItem.id));

    const flowItemWithCheckbox = (
        <div className="flow-item-with-checkbox">
            <InputGroup as="label">
                <InputGroup.Checkbox
                    id={`${flowItem.id}${translatorId || ""}`}
                    checked={isFlowChecked || reassignedFlowStatus}
                    onChange={handleChange}
                />
            </InputGroup>

            {renderedFlowItem}
        </div>
    );
    const tooltip = <Tooltip id={`tooltip-${flowItem.id}`}>{flowItem.comments}</Tooltip>;
    const flowItemContent = showReassigning ? flowItemWithCheckbox : renderedFlowItem;

    if (isChooseFlowsModalOpen) return flowItemWithCheckbox;
    else if (flowItem.status === "failed") {
        return flowItem.comments ? (
            <OverlayTrigger overlay={tooltip} placement="right">
                {flowItemContent}
            </OverlayTrigger>
        ) : (
            flowItemContent
        );
    }

    return renderedFlowItem;
};

export default FlowItem;
