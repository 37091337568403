export const getFileContent = (src, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", src);
    // avoid previous browser cache non-CORS request from viewing screenshots (on FlowPage for example)
    xhr.setRequestHeader("Cache-Control", "no-cache");
    xhr.responseType = "blob";
    xhr.onload = function () {
        callback(xhr.response);
    };
    xhr.send();
};
