import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import appState from "appState";

import { getDefaultFilters } from "models/getDefaultFilters";
import { getFilters, getFiltersForTCBuildId, getLocalesToCreateTask } from "models/getFilters";
import { getSections } from "models/getSections";

import decodeQueryParams from "utils/decodeQueryParams";
import checkObjectsShallowEquality from "utils/checkObjectsShallowEquality";

import { translatorsAreChosen, taskIsComplete, updateTheme, createTask } from "./helpers";

import TranslatorMultiChoose from "components/TranslatorMultiChoose";
import FlowMultiChoose from "components/FlowMultiChoose";
import DropdownWithFilter from "components/DropdownWithFilter";
import { Badge, Button, Card } from "react-bootstrap";

import "./FlowSelector.scss";

class FlowSelector extends Component {
    constructor() {
        super();
        this.state = {
            currentFilters: {
                platform: "",
                product: "",
                translatorsLocales: {},
                selectedFlowsForTaskCreation: {},
            },
        };
        this.setTranslatorsLocales = this.setTranslatorsLocales.bind(this);
        this.fetchNewFlow = this.fetchNewFlow.bind(this);
        this.handleChooseFlowsApplyButton = this.handleChooseFlowsApplyButton.bind(this);
    }

    override componentDidMount() {
        this.fetchFilters(this.props);
    }

    override componentDidUpdate(prevProps: Record<string, any>) {
        if (!checkObjectsShallowEquality(prevProps, this.props)) {
            this.fetchFilters(this.props);
        }
    }

    handleChooseFlowsApplyButton(selectedFlows: number[]) {
        this.setState((prevState) => ({
            ...prevState,
            currentFilters: {
                ...prevState.currentFilters,
                selectedFlowsForTaskCreation: selectedFlows,
            },
        }));
    }

    fetchFilters(props) {
        const flowKey = (this.flowKey = props.flowKey || "flow");

        const currentFilters = appState.get(flowKey) || props.filter || {};

        if (Object.keys(currentFilters).length === 0 && props.match.params.tcBuildId) {
            getFiltersForTCBuildId(props.match.params.tcBuildId, currentFilters).then((filters) => {
                if (Object.keys(filters).length === 0) {
                    getDefaultFilters({}).then((defaultFilters) => {
                        appState.set({ flow: defaultFilters });
                    });
                } else {
                    appState.set({ flow: filters });
                }
            });
        } else {
            const urlState = decodeQueryParams(this.props.history.location.search);
            // This applies only if translator manually added mode=advanced to URL which means they want to see all devices
            if (urlState.mode) {
                currentFilters.mode = urlState.mode;
            }
            if (!currentFilters || Object.keys(currentFilters).length < 3) {
                this.renderDefaultFilters(currentFilters);
            } else if (this.flowKey === "task-creation") {
                this.setState((prevState) => ({
                    ...prevState,
                    filters: {
                        ...props.filter,
                        locale: undefined,
                    },
                }));
            } else {
                this.updateFilters(currentFilters);
            }
        }
    }

    renderDefaultFilters(
        selectedFilters: { product: string; platform: string },
        productChanged = false,
        page: { isSectionsListPage: boolean; isFlowExplorer: boolean; isSearch: boolean },
    ) {
        getDefaultFilters(selectedFilters).then(
            (currentFilters: {
                pod: string;
                platform: string;
                product: string;
                device: string;
                locale: string;
                version: string;
                os: string;
                branch: string;
            }) => {
                if (
                    productChanged &&
                    !page?.isSectionsListPage &&
                    !page?.isFlowExplorer &&
                    !page?.isSearch
                ) {
                    this.fetchNewFlow(currentFilters, false);
                } else {
                    this.updateAppState(currentFilters);
                }
            },
        );
    }

    updateAppState(filters, appStatePath) {
        if (this.flowKey !== "task-creation") {
            const currentVersionBranches = this.state?.filters?.branch[filters.version];
            if (currentVersionBranches && !currentVersionBranches?.includes(filters.branch)) {
                filters.branch = currentVersionBranches[0] || "Release";
            }
            const currentVersionOs = this.state?.filters?.os[filters.version];
            if (currentVersionOs && !currentVersionOs?.includes(filters.os)) {
                filters.os = currentVersionOs[0];
            }
        }
        appState.set(
            {
                [this.flowKey]: filters,
            },
            false,
            appStatePath,
        );
        if (this.flowKey === "task-creation" && filters.product && filters.platform) {
            getLocalesToCreateTask(filters.product, filters.platform).then((locales) => {
                this.setState((prevState) => ({
                    ...prevState,
                    filters: {
                        ...prevState.filters,
                        locale: locales,
                    },
                }));
            });
        }
        this.setState({ currentFilters: filters });
    }

    fetchNewFlow(
        filters: {
            pod: string;
            platform: string;
            product: string;
            device: string;
            locale: string;
            version: string;
            os: string;
            branch: string;
        },
        isPodChange: boolean,
    ) {
        getSections(filters, null, false).then((sections) => {
            if (sections.sections.length > 0 && sections.sections[0].flows.length > 0) {
                let newFlowId = sections.sections[0].flows[0].id;

                if (this.props.isComparison) {
                    if (isPodChange) {
                        const { altFlow } = this.props;
                        const flowSection = altFlow.section;
                        const flowName = altFlow.name;

                        const newSections = sections?.sections.filter(
                            (sect: { name: string }) => sect.name === flowSection,
                        );
                        if (newSections && newSections.length) {
                            const newFlow = newSections[0].flows.filter(
                                (nFlow: { name: string }) => nFlow.name === flowName,
                            );
                            if (newFlow && newFlow.length) newFlowId = newFlow[0].id;
                        }
                    }
                    this.props.changeFlowId(this.props.flowKey, newFlowId, filters);
                } else this.updateAppState(filters, `/flow/${newFlowId}`);
            } else if (sections.sections.length === 0) {
                const podName =
                    this.state?.filters.pod.filter(
                        (pod: { value: string }) => pod.value === filters.pod,
                    )[0]?.display || "All pods";
                alert(
                    `There are no flows with selected parameters:

                    ${filters.platform} / ${filters.product}
                    ${filters.device} / ${filters.locale} / ${filters.version}
                    ${filters.os} / ${filters.branch}
                    ${podName}`,
                );
            }
        });
    }

    updateFilters(newFilters) {
        // clone

        newFilters = { ...newFilters };

        getFilters(newFilters).then((filters) => {
            this.updateAppState(newFilters);
            this.setState({
                filters,
                currentFilters: newFilters,
            });
        });
    }

    onSelect(field, value) {
        const newFilters = {
            ...this.state.currentFilters,
        };
        newFilters[field] = value;

        const { product, platform } = newFilters;

        if ((field === "platform" || field === "product") && this.flowKey !== "task-creation") {
            this.renderDefaultFilters({ product, platform }, field === "product", {
                isSectionsListPage: !!this.props.isSectionsListPage,
                isFlowExplorer: !!this.props.isFlowExplorer,
                isSearch: !!this.props.isSearch,
            });
        } else if (field === "pod" && !this.props.isSectionsListPage) {
            this.fetchNewFlow(newFilters, true);
        } else if (field === "locale") {
            const showLexemesProp = appState.get("showLexemes");

            if ((showLexemesProp && newFilters.locale !== "lx-lx") || !showLexemesProp) {
                appState.set({
                    showLexemes: false,
                    [`${this.props.flowKey || "flow"}`]: { ...newFilters },
                });
            }
        } else {
            if (field === "product" && this.flowKey === "task-creation") {
                updateTheme(value);
            }
            this.updateAppState(newFilters);
        }
    }

    setTranslatorsLocales(translatorsLocales) {
        this.setState((prevState) => {
            return {
                ...prevState,
                currentFilters: {
                    ...prevState.currentFilters,
                    translatorsLocales,
                },
            };
        });

        appState.set({
            "task-creation": {
                ...appState.get(["task-creation"]),
                locale: Object.values(translatorsLocales)[0],
            },
        });
    }

    render() {
        if (!this.state) return false;

        const { currentFilters, filters } = this.state;
        const { allowChoosingPods, differentOnly, tcBuildId } = this.props;
        const header = this.flowKey === "task-creation" ? "Create new tasks" : "Select a flow";

        if (!filters) return false;

        const podDefaultValue = null;
        let osItems = [];
        let podItems = [];
        let branchItems = [];

        if (this.flowKey !== "task-creation") {
            const current_pods = [
                "Setting Up",
                "Profile Experience",
                "Core Discovery",
                "Enhanced Discovery",
                "Connect",
                "Payer Conversion",
                "Revenue - Promo Platform",
                "Paywall Platform",
                "Bumble Inc Media",
            ];

            const pods = filters.pod.filter((item) => current_pods.includes(item.display));
            podItems = [
                { display: "All pods", value: podDefaultValue },
                ...pods.sort((a, b) => {
                    return a.display.localeCompare(b.display);
                }),
            ];

            const currentVersionBranches = filters.branch[currentFilters.version] || ["Release"];
            branchItems = currentVersionBranches?.flatMap((branch) => [
                { display: branch, value: branch },
            ]) || [{ display: "Non-Release", value: "Non-Release" }];

            const currentVersionOs = filters.os[currentFilters.version] || ["Unknown"];

            // platform for Android devices only because iOS already have the prefix
            const platform = currentFilters.platform === "android" ? "Android " : "";

            osItems = currentVersionOs?.flatMap((os) => [
                {
                    display: (os !== "Unknown" ? platform : "") + os,
                    value: os,
                },
            ]) || [{ display: "Unknown", value: "unknown" }];
        }
        return (
            <>
                {differentOnly ? (
                    <Badge className="different-only-warning" variant="warning">
                        <strong>Different Only</strong>
                    </Badge>
                ) : null}
                <form className={`FlowSelector${differentOnly ? " different-only-disabled" : ""}`}>
                    <Card>
                        <Card.Header>{header}</Card.Header>
                        {tcBuildId ? (
                            <Badge className="card-tcbuildid-message" variant="secondary">
                                <span className="tcbuildid-text">TCBuild&nbsp;ID: </span>
                                <span>{tcBuildId}</span>
                            </Badge>
                        ) : null}

                        <Card.Body>
                            <DropdownWithFilter
                                title="Platform"
                                items={filters.platform}
                                selected={currentFilters.platform}
                                onSelect={this.onSelect.bind(this, "platform")}
                                disabled={this.props.tcBuildId}
                            />

                            <DropdownWithFilter
                                title="Product"
                                items={filters.product}
                                selected={currentFilters.product}
                                onSelect={this.onSelect.bind(this, "product")}
                                disabled={this.props.tcBuildId}
                            />

                            {this.flowKey === "task-creation" ? (
                                <>
                                    <TranslatorMultiChoose
                                        locales={filters.locale}
                                        translators={filters.translator}
                                        setTranslatorsLocales={this.setTranslatorsLocales}
                                        areTranslatorsChosen={translatorsAreChosen(
                                            currentFilters.translatorsLocales,
                                        )}
                                    />
                                    <FlowMultiChoose
                                        disabled={!taskIsComplete(currentFilters)}
                                        currentFilters={currentFilters}
                                        selectedFlowsForTaskCreation={
                                            this.state.currentFilters.selectedFlowsForTaskCreation
                                        }
                                        handleChooseFlowsApplyButton={
                                            this.handleChooseFlowsApplyButton
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <hr />

                                    {filters.device && filters.device.length > 0 ? (
                                        <DropdownWithFilter
                                            title="Device"
                                            items={filters.device}
                                            selected={currentFilters.device}
                                            onSelect={this.onSelect.bind(this, "device")}
                                            disabled={this.props.tcBuildId}
                                        />
                                    ) : null}
                                    <DropdownWithFilter
                                        title="Language"
                                        items={filters.locale}
                                        selected={currentFilters.locale}
                                        onSelect={this.onSelect.bind(this, "locale")}
                                    />

                                    <DropdownWithFilter
                                        title="Version"
                                        items={filters.version}
                                        selected={currentFilters.version}
                                        onSelect={this.onSelect.bind(this, "version")}
                                        disabled={this.props.tcBuildId}
                                    />
                                    <hr />
                                    <DropdownWithFilter
                                        title="OS"
                                        items={osItems}
                                        selected={currentFilters.os || "Unknown"}
                                        onSelect={this.onSelect.bind(this, "os")}
                                        disabled={this.props.tcBuildId}
                                    />
                                    <DropdownWithFilter
                                        title="Branch"
                                        items={branchItems}
                                        selected={currentFilters.branch || "Non-Release"}
                                        onSelect={this.onSelect.bind(this, "branch")}
                                        disabled={this.props.tcBuildId}
                                    />

                                    <hr />
                                    <DropdownWithFilter
                                        title="Pod"
                                        items={podItems}
                                        selected={currentFilters.pod?.toString() || podDefaultValue}
                                        onSelect={this.onSelect.bind(this, "pod")}
                                        disabled={!allowChoosingPods}
                                    />
                                </>
                            )}
                        </Card.Body>
                    </Card>
                </form>
                {this.flowKey === "task-creation" ? (
                    <Button
                        block={true}
                        className="flow-selector-button flow-selector-button--create-task"
                        onClick={() => createTask(currentFilters, this.props.setHasNewTasks)}
                        disabled={!taskIsComplete(currentFilters)}>
                        Create new tasks
                    </Button>
                ) : null}
            </>
        );
    }
}

export default withRouter(FlowSelector);
