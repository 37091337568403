import React, { Component } from "react";
import { LinkContainer } from "react-router-bootstrap";
import ReactLoading from "react-loading";

import appState from "appState";

import { getDefaultFilters } from "models/getDefaultFilters";
import { getSearchResults } from "models/getSearchResults";

import prettyString from "utils/prettyString";

import FlowSelector from "components/FlowSelector";
import BreadcrumbsNavigation from "components/BreadcrumbsNavigation";
import ScreenshotListItem from "components/ScreenshotListItem";
import Flow from "components/Flow";
import ErrorMessage from "components/ErrorMessage";
import ModePanel from "components/ModePanel";
import { Card, CardColumns, Col, Container, ListGroup, ListGroupItem, Row } from "react-bootstrap";

import "./SearchResultPage.scss";

class SearchResultPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props,
            screenshots: [],
            flows: [],
            sections: [],
            isLoadedSearchResults: false,
            hasResults: null,
        };

        this.changeMode = this.changeMode.bind(this);
    }

    override componentDidMount() {
        this.handleSearch(this.props);
    }

    override componentDidUpdate(prevProps) {
        if (prevProps.match.params.search !== this.props.match.params.search) {
            this.handleSearch(this.props);
            this.setState({
                ...this.props,
            });
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.handleSearch(this.props);

            this.setState({
                ...this.props,
            });
        }
    }

    handleSearch(props) {
        this.setState({
            error: null,
            isLoadedSearchResults: false,
        });

        const selectedFilters = appState.get("flow");

        const searchQuery = props.match.params.search;
        const filtersFulfillingNeeded = props.location.state?.from.pathname.indexOf("/jira/") === 0;

        if (filtersFulfillingNeeded) {
            const partialFilters = {
                platform: props.location.state.platform,
                product: props.location.state.product,
            };
            getDefaultFilters(partialFilters).then((filters) => {
                appState.set({ flow: filters }, true);

                getSearchResults(searchQuery, filters)
                    .then(({ flows, screenshots, sections }) => {
                        this.setState({
                            flows,
                            screenshots,
                            sections,
                            error: null,
                            isLoadedSearchResults: true,
                        });
                    })
                    .catch((error) => {
                        this.setState({
                            error,
                            isLoadedSearchResults: true,
                        });
                    });
            });
        } else {
            getSearchResults(searchQuery, selectedFilters)
                .then(({ flows, screenshots, sections }) => {
                    this.setState({
                        flows,
                        screenshots,
                        sections,
                        error: null,
                        isLoadedSearchResults: true,
                    });
                })
                .catch((error) => {
                    this.setState({
                        error,
                        isLoadedSearchResults: true,
                    });
                });
        }
    }

    changeMode(props) {
        const { showLexemes } = props;

        this.setState({
            showLexemes,
        });
    }

    renderSearchResults() {
        const { flows, screenshots, sections, showLexemes } = this.state;
        const hasResults = sections.length + flows.length + screenshots.length > 0;

        return [
            sections.length > 0 ? (
                <SectionResults
                    key="section-results"
                    sections={sections}
                    search={this.props.location.search}
                />
            ) : null,
            flows.length > 0 ? (
                <FlowResults key="flow-results" flows={flows} search={this.props.location.search} />
            ) : null,
            screenshots.length > 0 ? (
                <ScreenshotResults
                    key="screenshot-results"
                    screenshots={screenshots}
                    search={this.props.location.search}
                    showLexemes={showLexemes}
                />
            ) : null,

            hasResults || hasResults === null ? null : <NoResults key="no-results" />,
        ];
    }

    render() {
        const { screenshots, error, isLoadedSearchResults } = this.state;
        const hasLexemes = screenshots.some((screenshot) => {
            return (
                screenshot.metadata &&
                screenshot.metadata.lexemes &&
                screenshot.metadata.lexemes.length > 0
            );
        });

        return (
            <Container className="SearchResultPage">
                <Row>
                    <Col key="col-1" xs={12} md={2} className="StickyFlow">
                        <FlowSelector key="flow" isSearch={true} />
                        <ModePanel hasLexemes={hasLexemes} onChange={this.changeMode} />
                    </Col>

                    <Col key="col-2" xs={12} md={10}>
                        <BreadcrumbsNavigation
                            search={this.props.match.params.search}
                            showSearch={true}
                            autoFocus={true}
                        />

                        {!isLoadedSearchResults ? (
                            <div className="loader-container">
                                <ReactLoading type="bubbles" color="#888" />
                            </div>
                        ) : isLoadedSearchResults && error ? (
                            <ErrorMessage error={error} />
                        ) : (
                            this.renderSearchResults()
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default SearchResultPage;

function SectionResults({ sections, search }) {
    return (
        <div>
            <h2>Sections</h2>
            <ResultsText count={sections.length} />
            <CardColumns>
                {sections.map((section) => (
                    <Section key={section.id} section={section} search={search} />
                ))}
            </CardColumns>
        </div>
    );
}

function Section({ section, search }) {
    return (
        <Card>
            <ListGroup>
                <LinkContainer to={`/section/${section.id}${search}`}>
                    <ListGroupItem as="a">
                        <strong>{prettyString(section.name)}</strong>
                        <div>{section.description}</div>
                    </ListGroupItem>
                </LinkContainer>
            </ListGroup>
        </Card>
    );
}

function FlowResults({ flows, search }) {
    return (
        <div>
            <h2>Flows</h2>
            <ResultsText count={flows.length} />
            <CardColumns>
                {flows.map((flow) => (
                    <Flow key={flow.id} flow={flow} search={search} />
                ))}
            </CardColumns>
        </div>
    );
}

function ScreenshotResults({ screenshots, search, showLexemes }) {
    return (
        <div>
            <h2>Screenshots</h2>
            <ResultsText count={screenshots.length} />
            <Row className="ScreenshotList">
                {screenshots.map((screenshot) => (
                    <ScreenshotListItem
                        key={screenshot.id}
                        flowItem={{
                            ...screenshot,
                            width: screenshot.resolution.width,
                            height: screenshot.resolution.height,
                        }}
                        url={`/flow/${screenshot.flow_id}`}
                        search={search}
                        hasOverlay={true}
                        colWidth={4}
                        caption={screenshot.name}
                        showLexemes={showLexemes}
                        currentFilter={appState.get("flow")}
                        buildFlowLink={buildFlowLink(
                            `/flow/${screenshot.flow_id}`,
                            screenshot.section_name,
                            screenshot.flow_name,
                        )}
                    />
                ))}
            </Row>
        </div>
    );
}

function NoResults() {
    return (
        <div>
            <h2>No results</h2>
        </div>
    );
}

function buildFlowLink(url, section_name, flow_name) {
    const href = `${url}?${appState.encodeState()}`;
    return <a href={href}>{`${prettyString(section_name)} > ${prettyString(flow_name)}`}</a>;
}

function ResultsText({ count }) {
    if (!count || count === 0) {
        return <p>No results</p>;
    } else if (count === 1) {
        return <p>1 result</p>;
    } else {
        return <p>{count} results</p>;
    }
}
