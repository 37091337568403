import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";

import { faArrowLeft, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getLastTaskStatus } from "models/getTasks";
import closeTask from "utils/closeTask";

import { Button, Nav, Navbar } from "react-bootstrap";
import Spacer from "components/Spacer";

const NavigationTask: React.FunctionComponent = () => {
    const history = useHistory();

    const [isUserManager, setIsUserManager] = useState(false);

    useEffect(() => {
        getLastTaskStatus()
            .then(({ user_is_manager }: { user_is_manager: boolean }) => {
                setIsUserManager(user_is_manager);
            })
            .catch((err: { error: Object }) => {
                alert(err.error);
            });
    }, [isUserManager]);

    const handleTaskClose = useCallback(() => {
        const pageAfterTaskClosing = isUserManager ? "/taskmanager" : "/";
        history.push({ pathname: pageAfterTaskClosing });

        closeTask();
    }, [history, isUserManager]);

    return (
        <>
            <Navbar className="justify-content-between" bg="dark" variant="dark">
                <Nav.Item>
                    <Button
                        variant="dark"
                        onClick={useCallback(() => window.history.back(), [])}
                        title="Close this task">
                        <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                    </Button>
                </Nav.Item>
                <Navbar.Brand>Flow Verification</Navbar.Brand>
                <Nav.Item>
                    <Button variant="dark" onClick={handleTaskClose} title="Close this task">
                        <FontAwesomeIcon size="lg" icon={faTimes} />
                    </Button>
                </Nav.Item>
            </Navbar>
            <Spacer bottom="xlg" />
        </>
    );
};
export default NavigationTask;
