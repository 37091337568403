import React from "react";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NavigationTooltip from "components/NavigationTooltip";
import { IBreadcrumbNavigationButtonsPropsTypes } from "./interfaces";

import { Button, OverlayTrigger } from "react-bootstrap";

export const BreadcrumbNavigationButtons: React.FunctionComponent<
    IBreadcrumbNavigationButtonsPropsTypes
> = ({ isFirst, isLast, navigateToPreviousFlow, navigateToNextFlow }) => {
    return (
        <OverlayTrigger
            placement="bottom"
            overlay={<NavigationTooltip tooltipId={"breadcrumb-tooltip"} />}>
            <div className="breadcrumbs-nav-buttons">
                <Button
                    variant="outline-secondary"
                    as="button"
                    onClick={navigateToPreviousFlow}
                    disabled={isFirst}>
                    <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                </Button>
                <Button
                    variant="outline-secondary"
                    as="button"
                    onClick={navigateToNextFlow}
                    disabled={isLast}>
                    <FontAwesomeIcon size="lg" icon={faArrowRight} />
                </Button>
            </div>
        </OverlayTrigger>
    );
};

export default BreadcrumbNavigationButtons;
