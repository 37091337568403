import React, { useEffect, useState } from "react";

import { faArrowAltCircleDown, faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import addProtocol from "utils/addProtocol";
import createDropdownOptionLink from "utils/createDropdownOptionLink";

import { IFlowExplorerRowPropsTypes } from "./interfaces";
import ScreenshotWithPane from "components/ScreenshotWithPane";
import FlowExplorerDropdown from "components/FlowExplorerDropdown";

import "./FlowExplorerRow.scss";

const FlowExplorerRow: React.FunctionComponent<IFlowExplorerRowPropsTypes> = ({
    flow,
    navigate,
    showLexemes,
}) => {
    const [currentFlows, setCurrentFlows] = useState([]);
    const lastFlow = flow.lastFlow;

    // The link points to the base URL so the users can select and go back
    const linkUrl = flow.baseUrl;

    // This happens when you switch filters and the needed flow doesn't exist anymore
    // Or if you are at the last flow

    useEffect(() => {
        const flows = flow.flows.sort((prev: { name: string }, next: { name: string }) =>
            prev.name.toLowerCase().localeCompare(next.name.toLowerCase()),
        );

        setCurrentFlows(flows);
    }, [flow.flows]);

    let flowToShow = null;

    if (currentFlows.length < 1) {
        flowToShow = (
            <div className="FlowComplete">
                <FontAwesomeIcon size="lg" icon={faCheckCircle} />
            </div>
        );
    } else if (currentFlows[0] && currentFlows.length === 1) {
        const { id, name, file, width, height, metadata } = currentFlows[0];
        const navigationLink = createDropdownOptionLink(linkUrl);

        flowToShow = (
            <ScreenshotWithPane
                id={id}
                src={addProtocol(file)}
                width={width}
                height={height}
                caption={name}
                onClick={() => navigate(navigationLink)} // The link points to the base URL so the users can select and go back
                lexemes={metadata?.lexemes}
                showLexemes={showLexemes}
                colWidth={3}
            />
        );
    } else if (currentFlows.length > 1) {
        flowToShow = (
            <FlowExplorerDropdown
                navigate={navigate}
                baseUrl={linkUrl}
                currentFlows={currentFlows}
            />
        );
    }
    return (
        <div className="FlowExplorerRow">
            {flowToShow}
            {!lastFlow ? <FontAwesomeIcon size="lg" icon={faArrowAltCircleDown} /> : null}
        </div>
    );
};

export default FlowExplorerRow;
