import React, { Component } from "react";
import ReactModal from "react-modal";

import _ from "lodash";

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import prettyLocale from "utils/prettyLocale";
import prettyPlatform from "utils/prettyPlatform";
import prettyString from "utils/prettyString";

import ScreenshotsWithDropdown from "./ScreenshotsWithDropdown";
import { Button } from "react-bootstrap";

import "./ScreenshotComparison.scss";

ReactModal.setAppElement("#root");

class ScreenshotComparison extends Component {
    constructor() {
        super();
        this.state = {};
        this.dismiss = this.dismiss.bind(this);
    }

    dismiss() {
        this.props.history.replace(this.props.backUrl);
        window.gtag("event", "click", {
            event_label: "CloseScreenshotComparison",
        });
    }

    componentDidMount() {
        this.updateState(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps !== this.props) {
            this.updateState(nextProps);
        }
    }

    updateState(props) {
        this.setState({ ...props });
    }

    render() {
        const { screenshots, comparedField, selectedFilters } = this.state;

        if (!screenshots || screenshots.length < 1 || !selectedFilters) {
            return false;
        }

        const comparedFieldValue = selectedFilters[comparedField];

        let component;
        if (comparedField === "locale") {
            component = (
                <ScreenshotsWithDropdown
                    pretty={prettyLocale}
                    field="locale"
                    secondField="device"
                    screenshots={screenshots}
                    first={comparedFieldValue}
                />
            );
        } else {
            component = (
                <ScreenshotsWithDropdown
                    pretty={_.identity}
                    field="device"
                    secondField="resolution"
                    screenshots={screenshots}
                    first={comparedFieldValue}
                />
            );
        }

        const screenshot = screenshots[0];

        return (
            <ReactModal
                contentLabel="Modal"
                className="ScreenshotComparison"
                overlayClassName="ScreenshotComparisonOverlay"
                isOpen={true}
                onRequestClose={this.dismiss}>
                <Button className="dismiss" size="lg" variant="dark" onClick={this.dismiss}>
                    <FontAwesomeIcon size="lg" icon={faTimes} />
                </Button>

                <h1>{prettyString(screenshot.name)}</h1>

                <h4>
                    {prettyPlatform(screenshot.platform)} {screenshot.version}
                </h4>

                {component}
            </ReactModal>
        );
    }
}

export default ScreenshotComparison;
