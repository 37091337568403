import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "./Toggle.scss";

const propTypes = {
    /**
     * The DOM "id" property for the "input" element
     */
    id: PropTypes.string,
    /**
     * The DOM "name" property for the "input" element
     */
    name: PropTypes.string,
    /**
     * The "value" property of the "input" element
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /**
     * If the state of the component is "checked"
     */
    isChecked: PropTypes.bool,
    /**
     * If the component is in "disabled" state
     */
    isDisabled: PropTypes.bool,
    /**
     * "onChange" handler attached to the "input" element
     */
    onChange: PropTypes.func,
};

const defaultProps = {
    isChecked: false,
    isDisabled: false,
    onChange: () => {},
};

const Toggle = (props) => {
    const { id, isChecked, isDisabled, onChange } = props;

    const className = classNames({
        "csms-toggle": true,
        "csms-toggle--disabled": isDisabled,
    });

    return (
        <div className={className}>
            <input
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                disabled={isDisabled}
            />
            <span className="csms-toggle__surrogate" />
        </div>
    );
};

Toggle.propTypes = propTypes;
Toggle.defaultProps = defaultProps;

export default Toggle;
