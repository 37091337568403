import { get, post } from "../api";
import parseSearchString from "utils/parseSearchString";

export const getVerification = function (flowId: number) {
    const params: { task_id: string | null; flow_id?: number } = getParams();
    params.flow_id = flowId;
    return get("flow_status", params);
};

export const getVerifications = function () {
    return get("flow_status", getParams()).then(
        (verifications: {
            verifications: {
                flow_id: number;
            }[];
        }) => {
            return verifications.verifications.map(({ flow_id: id, ...rest }) => ({ id, ...rest }));
        },
    );
};

export const setFlowStatus = function (data: {
    flow_id: number;
    comments?: string;
    flow_status: string;
}) {
    return post("set_flow_status", { ...data, ...getParams() });
};

export const getScreenshotsVerifications = function (data: {
    flow_id: number;
    task_id: number;
    is_parent_task: boolean;
}) {
    return get("get_screenshots_verifications", data);
};

export const setScreenshotVerification = function (data: {
    task_id: number;
    screenshot_id: number;
    screenshot_status: string;
    comment: string;
    flow_id: number;
}) {
    return post("set_screenshot_verification", data);
};

function getParams() {
    const params = parseSearchString();
    const taskId = params.get("taskId");
    return { task_id: taskId };
}
