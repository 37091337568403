import React, { useCallback } from "react";

import { ITranslatorLanguagePairPropsTypes } from "./interfaces";

import { InputGroup, DropdownButton, Dropdown } from "react-bootstrap";

const TranslatorLanguagePair: React.FunctionComponent<ITranslatorLanguagePairPropsTypes> = ({
    translator,
    locales,
    selectedLocale,
    onSelectLocale,
    checked,
    onCheck,
}) => {
    const onTranslatorCheck = useCallback(
        () => onCheck(translator, !checked),
        [checked, onCheck, translator],
    );

    const onTranslatorSelectLocale = useCallback(
        (event) => {
            onSelectLocale(translator, event);
        },
        [onSelectLocale, translator],
    );

    const handleCheckboxChange = useCallback(() => {
        // We need empty function because there is an error if onChange property
        // is not send to InputGroup.Checkbox
    }, []);

    return (
        <InputGroup className="mb-3">
            <InputGroup.Prepend onClick={onTranslatorCheck}>
                <InputGroup.Checkbox checked={checked} onChange={handleCheckboxChange} />
            </InputGroup.Prepend>
            <InputGroup.Prepend onClick={onTranslatorCheck}>
                <InputGroup.Text>{translator}</InputGroup.Text>
            </InputGroup.Prepend>
            {checked ? (
                <DropdownButton
                    as={InputGroup.Prepend}
                    variant="outline-secondary"
                    title={<span>{selectedLocale || "Choose"}</span>}
                    id={translator.replace(/\s/g, "-").toLowerCase()}
                    onSelect={onTranslatorSelectLocale}>
                    {locales.map((locale) => (
                        <Dropdown.Item eventKey={locale.value} key={locale.value}>
                            {locale.display}
                        </Dropdown.Item>
                    ))}
                </DropdownButton>
            ) : null}
        </InputGroup>
    );
};

export default TranslatorLanguagePair;
