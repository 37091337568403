const decodeQueryParams = (queryParamsString: string) => {
    let newQueryParamsString = queryParamsString;
    if (queryParamsString.startsWith("?")) {
        newQueryParamsString = queryParamsString.slice(1);
    }

    let match;
    const search = /([^&=]+)=?([^&]*)/g;

    const decodeJSON = function (str: string) {
        let decodedString = decodeURIComponent(str);

        try {
            decodedString = JSON.parse(decodedString);
        } catch (error) {
            console.error(error);
        }

        return decodedString;
    };

    const urlParams: { [key: string]: string } = {};

    match = search.exec(newQueryParamsString);

    while (match) {
        urlParams[decodeURIComponent(match[1])] = decodeJSON(match[2]);
        match = search.exec(newQueryParamsString);
    }

    return urlParams;
};

export default decodeQueryParams;
