import React, { Component, createRef } from "react";
import ReactLoading from "react-loading";

import appState from "appState";

import encodeIntoQueryParams from "utils/encodeIntoQueryParams";
import calcFlowImagePadding from "utils/calcFlowImagePadding";
import FlowNavigator from "utils/services/FlowNavigator";
import makeTextLinksClickable from "utils/makeTextLinksClickable";
import checkObjectsShallowEquality from "utils/checkObjectsShallowEquality";
import { filterSectionsByFlowsArray } from "utils/filterSectionsByFlowsArray";

import {
    allScreenshotsAreIdentical,
    areFiltersIdentical,
    loadDifferentOnly,
    fetchAll,
    fetchFlows,
} from "./helpers";

import FlowComparisonPageIdenticalFiltersMessage from "./FlowComparisonPageIdenticalFiltersMessage";
import FlowComparisonColumn from "./FlowComparisonColumn";
import BreadcrumbsNavigation from "components/BreadcrumbsNavigation";
import TripleColumns from "components/TripleColumns";
import getScreenshotsList from "components/getScreenshotsList";
import Screenshot from "components/Screenshot";
import ScrollToTop from "components/ScrollToTop";
import NavigationButton from "components/NavigationButton";
import { Container, Row, Col, Card, Tooltip, OverlayTrigger } from "react-bootstrap";

import "./FlowComparisonPage.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentDots } from "@fortawesome/free-solid-svg-icons";
import {pixelDiffRatioThreshold} from "../../components/Screenshot/helpers";

const beeLoader: string = require("../../../public/logos/bee-from-sveta.svg");

class FlowComparisonPage extends Component {
    constructor(props: {} | Readonly<{}>) {
        super(props);
        this.changeMode = this.changeMode.bind(this);
        this.navigateToPreviousFlow = this.navigateToPreviousFlow.bind(this);
        this.navigateToNextFlow = this.navigateToNextFlow.bind(this);
        this.changeFlowId = this.changeFlowId.bind(this);
        this.handleComparisonFLowsNavigation = this.handleComparisonFLowsNavigation.bind(this);

        this.state = {
            firstFilter: null,
            secondFilter: null,
            firstFlow: null,
            secondFlow: null,
            firstSectionInfo: { finished: false, sections: null },
            secondSectionInfo: { finished: false, sections: null },
            comparisonData: null,
            showComparison: false,
            excludeIdentical: false,
            differentOnly: false,
            differentFlows: null,
            differentOnlySearch: "",
            progress: 0,
        };
    }

    handleKeyDown = (event: { keyCode: number }) => {
        // keyCode 17 is Control
        if (event.keyCode === 17) {
            document.addEventListener("keydown", this.handleComparisonFLowsNavigation);
        }
    };

    handleKeyUp = (event: { keyCode: number }) => {
        // keyCode 17 is Control
        if (event.keyCode === 17) {
            document.removeEventListener("keydown", this.handleComparisonFLowsNavigation);
        }
    };

    override componentDidMount() {
        fetchAll(this);

        document.addEventListener("keydown", this.handleKeyDown);
        document.addEventListener("keyup", this.handleKeyUp);

        if (!this.props.differentOnlySearch) {
            this.setState({ differentOnlySearch: this.props.location.search });
        }
    }

    override componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("keyup", this.handleKeyUp);
    }

    override componentDidUpdate(
        prevProps: Readonly<{ [key: string]: any }>,
        prevState: Readonly<{}>,
    ): void {
        const { differentOnly, differentFlows, firstFilter, secondFilter, differentOnlySearch } =
            this.state;

        if (
            firstFilter &&
            secondFilter &&
            differentOnly &&
            prevState.differentOnly !== differentOnly &&
            (!differentFlows || differentOnlySearch !== this.props.location.search)
        ) {
            loadDifferentOnly(this);
        }
        if (!checkObjectsShallowEquality(this.props, prevProps)) {
            fetchAll(this);
        }
    }

    changeMode(props: {
        showComparison: boolean;
        excludeIdentical: boolean;
        differentOnly: boolean;
    }) {
        const { showComparison, excludeIdentical, differentOnly } = props;
        const { firstFilter, secondFilter } = this.state;

        if (firstFilter && secondFilter) {
            const state = {
                showComparison,
                excludeIdentical,
                differentOnly,
            };

            if (!areFiltersIdentical(firstFilter, secondFilter)) {
                this.setState(state);
            }
        }
    }

    navigateTo(delta: number) {
        const { firstFlow, secondFlow, differentOnly, firstSectionInfo, differentFlows } =
            this.state;

        if (firstFlow && secondFlow && firstSectionInfo) {
            let breadcrumbsSections = firstSectionInfo.sections;
            if (differentOnly && differentFlows) {
                breadcrumbsSections = filterSectionsByFlowsArray(
                    breadcrumbsSections,
                    differentFlows,
                );
            }
            // The opposite shouldn't ever happen as we do not show buttons if flows have different ids
            if (firstFlow.id === secondFlow.id) {
                if (FlowNavigator.flowIsFirst(breadcrumbsSections, firstFlow.id) && delta < 0)
                    return;

                if (FlowNavigator.flowIsLast(breadcrumbsSections, firstFlow.id) && delta > 0)
                    return;
                const flowId = FlowNavigator.getFlowId(breadcrumbsSections, firstFlow.id, delta);
                // only fetch flows where there is a proper id
                if (flowId) {
                    fetchFlows(flowId, this);

                    window.scrollTo({ top: 0 });
                }
            }
        }
    }

    navigateToPreviousFlow() {
        this.navigateTo(-1);
    }

    navigateToNextFlow() {
        this.navigateTo(1);
    }

    handleComparisonFLowsNavigation(event: { keyCode: number }) {
        if (event.keyCode === 188) {
            // keyCode 188 is "<" (left angle bracket)
            this.navigateToPreviousFlow();
        } else if (event.keyCode === 190) {
            // keyCode 190 is ">" (right angle bracket)
            this.navigateToNextFlow();
        }
    }

    changeFlowId(side, flowId, filters) {
        let newState = "/flow-comparison/";
        const { firstFlow, secondFlow } = this.state;

        if (firstFlow && secondFlow) {
            if (side === "altFlow") {
                filters = { altFlow: filters };
                newState += `${this.state.firstFlow?.id}/${flowId}`;
            } else {
                filters = { flow: filters };
                newState += `${flowId}/${this.state.secondFlow?.id}`;
            }
            appState.set(filters, false, newState);
        }
    }

    render() {
        const {
            firstFilter,
            secondFilter,
            firstFlow,
            secondFlow,
            firstSectionInfo: { finished: firstSectionFinished, sections: firstSections } = {},
            secondSectionInfo: { finished: secondSectionFinished, sections: secondSections } = {},
            comparisonData,
            showComparison,
            excludeIdentical,
            differentOnly,
            differentFlows,
            progress,
        } = this.state;

        if (!firstFilter || !secondFilter) {
            return (
                <div className="loader-container">
                    <ReactLoading type="bubbles" color="#888" />
                </div>
            );
        }
        const anyComparable = comparisonData?.some(
            (item: { isComparable: boolean }) => item.isComparable,
        );

        const firstFlowScreenshotRatio = (
            firstFlow?.screenshots?.[0]?.height / firstFlow?.screenshots?.[0]?.width
        ).toFixed(2);

        const secondFlowScreenshotRatio = (
            secondFlow?.screenshots?.[0]?.height / secondFlow?.screenshots?.[0]?.width
        ).toFixed(2);

        const areScreenshotsHaveDifferentRatio =
            firstFlowScreenshotRatio !== secondFlowScreenshotRatio;
        appState.set({ areScreenshotsHaveDifferentRatio });

        const safeFirstFlowId = firstFlow?.id || secondFlow?.id;
        const safeSecondFlowId = secondFlow?.id || firstFlow?.id;
        const disableButtonSides = [];

        let currentSections = firstSections;

        if (differentOnly && differentFlows) {
            currentSections = filterSectionsByFlowsArray(currentSections, differentFlows);
        }

        if (FlowNavigator.flowIsFirst(currentSections, safeFirstFlowId)) {
            disableButtonSides.push("left");
        }
        if (FlowNavigator.flowIsLast(currentSections, safeFirstFlowId)) {
            disableButtonSides.push("right");
        }
        if (!currentSections?.length) {
            disableButtonSides.push("left");
            disableButtonSides.push("right");
        }
        // we need to know length of initial screenshots list (this is default length for comparison) because
        // we are going to use this length when we compare list of screenshots
        // to an empty column with suggestion buttons for example

        const initialScreenshotsListLength =
            Math.max(
                this.state.firstFlow?.screenshots?.length,
                this.state.secondFlow?.screenshots?.length,
            ) || 0;

        const isScreenshotsIdentical = allScreenshotsAreIdentical(comparisonData);

        const leftItems =
            flowIsInList(safeFirstFlowId, firstSections) || !anyComparable
                ? getItemsForComparison({
                      comparisonData,
                      isFinished: firstSectionFinished,
                      sections: firstSections,
                      flow: firstFlow,
                      filter: firstFilter,
                      encodeComparisonRoute(id: number) {
                          return `/flow-comparison/${id}/${safeSecondFlowId}?${appState.encodeState()}`;
                      },
                      encodeFilterRoute(filter) {
                          return `/flow-comparison/${safeFirstFlowId}/${safeSecondFlowId}?${appState.encodeState(
                              {
                                  flow: filter,
                              },
                          )}`;
                      },
                      onClick: this.navigateToPreviousFlow,
                      flowSide: "left",
                      disableButtonSides,
                      anyComparable,
                      showComparison,
                      excludeIdentical,
                      differentOnly,
                      differentFlows,
                      initialScreenshotsListLength,
                      isScreenshotsIdentical,
                      altFlow: secondFlow,
                      altSections: secondSections,
                  })
                : noFlowStub(
                      "left",
                      this.navigateToPreviousFlow,
                      disableButtonSides,
                      secondFlow?.screenshots.length,
                  );

        const rightItems =
            flowIsInList(safeSecondFlowId, secondSections) || !anyComparable
                ? getItemsForComparison({
                      comparisonData,
                      isFinished: secondSectionFinished,
                      sections: secondSections,
                      flow: secondFlow,
                      filter: secondFilter,
                      encodeComparisonRoute(id: number) {
                          return `/flow-comparison/${safeFirstFlowId}/${id}?${appState.encodeState()}`;
                      },
                      encodeFilterRoute(filter) {
                          return `/flow-comparison/${safeFirstFlowId}/${
                              secondFlow?.id
                          }?${appState.encodeState({
                              altFlow: filter,
                          })}`;
                      },
                      onClick: this.navigateToNextFlow,
                      flowSide: "right",
                      disableButtonSides,
                      anyComparable,
                      showComparison,
                      excludeIdentical,
                      differentOnly,
                      differentFlows,
                      initialScreenshotsListLength,
                      isScreenshotsIdentical,
                      altFlow: firstFlow,
                      altSections: firstSections,
                  })
                : noFlowStub(
                      "right",
                      this.navigateToNextFlow,
                      disableButtonSides,
                      firstFlow?.screenshots.length,
                  );

        const comparisonImageWidth =
            this.state.firstFlow?.screenshots[0]?.width ||
            this.state.secondFlow?.screenshots[0]?.width;

        const comparisonImageHeight =
            this.state.firstFlow?.screenshots[0]?.height ||
            this.state.secondFlow?.screenshots[0]?.height;
        let noComparisonToShow = null; // show no elements by default

        //
        const areImagesLoaded =
            comparisonData && comparisonData.every((screenshot) => screenshot.dataUrl);

        // if there any comparable screenshots inside the flows and Exclude Identical mode activated
        if (anyComparable && excludeIdentical && !differentOnly) {
            // screenshots are identical and at least one is comparable  - then we need to show message
            if (isScreenshotsIdentical && areImagesLoaded)
                noComparisonToShow = (
                    <h3 className={"all-flows-identical"}>
                        All screenshots are identical using this set of filters
                    </h3>
                );
            else if (
                // we need to have loader while waiting for proper comparisonData to be uploaded
                firstFlow?.screenshots.length &&
                secondFlow?.screenshots.length &&
                !areImagesLoaded
            )
                noComparisonToShow = (
                    <ReactLoading type="bubbles" delay={0} className="loading-exclude-identical" />
                );
        } else if (
            anyComparable &&
            differentOnly &&
            ((isScreenshotsIdentical && areImagesLoaded) || !differentFlows?.length)
        ) {
            noComparisonToShow = (
                <Card bg="info">
                    <Card.Body>
                        No difference for current flows, please navigate to the previous or next
                        flow, if they exist
                    </Card.Body>
                </Card>
            );
        }

        const identicalFiltersMessage = areFiltersIdentical(firstFilter, secondFilter) ? (
            <FlowComparisonPageIdenticalFiltersMessage filter={firstFilter} />
        ) : null;

        return progress < 100 && progress !== -1 && differentOnly ? (
            <Container fluid={true}>
                <Row className="loader-bee-text">
                    <Col>
                        I am trying my best comparing all the images,
                        <br />
                        please be patient with me!
                        <br />
                        <br />
                        <strong>{progress}</strong>%
                        <img className="loader-bee-img" src={beeLoader} />
                    </Col>
                </Row>
            </Container>
        ) : (
            <Container className="FlowComparisonPage">
                {identicalFiltersMessage}

                <Row>
                    <FlowComparisonColumn
                        flowKey="flow"
                        flowId={safeFirstFlowId}
                        filter={firstFilter}
                        changeMode={this.changeMode}
                        anyComparable={anyComparable}
                        changeFlowId={this.changeFlowId}
                        altFlow={secondFlow}
                        areFlowsTheSame={areFiltersIdentical(firstFilter, secondFilter)}
                        isScreenshotsIdentical={isScreenshotsIdentical}
                        differentOnly={differentOnly}
                    />

                    <Col xs={12} md={8}>
                        <TripleColumns
                            className="ScreenshotList"
                            leftItems={leftItems}
                            middleItems={
                                showComparison &&
                                anyComparable &&
                                !areFiltersIdentical(firstFilter, secondFilter)
                                    ? getComparisonImagesList(
                                          comparisonData,
                                          excludeIdentical,
                                          differentOnly,
                                          differentFlows,
                                          comparisonImageWidth,
                                          comparisonImageHeight,
                                      )
                                    : null
                            }
                            rightItems={rightItems}
                        />

                        {noComparisonToShow}
                    </Col>
                    <FlowComparisonColumn
                        flowKey="altFlow"
                        flowId={safeSecondFlowId}
                        filter={secondFilter}
                        changeFlowId={this.changeFlowId}
                        altFlow={firstFlow}
                        differentOnly={differentOnly}
                    />
                </Row>

                <ScrollToTop />
            </Container>
        );
    }
}

export default FlowComparisonPage;

function getItemsForComparison({
    sections,
    flow,
    filter,
    encodeComparisonRoute,
    encodeFilterRoute,
    comparisonData,
    flowSide,
    onClick,
    disableButtonSides,
    excludeIdentical,
    differentOnly,
    differentFlows,
    initialScreenshotsListLength,
    isScreenshotsIdentical,
    showComparison,
    altFlow,
}) {
    const screenshotsList = getScreenshotsList({
        comparisonData,
        screenshots: flow?.screenshots,
        suggestions: flow?.suggestions,
        baseUrl: `/flow/${flow?.id}`,
        currentFilter: filter,
        searchQuery: `?${encodeIntoQueryParams({ flow: filter })}`,
        encodeFilterRoute,
        colWidth: 12,
        disableStats: true,
        excludeIdentical,
        differentOnly,
        showComparison,
    });

    // we need initialScreenshotsListLength when one of the flows has screenshots and another one doesn't, for example another one has suggestions
    let screenshotListLength = initialScreenshotsListLength;
    // "Exclude Identical" is on and there are existing screenshots
    if (showComparison && excludeIdentical) {
        // all screenshots are identical and there are no screenshots to show
        if (isScreenshotsIdentical && !screenshotsList.length) screenshotListLength = 0;
        // SOME screenshots are identical and there are SOME screenshots to show
        else if (!isScreenshotsIdentical && screenshotsList.length)
            screenshotListLength = screenshotsList.length;
    } else if (showComparison && differentOnly && !differentFlows?.length) {
        screenshotListLength = 0;
    }

    const isNavButtonDisabled = disableButtonSides.includes(flowSide);
    const crumbs = getCrumbsFromSections({
        sections,
        flow,
        encodeComparisonRoute,
        differentOnly,
        differentFlows,
    });

    const leftSectionsFromLocalStorage = JSON.parse(sessionStorage.getItem("leftSections") || "[]");
    const leftFlowsFromLocalStorage =
        leftSectionsFromLocalStorage?.filter(
            (section: { name: string }) => section.name === flow.section,
        )?.[0]?.flows || [];
    const doesFirstFlowHaveSections =
        leftSectionsFromLocalStorage.length > 1 || leftFlowsFromLocalStorage.length > 1;

    const flowDescriptionWithLinks = makeTextLinksClickable(flow.description);
    const flowComment = flow.flow_comment;

    return [
        flow?.id === altFlow?.id && doesFirstFlowHaveSections ? (
            <NavigationButton
                key={`nav-button-${flowSide}-${flow?.id}`}
                flowSide={flowSide}
                onClick={onClick}
                disabled={isNavButtonDisabled}
                hasTooltip={true}
            />
        ) : null,

        <BreadcrumbsNavigation key="breadcrumb" crumbs={crumbs} isComparison={true} flow={flow} />,
        <Card
            className="flow-description"
            key={`flow-description-${flowSide}-${flow?.id}`}
            body={true}>
            {flowDescriptionWithLinks}
            {flowComment ? (
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="flow-comment-comparison">{flowComment}</Tooltip>}>
                    <FontAwesomeIcon
                        className="flow-comment-comparison"
                        size="lg"
                        icon={faCommentDots}
                        color="#17a2b8"
                    />
                </OverlayTrigger>
            ) : null}
        </Card>,
        differentOnly && !differentFlows?.length ? [] : screenshotsList,
        screenshotListLength > 1 && flow?.id === altFlow?.id && doesFirstFlowHaveSections ? (
            <NavigationButton
                flowSide={flowSide}
                onClick={onClick}
                disabled={isNavButtonDisabled}
                hasTooltip={false}
            />
        ) : null,
    ].filter(Boolean);
}

function getCrumbsFromSections({
    sections,
    flow,
    encodeComparisonRoute,
    differentOnly,
    differentFlows,
}: {
    sections: { name: string; id: number; flows: { name: string; id: number }[] }[];
    flow: { name: string; section: string };
    encodeComparisonRoute: string;
    differentOnly: boolean;
    differentFlows: number[];
}) {
    let breadcrumbsSections = sections;

    const currentFlowSection = flow.section;
    const currentFlowName = flow.name;

    if (differentOnly && differentFlows) {
        breadcrumbsSections = filterSectionsByFlowsArray(breadcrumbsSections, differentFlows);
    }
    return breadcrumbsSections.map(({ name, id, flows }) => ({
        name,
        id,
        isCurrent: name === currentFlowSection,
        url: encodeComparisonRoute(flows?.[0]?.id),

        crumbs: flows.map(({ name: childName, id: childId }: { name: string; id: number }) => ({
            name: childName,
            id: childId,
            isCurrent: childName === currentFlowName,
            url: encodeComparisonRoute(childId),
        })),
    }));
}

function getComparisonImagesList(
    comparisonData,
    excludeIdentical,
    differentOnly,
    differentFlows,
    width,
    height,
) {
    // we need width and height for creating loader element
    // so it would be the same size as a screenshot
    const screenshots = comparisonData.map((comparisonItem, idx) => {
        const comparable = comparisonItem.isComparable;
        const toBeIncluded =
            (!excludeIdentical && !differentOnly) ||
            (!excludeIdentical && differentFlows?.length) ||
            (excludeIdentical && comparisonItem.diffPixelRatio > pixelDiffRatioThreshold);

        // comparisonItem.dataUrl is going to be empty string "" when image is not loaded and comparison is in progress
        // that's when we need loader
        const comparisonItemImage = comparisonItem.dataUrl ? (
            <Screenshot id={idx} index={idx} key={idx} src={comparisonItem.dataUrl} />
        ) : (
            <div className="loader-container">
                <ReactLoading type="bubbles" color="#888" key={idx} />
            </div>
        );

        const comparisonItemImageColClasses = `ScreenshotWithoutPane${
            comparisonItem.dataUrl ? "" : " loading"
        }`;
        const comparisonItemImageColStyles = comparisonItem.dataUrl
            ? { paddingBottom: "initial" }
            : { paddingBottom: `${calcFlowImagePadding(width, height)}%` }; // the same calculation is applied for Screenshot

        return comparable && toBeIncluded ? (
            <Col
                key={idx}
                className={comparisonItemImageColClasses}
                style={comparisonItemImageColStyles}>
                {comparisonItemImage}
            </Col>
        ) : null;
    });

    if (!screenshots.every((element) => element === null)) {
        return [null, null, null, [screenshots], null];
    } else {
        return null;
    }
}

function flowIsInList(flowId, sections) {
    let inList = false;

    sections?.forEach((section) => {
        if (section.flows.map((flow) => flow?.id).includes(flowId)) {
            inList = true;
        }
    });
    return inList;
}

function noFlowStub(flowSide, onClick, disableButtonSides, otherSideScreenshotsAmount) {
    const isNavButtonDisabled = disableButtonSides.includes(flowSide);
    const out = [
        <NavigationButton
            key={`top-nav-button-${flowSide}`}
            flowSide={flowSide}
            onClick={onClick}
            disabled={isNavButtonDisabled}
            hasTooltip={true}
        />,
        <Card key="no-flow" body={true}>
            Unfortunately, this flow is not available for the given filter
        </Card>,
    ];

    // This is to make bottom button aligned with the one on the other side
    for (let i = 0; i < otherSideScreenshotsAmount + 2; i++) {
        out.push(<></>);
    }
    out.push(
        <NavigationButton
            key={`bottom-nav-button-${flowSide}`}
            flowSide={flowSide}
            onClick={onClick}
            disabled={isNavButtonDisabled}
            hasTooltip={false}
        />,
    );
    return out;
}
