const FlowNavigator = {
    currentFlowIndex(flowList, currentFlowId) {
        let currentFlowIndex = -1;
        flowList?.forEach((flow, idx) => {
            if (flow.id === Number(currentFlowId)) {
                currentFlowIndex = idx;
            }
        });

        return currentFlowIndex;
    },

    getFlowList(sections) {
        let flowList = [];

        sections?.forEach((section) => {
            if (section?.crumbs) {
                flowList = flowList.concat(section.crumbs);
            } else {
                flowList = flowList.concat(section.flows);
            }
        });
        return flowList;
    },

    getFlowId(flows, currentFlowId, delta = 0) {
        if (!flows?.length || !currentFlowId) return null;

        const flowList = this.getFlowList(flows);
        const index = this.currentFlowIndex(flowList, currentFlowId) + delta;
        if (index === -1) return null;
        else return flowList?.[index].id;
    },

    flowIsFirst(flows, currentFlowId) {
        if (!flows?.length || !currentFlowId) return false;

        const flowList = this.getFlowList(flows);
        return this.currentFlowIndex(flowList, currentFlowId) === 0;
    },

    flowIsLast(flows, currentFlowId) {
        if (!flows?.length || !currentFlowId) return false;
        const flowList = this.getFlowList(flows);
        // the condition should be true only when array has values in it
        return flowList?.length
            ? this.currentFlowIndex(flowList, currentFlowId) === flowList?.length - 1
            : false;
    },
};

export default FlowNavigator;
