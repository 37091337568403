import { get, post } from "../api";
import { cache } from "utils/cache";

export const getUsers = cache(function () {
    return get(`users_roles`).then((users) => {
        const output = {};
        users.forEach((user) => {
            output[user.id] = { name: user.name, role: user.role };
        });
        return output;
    });
});

export const saveRoles = cache(function (changes) {
    return post(`save_roles`, { user: changes });
});
