import { get } from "../api";
import { cache } from "utils/cache";

export const getRun = cache(function (runId) {
    return get(`run/${runId}`).then((run) => {
        if (run.screenshots) {
            run.screenshots = run.screenshots.map((screenshot) => {
                let metadata = screenshot.metadata;

                if (typeof metadata === "string") {
                    try {
                        metadata = JSON.parse(metadata);
                    } catch (e) {
                        metadata = {};
                    }
                }

                return {
                    ...screenshot,
                    metadata,
                };
            });

            run.hasLexemes = run.screenshots.some(
                (screenshot) =>
                    screenshot.metadata &&
                    screenshot.metadata.lexemes &&
                    screenshot.metadata.lexemes.length > 0,
            );
        }
        return run;
    });
});
