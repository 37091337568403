import React, { Component } from "react";
import "./ErrorMessage.scss";

class ErrorMessage extends Component {
    constructor() {
        super();
        this.toggleTrace = this.toggleTrace.bind(this);
        this.state = {
            showTrace: false,
        };
    }

    toggleTrace() {
        this.setState({
            showTrace: !this.state.showTrace,
        });
    }

    render() {
        const { message, error } = this.props;

        const errorMessage = message || (error && error.error);

        let errorTrace = null;

        if (this.state.showTrace && error && error.traceback) {
            errorTrace = error.traceback;

            if (typeof errorTrace === "object") {
                errorTrace = errorTrace.join("\n");
            }
        }

        return (
            <div className="ErrorMessage">
                <div className="ErrorMessageHeading">{`Sorry, something has gone wrong :(`}</div>

                <div className="ErrorMessageText" onClick={this.toggleTrace}>
                    {errorMessage}
                    {errorTrace && <div className="ErrorMessageTrace">{errorTrace}</div>}
                </div>
            </div>
        );
    }
}

export default ErrorMessage;
