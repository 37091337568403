import parse from "html-react-parser";
import React from "react";

const addHTMLtagsToLinks = (text: string): string => {
    const urlPattern = /https?:\/\/\S+/g;

    // Replace URLs with anchor tags
    const textWithLinks = text?.replace(
        urlPattern,
        (url: string) => `<a class="text-link" href="${url}" target="_blank" >${url}</a>`,
    );

    return textWithLinks;
};

const makeTextLinksClickable = (text: string): string | React.JSX.Element | React.JSX.Element[] => {
    const textWithHTMLmarkup = addHTMLtagsToLinks(text);

    return parse(textWithHTMLmarkup);
};

export default makeTextLinksClickable;
