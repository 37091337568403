import { post } from "../../api";
import prettyBrand from "utils/prettyBrand";
import prettyLocale from "utils/prettyLocale";
import prettyPlatform from "utils/prettyPlatform";

export function taskIsComplete(filters) {
    return filters.platform && filters.product && translatorsAreChosen(filters.translatorsLocales);
}

export function translatorsAreChosen(translatorsLocales) {
    return translatorsLocales !== undefined && Object.keys(translatorsLocales).length > 0;
}

export function prettyTranslatorsLocales(translatorsLocales) {
    let output = "\n";
    Object.entries(translatorsLocales).forEach(([translator, locale]) => {
        output += `${translator} -> ${prettyLocale(locale)}\n`;
    });
    return output;
}

export function prettyErrors(errors) {
    let output = "";
    Object.entries(errors).forEach(([translator, error]) => {
        output += `${translator} -> ${error}\n`;
    });
    return output;
}

export function updateTheme(newTheme) {
    document.body.classList.forEach((item) => {
        if (item.startsWith("theme-")) {
            document.body.classList.remove(item);
        }
    });
    document.body.classList.add(`theme-${newTheme.toLowerCase()}`);
}

export const createTask = (
    filters: { platform: string; product: string; translatorsLocales: any },
    setHasNewTasks: (arg0: boolean) => void,
): void => {
    const platform = prettyPlatform(filters.platform);
    const product = prettyBrand(filters.product);
    const translatorsLocales = prettyTranslatorsLocales(filters.translatorsLocales);
    const confirm = window.confirm(`Are you sure you want to create tasks \
to verify ${product} ${platform} for the following translators and locales: \
${translatorsLocales}?`);

    const paramsForTaskCreation = {
        ...filters,
    };

    if (confirm === true) {
        post("tasks/create/", paramsForTaskCreation)
            .then((response) => {
                if (response.result) {
                    setHasNewTasks(true);
                    alert("All tasks are successfully created!");
                } else {
                    setHasNewTasks(true);
                    alert(
                        `Some errors were encountered: \n${prettyErrors(
                            response,
                        )}\nAll other tasks were created successfully!`,
                    );
                }
            })
            .catch((err) => {
                alert(err.error);
            });
    }
};
