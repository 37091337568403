const prettyString = (str: string) => {
    return (str || "")
        .replace(/([a-z])([A-Z])/g, "$1 $2")
        .replace(/([a-zA-Z])([0-9])/g, "$1 $2")
        .replace(/^[a-z]/, function (match) {
            return match.toUpperCase();
        })
        .replace(/[-_]/g, " ");
};
export default prettyString;
