import React, { useEffect, useState } from "react";
import ReactLoading from "react-loading";

import { getFiltersToCreateTask } from "models/getFilters";

import { IFiltersToCreateTaskTypes } from "./interfaces";

import FlowSelector from "components/FlowSelector";
import TasksTable from "components/TasksTable";
import { Col, Container, Row } from "react-bootstrap";

import "./TaskManagerPage.scss";
import ErrorMessage from "components/ErrorMessage";

const TaskManagerPage: React.FunctionComponent = () => {
    const [filter, setFilter] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [hasNewTasks, setHasNewTasks] = useState(false);
    const [pageError, setPageError] = useState(null);

    useEffect(() => {
        getFiltersToCreateTask()
            .then((filterParameters: IFiltersToCreateTaskTypes) => {
                setFilter(filterParameters);
                setIsLoading(false);
            })
            .catch((error: string) => {
                setIsLoading(false);
                // eslint-disable-next-line no-console
                console.error(error);
            });
    }, []);

    if (pageError) return <ErrorMessage error={pageError} />;
    return (
        <Container>
            <Row>
                <Col xs={12} md={3} className="StickyFlow">
                    {isLoading ? (
                        <div className="loader-container">
                            <ReactLoading type="bubbles" color="#888" />
                        </div>
                    ) : (
                        <FlowSelector
                            flowKey="task-creation"
                            filter={filter}
                            setHasNewTasks={setHasNewTasks}
                        />
                    )}
                </Col>
                <Col xs={12} md={9}>
                    <TasksTable
                        hasNewTasks={hasNewTasks}
                        setHasNewTasks={setHasNewTasks}
                        setPageError={setPageError}
                    />
                </Col>
            </Row>
        </Container>
    );
};

export default TaskManagerPage;
