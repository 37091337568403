import React, { Component } from "react";

import appState from "appState";

import { getSection } from "models/getSection";

import FlowSelector from "components/FlowSelector";
import FlowsList from "components/FlowsList";
import BreadcrumbsNavigation from "components/BreadcrumbsNavigation";
import Jumbotron from "components/Jumbotron";
import { Container, Row, Col, Card } from "react-bootstrap";

class SectionPage extends Component {
    componentDidMount() {
        this.checkDefaultFlow(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.checkDefaultFlow(props);
    }

    checkDefaultFlow() {
        const selectedFilters = appState.get("flow");

        getSection(this.props.match.params.sectionId, selectedFilters).then((section) => {
            this.setState({
                section,
            });

            if (section) {
                appState.set({ lastExistingSectionName: section.name });
            }
        });
    }

    render() {
        if (!this.state) {
            return false;
        }

        const section = this.state.section;
        const crumbs = [];

        const currentFiltersValues = appState.get("flow");
        let currentFiltersValuesList = null;

        if (section) {
            const currentSectionBreadcrumb = {
                id: section.id,
                name: section.name,
                isCurrent: true,
            };
            crumbs.push(currentSectionBreadcrumb);
        }

        if (currentFiltersValues) {
            currentFiltersValuesList = (
                <ul className="current-filters-parameters">
                    {Object.entries(currentFiltersValues).map(([key, value]) => (
                        <li key={key}>
                            <strong>{key}</strong>&nbsp;{value}
                        </li>
                    ))}
                </ul>
            );
        }

        const lastExistingSectionName = appState.get("lastExistingSectionName") || "";

        return (
            <Container>
                <Row>
                    <Col xs={12} md={2} className="StickyFlow">
                        <FlowSelector />
                    </Col>
                    <Col xs={12} md={10}>
                        {section ? (
                            <>
                                <BreadcrumbsNavigation showSearch={true} crumbs={crumbs} />
                                <Jumbotron />
                                <FlowsList
                                    search={this.props.location.search}
                                    flows={section.flows}
                                />
                            </>
                        ) : (
                            <Card body={true} bg="warning">
                                <span>
                                    <strong>Section: </strong> {lastExistingSectionName}
                                    <br />
                                    There are no flows with chosen filter parameters ¯\_(ツ)_/¯
                                </span>
                                {currentFiltersValuesList}
                                <span>Please try again.</span>
                            </Card>
                        )}
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default SectionPage;
