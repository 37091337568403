import FileSaver from "file-saver";

import { getFileContent } from "utils/getFileContent";
import addProtocol from "utils/addProtocol";

const getExtension = (file: string) => {
    return file.split(".").reverse()[0];
};

const downloadImage = (url: string, name: string) => {
    url = addProtocol(url);

    getFileContent(url, function (blob: string | Blob) {
        FileSaver.saveAs(blob, `${name}.${getExtension(url)}`);
    });
};

export default downloadImage;
