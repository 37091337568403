import React, { useState, useCallback } from "react";
import { Button, Jumbotron as JumbotronBootStrap } from "react-bootstrap";

const Jumbotron = () => {
    const [isHidden, setIsHidden] = useState(localStorage.getItem("JumbotronHidden") || "false");

    const hide = useCallback(() => {
        setIsHidden("true");
        localStorage.setItem("JumbotronHidden", "true");
    }, []);

    return isHidden === "true" ? null : (
        <JumbotronBootStrap>
            <h1>Welcome!</h1>
            <p>
                With this tool you can find, compare and download screenshots of all the features of
                our app, filtered by language, device, version and more. You can either start by
                setting up your filter on the left, or by tapping on a flow below to jump into the
                screens.
            </p>
            <p>
                <Button variant="primary" onClick={hide}>
                    Got it
                </Button>
            </p>
        </JumbotronBootStrap>
    );
};

export default Jumbotron;
