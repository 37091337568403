import React from "react";

import appState from "appState";
import FlowSelector from "components/FlowSelector";
import SectionsList from "components/SectionsList";

import { Container, Row, Col } from "react-bootstrap";

interface ISectionsListByTCIdPageProps {
    match: {
        params: {
            tcBuildId: number;
        };
    };
    location: {
        search: string;
    };
}

const SectionsListByTCIdPage: React.FunctionComponent<ISectionsListByTCIdPageProps> = (props) => {
    const { tcBuildId } = props.match.params;
    const { search } = props.location;

    const selectedFilters = appState.get("flow");

    return (
        <Container>
            <Row>
                <Col xs={6} md={3} className="StickyFlow">
                    <FlowSelector
                        tcBuildId={tcBuildId}
                        allowChoosingPods={true}
                        isSectionsListPage={true}
                    />
                </Col>
                <Col xs={12} md={9}>
                    <SectionsList
                        tcBuildId={tcBuildId}
                        search={search}
                        selectedFilters={selectedFilters}
                    />
                </Col>
            </Row>
        </Container>
    );
};
export default SectionsListByTCIdPage;
