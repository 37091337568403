import React from "react";
import { LinkContainer } from "react-router-bootstrap";

import prettyString from "utils/prettyString";

import { IFlowPropsTypes } from "./interfaces";

import { Card, ListGroup, ListGroupItem } from "react-bootstrap";

const Flow: React.FunctionComponent<IFlowPropsTypes> = ({ flow, search }) => {
    return (
        <Card>
            <ListGroup>
                <LinkContainer to={`/flow/${flow.id}${search}`}>
                    <ListGroupItem as="a">
                        <strong>{prettyString(flow.name)}</strong>
                        <div>{flow.description}</div>
                    </ListGroupItem>
                </LinkContainer>
            </ListGroup>
        </Card>
    );
};

export default Flow;
