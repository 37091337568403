import React from "react";

import { createStaffpassLexemeURL } from "./helpers";
import { IScreenshotLexemesPropsTypes } from "./interfaces";

import "./ScreenshotLexemes.scss";

const ScreenshotLexemes: React.FunctionComponent<IScreenshotLexemesPropsTypes> = ({
    lexemes,
    isExpandedDown,
}) => {
    return (
        <div className={`ScreenshotLexemes ${isExpandedDown ? " expanded-down" : ""}`}>
            {lexemes ? (
                <>
                    <h2>Lexemes</h2>
                    {lexemes.length ? (
                        <ul>
                            {lexemes.map((lexeme) => (
                                <li key={lexeme}>
                                    <a
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        href={createStaffpassLexemeURL(lexeme)}>
                                        {lexeme}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <p>No lexemes found for this screenshot (&nbsp;˘&nbsp;︹&nbsp;˘&nbsp;)</p>
                    )}
                </>
            ) : null}
        </div>
    );
};

export default ScreenshotLexemes;
