import * as React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import "./Spacer.scss";

type SpacerValue = "xsm" | "sm" | "md" | "lg" | "xlg" | "xxlg" | "gap";

type SpacerProps = {
    /**
     * The children elements
     */
    children?: React.ReactNode;
    /**
     * Top spacing
     */
    top?: SpacerValue;
    /**
     * Right spacing
     */
    right?: SpacerValue;
    /**
     * Bottom spacing
     */
    bottom?: SpacerValue;
    /**
     * Left spacing
     */
    left?: SpacerValue;
};

const propTypes = {
    /**
     * The children elements
     */
    children: PropTypes.node,
    /**
     * Top spacing
     */
    top: PropTypes.oneOf<SpacerProps["top"]>(["xsm", "sm", "md", "lg", "xlg", "xxlg", "gap"]),
    /**
     * Right spacing
     */
    right: PropTypes.oneOf<SpacerProps["right"]>(["xsm", "sm", "md", "lg", "xlg", "xxlg", "gap"]),
    /**
     * Bottom spacing
     */
    bottom: PropTypes.oneOf<SpacerProps["bottom"]>(["xsm", "sm", "md", "lg", "xlg", "xxlg", "gap"]),
    /**
     * Left spacing
     */
    left: PropTypes.oneOf<SpacerProps["left"]>(["xsm", "sm", "md", "lg", "xlg", "xxlg", "gap"]),
};

export const Spacer: React.FunctionComponent<SpacerProps> = ({
    children,
    top,
    right,
    bottom,
    left,
}) => {
    const className = classnames({
        "csms-spacer": true,
        [`csms-spacer--top-${top}`]: top,
        [`csms-spacer--right-${right}`]: right,
        [`csms-spacer--bottom-${bottom}`]: bottom,
        [`csms-spacer--left-${left}`]: left,
    });

    return <div className={className}>{children}</div>;
};

Spacer.propTypes = propTypes;
