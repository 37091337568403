import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import appState from "appState";

import prettyPlatform from "utils/prettyPlatform";
import prettyBrand from "utils/prettyBrand";
import prettyLocale from "utils/prettyLocale";
import parseSearchString from "utils/parseSearchString";

import ScreenshotListItem from "components/ScreenshotListItem";
import { pixelDiffRatioThreshold } from "components/Screenshot/helpers";
import { Button } from "react-bootstrap";

export default function getScreenshotsList({
    suggestions,
    screenshots,
    baseUrl,
    searchQuery,
    currentFilter,
    encodeFilterRoute,
    showLexemes,
    disableStats,
    colWidth,
    onClickScreenshot,
    comparisonData,
    excludeIdentical,
    flowId,
    taskId,
    setScreenshotsVerificationData,
    screenshotsVerificationData,
    setFlowPageStateFlowStatus,
    setIsTaskFinishedStateStatus,
    isTaskFinished,
    isScreenshotsVerificationLoading,
}) {
    if (!screenshots || screenshots.length === 0) {
        if (suggestions && suggestions.length > 0) {
            return [
                <Suggestions
                    key="suggestions"
                    suggestions={suggestions}
                    encodeFilterRoute={encodeFilterRoute}
                    currentFilter={currentFilter}
                />,
            ];
        } else {
            return [
                <h3 key="not-found-suggestion" className="not-found-suggestion">
                    <span> ( • ᴖ • ｡ )</span>
                    <br />
                    <span>The flow is not found for selected filters.</span>
                    <br />
                    <span>
                        The flow is either outside of the selected pod, or doesn&apos;t have any
                        suggestions.
                    </span>
                </h3>,
            ];
        }
    }

    if (excludeIdentical && comparisonData?.length) {
        screenshots = screenshots.filter(
            (_, idx) => comparisonData[idx] && comparisonData[idx].diffPixelRatio > pixelDiffRatioThreshold,
        );
        comparisonData = comparisonData.filter(
            (screenshotData, idx) => screenshotData.diffPixelRatio > pixelDiffRatioThreshold,
        );
    }

    const screenshotColumns = colWidth || 4;

    return screenshots.map((flowItem, index) => (
        <ScreenshotListItem
            comparisonData={comparisonData ? comparisonData[index] : null}
            key={`screenshot-${flowItem.id}`}
            index={index}
            colWidth={screenshotColumns}
            flowItem={flowItem}
            url={baseUrl}
            search={searchQuery}
            hasOverlay={true}
            showLexemes={showLexemes}
            disableStats={disableStats}
            onClickScreenshot={onClickScreenshot}
            currentFilter={currentFilter}
            taskId={taskId}
            flowId={flowId}
            setScreenshotsVerificationData={setScreenshotsVerificationData}
            screenshotsVerificationData={screenshotsVerificationData}
            screenshotsIds={screenshots?.map((screen: { id: number }) => screen.id) || []}
            setFlowPageStateFlowStatus={setFlowPageStateFlowStatus}
            setIsTaskFinishedStateStatus={setIsTaskFinishedStateStatus}
            isTaskFinished={isTaskFinished}
            isScreenshotsVerificationLoading={isScreenshotsVerificationLoading}
        />
    ));
}

function Suggestions({ suggestions, encodeFilterRoute, currentFilter }) {
    let suggestionText = "However here are some alternatives:";

    if (suggestions.length === 1) {
        suggestionText = "However here is an alternative:";
    }
    return [
        <h3 key="not-found-suggestion" className="not-found-suggestion">
            <span>( • ᴖ • ｡ )</span>
            <br />
            <span>The flow is not found for selected filters.</span>
            <br />
            <span>{suggestionText}</span>
        </h3>,

        suggestions.map(
            (
                filter: {
                    device: string;
                    locale: string;
                    platform: string;
                    product: string;
                    version: string;
                    branch: string;
                    os: string;
                },
                idx: React.Key | null | undefined,
            ) => {
                const { device, locale, platform, product, version, branch, os, pod, tc_build_id } =
                    filter;

                const linkTextFields = [];

                const params = parseSearchString();
                const currentTCBuildId = params.get("tcBuildId");

                const search = {
                    device,
                    locale,
                    platform,
                    product,
                    version,
                    branch,
                    os,
                };

                if (product !== currentFilter.product) {
                    linkTextFields.push(prettyBrand(product));
                }

                if (platform !== currentFilter.platform) {
                    linkTextFields.push(prettyPlatform(platform));
                }

                if (locale !== currentFilter.locale) {
                    linkTextFields.push(prettyLocale(locale));
                }

                if (device !== currentFilter.device) {
                    linkTextFields.push(device);
                }

                if (version !== currentFilter.version) {
                    linkTextFields.push(version);
                }

                if (branch !== currentFilter.branch) {
                    linkTextFields.push(branch);
                }

                if (os !== currentFilter.os) {
                    linkTextFields.push(os);
                }

                if (currentFilter.pod) {
                    if (pod !== Number(currentFilter.pod)) {
                        linkTextFields.push(pod);
                    }
                    search.pod = currentFilter.pod;
                }
                if (currentTCBuildId && tc_build_id && currentTCBuildId !== tc_build_id) {
                    linkTextFields.push(`TCBuildId: ${tc_build_id}`);
                }
                const handleClick = () => {
                    appState.set({ TCBuildId: null });
                };

                return (
                    <LinkContainer
                        key={idx}
                        to={encodeFilterRoute(search)}
                        className="SuggestionButton">
                        <Button onClick={handleClick}>{linkTextFields.join(" / ")}</Button>
                    </LinkContainer>
                );
            },
        ),
    ];
}
