import { del, get, post } from "../api";
import { cache } from "utils/cache";

export const getFlow = cache(function (
    flowId: number,
    selectedFilters: any,
    withSuggestions = true,
    tcBuildId?: undefined,
    taskId?: number,
) {
    const params = {
        ...selectedFilters,
        with_suggestions: withSuggestions,
    };
    if (tcBuildId) {
        params.tc_build_id = tcBuildId;
    }
    if (taskId) {
        params.task_id = taskId;
    }

    return (
        get(`flow/${flowId}`, params)
            // Sometimes lexeme metadata is a string
            .then((flow) => {
                if (flow.screenshots) {
                    flow.screenshots = flow.screenshots.map((screenshot) => {
                        let metadata = screenshot.metadata;

                        if (typeof metadata === "string") {
                            try {
                                metadata = JSON.parse(metadata);
                            } catch (e) {
                                metadata = {};
                            }
                        }

                        return {
                            ...screenshot,
                            metadata,
                        };
                    });

                    flow.hasLexemes = flow.screenshots.some(
                        (screenshot) =>
                            screenshot.metadata &&
                            screenshot.metadata.lexemes &&
                            screenshot.metadata.lexemes.length > 0,
                    );
                }

                return flow;
            })
    );
});

export const getFlowComment = function (flowId: number) {
    return get(`flow/get_flow_comment/${flowId}`);
};

export const saveFlowComment = function (flowId: number, flowComment: string) {
    return post(`flow/save_flow_comment`, { flow_id: flowId, flow_comment: flowComment });
};

export const deleteFlowComment = function (flowId: number) {
    return del(`flow/delete_flow_comment/${flowId}`);
};
