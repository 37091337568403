import { get } from "../api";
import { cache } from "utils/cache";

const getDefaultFiltersCached = cache(function (selectedFilters) {
    return get("filters/default", selectedFilters);
});

export const getDefaultFilters = function (selectedFilters) {
    return getDefaultFiltersCached(getFiltersRequest(selectedFilters));
};

function getFiltersRequest(filters) {
    const out = {};

    if (filters.platform) {
        out.platform = filters.platform;
    }

    if (filters.product) {
        out.product = filters.product;
    }

    if (filters.locale) {
        out.locale = filters.locale;
    }

    if (filters.translator) {
        out.translator = filters.translator;
    }

    return out;
}
