import { getToken } from "models/getToken";
import closeTask from "utils/closeTask";

const Auth = {
    isLoggedIn() {
        return localStorage.getItem("isLoggedIn") === "true" || (process.env.API_KEY || "") !== "";
    },

    login(code, cb) {
        getToken(code).then(() => {
            localStorage.setItem("isLoggedIn", "true");
            setTimeout(cb, 100);
        });
    },

    logout(cb) {
        const returnTo = {
            pathname: window.location.pathname,
            search: window.location.search,
        };
        if (!returnTo.pathname.includes("login") && localStorage.getItem("returnTo") === null) {
            localStorage.setItem("returnTo", JSON.stringify(returnTo));
        }
        closeTask();
        localStorage.setItem("isLoggedIn", "false");
        setTimeout(cb, 100);
    },
};

export default Auth;
