import { get } from "../api";
import { cache } from "utils/cache";

export const getSections = function (
    selectedFilters: {
        platform: string;
        product: string;
        device: string;
        locale: string;
        version: string;
        branch?: string;
        os?: string;
    },
    taskId: number | null,
    isReassignMode: boolean,
) {
    // We don't want to cache here because it causes incorrect sections to be returned during comparison
    const params: {
        platform: string;
        product: string;
        device: string;
        locale: string;
        version: string;
        branch?: string;
        os?: string;
        task_id?: number;
        is_reassign_mode?: boolean;
    } = { ...selectedFilters };

    if (taskId) {
        params.task_id = taskId;
    }

    if (isReassignMode) {
        params.is_reassign_mode = isReassignMode;
    }

    return get("flows", params).then((flows) => {
        const sections = sectionsSort(flows);
        return {
            ...flows,
            sections,
        };
    });
};

export const getSectionsByTCId = cache(function (tcBuildId: number, selectedFilters) {
    return get(`flows/tc_build/${tcBuildId}`, selectedFilters).then((flows) => {
        const sections = sectionsSort(flows);
        return {
            ...flows,
            sections,
        };
    });
});

function sectionsSort(flows) {
    const sections = flows.sections.sort(function (section1, section2) {
        return section1.id - section2.id;
    });

    sections.forEach((section) => {
        section.flows = section.flows.sort(function (flow1, flow2) {
            return flow1.id - flow2.id;
        });
    });

    return sections;
}
