import React, { Component } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import ModalWindow from "components/ModalWindow";
import TranslatorLanguagePair from "components/TranslatorLanguagePair";
import SelectAll from "components/SelectAll";

import "./TranslatorMultiChoose.scss";

class TranslatorMultiChoose extends Component {
    constructor(props) {
        super(props);
        this.state = { selectAllChecked: false, selected: {}, modalTranslatorsShow: false };
        this.handleApplyChangesForTranslatorsModal =
            this.handleApplyChangesForTranslatorsModal.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.onSelectAll = this.onSelectAll.bind(this);
        this.initializeSelected = this.initializeSelected.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.locales !== this.props.locales) {
            this.initializeSelected();
        }
    }

    initializeSelected() {
        const selected = {};

        this.props.translators?.forEach((translator) => {
            selected[translator.display] = { checked: false };
        });

        this.setState({
            selected,
        });
    }

    handleApplyChangesForTranslatorsModal() {
        // FIXME: fix logic - if Apply hasn't been clicked - don't save checkbox

        // Removing all checkboxes set without choosing any languages
        const newSelected = this.state.selected;
        Object.keys(newSelected).forEach((event) => {
            if (newSelected[event].checked && newSelected[event].display === undefined) {
                newSelected[event].checked = false;
            }
        });

        const translatorsChosen = Object.values(this.state.selected).some(
            (event) => event.display && event.checked,
        );
        const selectedFiltered = {};

        if (translatorsChosen) {
            const selected = this.state.selected;
            Object.keys(selected).forEach((key) => {
                if (selected[key].checked) {
                    selectedFiltered[key] = selected[key].value;
                }
            });
        }
        this.props.setTranslatorsLocales(selectedFiltered);
    }

    onSelect(translator, value) {
        const newSelected = this.state.selected;
        const locale = this.props.locales.find((locale) => locale.value === value);
        newSelected[translator] = { ...newSelected[translator], ...locale };

        this.setState({
            selected: newSelected,
        });
    }

    onToggle(translator, forcedState = null) {
        const newSelected = this.state.selected;
        const defaultLocale = this.props.translators.find(
            (t) => t.display === translator,
        )?.defaultLocale;

        let newCheckedState;
        if (forcedState === null) {
            newCheckedState = !newSelected[translator].checked;
        } else {
            newCheckedState = forcedState;
        }
        newSelected[translator] = {
            checked: newCheckedState,
            display: this.props.locales.find((t) => t.value === defaultLocale)?.display,
            value: defaultLocale,
        };

        this.setState({
            selected: newSelected,
        });
    }

    onSelectAll() {
        const newState = !this.state.selectAllChecked;
        const selected = { ...this.state.selected };
        Object.keys(selected).forEach((translator) => {
            this.onToggle(translator, newState);
        });
        this.setState({
            selectAllChecked: !this.state.selectAllChecked,
        });
    }

    render() {
        const translatorLanguagePairs =
            this.props.translators && Object.keys(this.state).length > 0
                ? this.props.translators.map((translator) => (
                      <TranslatorLanguagePair
                          key={translator.display.replace(/\s/g, "-").toLowerCase()}
                          translator={translator.display}
                          locales={this.props.locales}
                          selectedLocale={
                              this.state.selected[translator.display]?.display === undefined
                                  ? translator.defaultLocale
                                  : this.state.selected[translator.display]?.display
                          }
                          onSelectLocale={this.onSelect}
                          checked={this.state.selected[translator.display]?.checked}
                          onCheck={this.onToggle}
                      />
                  ))
                : null;

        const modalTranslatorsBody = (
            <>
                <SelectAll
                    handleSelectAll={this.onSelectAll}
                    checked={this.state.selectAllChecked}
                />
                {translatorLanguagePairs}
            </>
        );
        return (
            <div className="TranslatorMultiChoose">
                <ModalWindow
                    modalButtonName="Choose translators"
                    isModalButtonDisabled={this.props.locales === undefined}
                    modalCustomClass="chose-translators"
                    modalTitle="Choose translators and languages"
                    modalBody={modalTranslatorsBody}
                    onApplyChanges={this.handleApplyChangesForTranslatorsModal}
                    buttonCustomClass="flow-selector-button flow-selector-button--choose"
                />
                {this.props.areTranslatorsChosen ? (
                    <FontAwesomeIcon className="check-icon" size="2x" icon={faCheck} />
                ) : null}
            </div>
        );
    }
}

export default TranslatorMultiChoose;
