import React, { Component, MouseEventHandler } from "react";
import { withRouter } from "react-router-dom";
import ReactLoading from "react-loading";

import { post } from "../../api";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle, faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import parseSearchString from "utils/parseSearchString";
import { removeAdditionalParametersFromSearch } from "utils/removeAdditionalParametersFromSearch";
import { getSections, getSectionsByTCId } from "models/getSections";
import { getVerifications } from "models/getVerifications";
import { getListOfTranslators } from "models/getFilters";

import { createFlowsIdsArray } from "./helpers";
import {
    ISectionListPropsTypes,
    ISectionListStateTypes,
    IFinalStateTypes,
    ISelectedFiltersTypes,
} from "./interfaces";

import SectionsListTaskReassignModal from "./SectionsListTaskReassignModal";
import DropdownWithFilter from "components/DropdownWithFilter";
import ErrorMessage from "components/ErrorMessage";
import FlowWarning from "components/FlowWarning";
import Section from "components/Section";
import ModalWindow from "components/ModalWindow";
import { Accordion, Button, Card, CardColumns, Table } from "react-bootstrap";

import "./SectionsList.scss";

class SectionsList extends Component<ISectionListPropsTypes, ISectionListStateTypes> {
    constructor(props: ISectionListPropsTypes) {
        super(props);
        this.state = {
            isLoading: true,
            selected: false,
            sectionInfo: { sections: [] },
            taskId: null,
            screensCount: null,
            flowsCount: null,
            translatorsList: [],
            taskFlowsToShowList: null,
            isReassignMode: false,
            reassignedFlows: [],
            hasNewestVariation: false,
            newestVariation: null,
            currentVariation: null,
            missingFlowsNewestVariation: null,
        };

        this.fetchFlows = this.fetchFlows.bind(this);
        this.changeFailedFlowState = this.changeFailedFlowState.bind(this);
        this.selectFlowsForNewTask = this.selectFlowsForNewTask.bind(this);
    }

    override componentDidMount() {
        this.fetchFlows(this.props);
        const params = parseSearchString();
        const taskId = params.get("taskId");
        const isReassignMode = params.get("isReassignMode");

        this.setState({
            taskId: Number(taskId),
            isReassignMode: isReassignMode === "true",
        });

        getListOfTranslators()
            .then((translatorsList: string[]) => {
                this.setState({
                    translatorsList,
                });
            })
            .catch((error: { status: number; error: string }) => {
                console.error(`Could not get translatorsList: ${error}`);
            });
        if (this.props.translatorsFlows) {
            if (this.props.fillTranslatorsFlowsData)
                this.props.fillTranslatorsFlowsData({
                    [this.props.translator]: createFlowsIdsArray(this.state.sectionInfo.sections),
                });
        }
    }

    override componentDidUpdate(_prevProps: any, prevState: { sectionInfo: { sections: any[] } }) {
        if (this.props.translatorsFlows) {
            if (
                JSON.stringify(this.state.sectionInfo.sections?.sort()) !==
                    JSON.stringify(prevState.sectionInfo.sections?.sort()) &&
                this.props.fillTranslatorsFlowsData
            )
                this.props.fillTranslatorsFlowsData({
                    [this.props.translator]: createFlowsIdsArray(this.state.sectionInfo.sections),
                });
        }
    }

    override UNSAFE_componentWillReceiveProps(props: ISectionListPropsTypes) {
        this.fetchFlows(props);
    }

    fetchFlows({
        selectedFilters,
        tcBuildId,
    }: {
        selectedFilters: ISelectedFiltersTypes;
        tcBuildId: number;
    }) {
        if (!selectedFilters && !tcBuildId) return;

        const selectedFiltersData = selectedFilters || {};

        const params = parseSearchString();
        const taskId = params.get("taskId");
        const isReassignMode = params.get("isReassignMode") === "true";

        Promise.all([
            tcBuildId
                ? getSectionsByTCId(tcBuildId, selectedFiltersData)
                : getSections(selectedFiltersData, Number(taskId), isReassignMode),
            taskId ? getVerifications() : Promise.resolve(null),
        ])
            .then(([sectionInfo, flowStatuses]) => {
                const finalState: IFinalStateTypes = {
                    sectionInfo,
                    flowStatuses,
                    error: false,
                    reassignedFlows: [],
                    flowsCount: sectionInfo.task_flows_count || "n/a",
                    taskFlowsToShowList: sectionInfo.task_flow_sections_list,
                    screensCount: sectionInfo.task_screens_count || "n/a",
                    hasNewestVariation:
                        sectionInfo.newest_variation?.id > sectionInfo.current_variation?.id,
                    currentVariation: sectionInfo.current_variation,
                    newestVariation: sectionInfo.newest_variation,
                    missingFlowsNewestVariation: sectionInfo.missing_flows,
                };

                if (!this.state?.reassignedFlows.length && this.state.isReassignMode && taskId) {
                    const reassignedFlows: number[] = [];
                    if (flowStatuses !== undefined) {
                        flowStatuses.forEach((flowStatus: { status: string; id: number }) => {
                            if (flowStatus.status === "failed") {
                                reassignedFlows.push(flowStatus.id);
                            }
                        });
                    }

                    finalState.reassignedFlows = reassignedFlows;
                }

                this.setState({ ...finalState, isLoading: false });
            })
            .catch((error) => {
                this.setState({ error, isLoading: false });
            });
    }

    selectFlowsForNewTask(
        input: { id: { match: (arg0: RegExp) => string[] }; checked: boolean },
        translator: string,
    ) {
        const flowIdRegex = /^(\d+)/; // for flows from modal window because they have ids with translator name attached
        const selectedFlowId = Number(
            this.props.isChooseFlowsModalOpen ? input.id.match(flowIdRegex)[0] : input.id,
        );

        const isSelected = input.checked;

        let newSelectedFlows = this.props.translatorSelectedFlows;

        if (isSelected) {
            newSelectedFlows = [...newSelectedFlows, selectedFlowId];
        } else {
            newSelectedFlows = newSelectedFlows.filter(
                (flowId: number) => flowId !== selectedFlowId,
            );
        }
        this.props.fillTranslatorsSelectedFlowsData({ [translator]: newSelectedFlows });
        this.props.handleSelectAll(translator, input, newSelectedFlows);
    }

    changeFailedFlowState(input: { id: number; checked: boolean }) {
        let newReassignedFlows = [...this.state.reassignedFlows];
        const id = Number(input.id);

        if (input.checked) {
            newReassignedFlows.push(id);
        } else {
            newReassignedFlows = newReassignedFlows.filter(function (flowId) {
                return flowId !== id;
            });
        }

        this.setState({ reassignedFlows: newReassignedFlows });
    }

    handleTaskReassign: MouseEventHandler<HTMLElement> = (
        evt: React.MouseEvent<HTMLButtonElement>,
    ): undefined => {
        if (this.state.reassignedFlows.length === 0) {
            alert("Error: list of flows to reassign is empty, please choose some");
            return;
        }

        const filters = {
            taskId: this.props.taskId,
            reassignedFlows: this.state.reassignedFlows,
            translator: this.state.selected,
            reassignedTaskVariationId: evt.currentTarget.value,
        };

        post("tasks/create/", filters)
            .then((response) => {
                if (response.result) {
                    this.props.history.push({
                        pathname: "/taskmanager",
                    });
                    alert("Task is successfully created");
                }
            })
            .catch((err) => {
                alert(err.error);
            });
    };

    // eslint-disable-next-line complexity
    render() {
        if (this.state.isLoading) {
            return (
                <div className="loader-container">
                    <ReactLoading type="bubbles" color="#888" />
                </div>
            );
        }

        if (this.state.error) {
            console.error(this.state.error);
            return <ErrorMessage error={this.state.error} />;
        }

        const {
            sectionInfo: { finished: isFinished },
            flowsCount,
            screensCount,
            taskId,
            taskFlowsToShowList,
            hasNewestVariation,
            newestVariation,
            currentVariation,
            missingFlowsNewestVariation,
            reassignedFlows,
        } = this.state;

        let {
            sectionInfo: { sections },
        } = this.state;
        if (!sections || sections.length === 0) {
            return <h3>No results found.</h3>;
        }

        if (this.state.flowStatuses) {
            sections = sections.map((section) => {
                const flows = section.flows.map((flow) => {
                    const status = this.state.flowStatuses.find(
                        (statusData) => statusData.id === flow.id,
                    );
                    return {
                        ...flow,
                        ...status,
                    };
                }, this);
                return {
                    ...section,
                    flows,
                };
            }, this);
        }

        const search = this.props.search;
        const isInsideTask = !!this.state.taskId;

        const missingFlowIDs = missingFlowsNewestVariation
            ?.map((flow) => flow[0])
            .sort((acc, cur) => acc - cur);

        let hasNewestVariationSelected = hasNewestVariation;
        let newestVariationSelected = newestVariation;
        let missingFlowsNewestVariationSelected = missingFlowsNewestVariation;

        if (missingFlowIDs && missingFlowsNewestVariationSelected) {
            for (let i = 0; i < missingFlowIDs.length; i++) {
                if (!reassignedFlows.includes(missingFlowIDs[i])) {
                    missingFlowsNewestVariationSelected =
                        missingFlowsNewestVariationSelected.filter(
                            (flow) => flow[0] !== missingFlowIDs[i],
                        );
                }
            }
            if (missingFlowIDs.toString() === reassignedFlows?.toString()) {
                hasNewestVariationSelected = false;
                newestVariationSelected = currentVariation;
            }
        }

        reassignedFlows.sort((acc, cur) => acc - cur);

        return (
            <div className="SectionsList">
                {isInsideTask && (
                    <div className="flows-screenshots-counter">
                        <div className="counter">
                            <strong>flow{flowsCount && flowsCount > 1 && "s"}</strong>
                            <span>{flowsCount}</span>
                            <strong>screen{(screensCount > 1 || "n/a") && "s"}</strong>
                            <span>{screensCount}</span>
                        </div>
                    </div>
                )}

                <FlowWarning isFinished={isFinished || isInsideTask} />

                <CardColumns>
                    {sections.map((section) => (
                        <Section
                            showReassigning={this.state.isReassignMode}
                            key={section.id}
                            isChooseFlowsModalOpen={this.props.isChooseFlowsModalOpen}
                            section={section}
                            search={`?${removeAdditionalParametersFromSearch(search)}${
                                this.props.tcBuildId ? `&tcBuildId=${this.props.tcBuildId}` : ""
                            }`}
                            reassignedFlowsStatus={this.state.reassignedFlows}
                            handleCheckbox={
                                this.props.isChooseFlowsModalOpen
                                    ? (input: {
                                          id: { match: (arg0: RegExp) => string[] };
                                          checked: boolean;
                                      }) => this.selectFlowsForNewTask(input, this.props.translator)
                                    : this.changeFailedFlowState
                            }
                            translatorSelectedFlows={this.props.translatorSelectedFlows}
                            translatorId={this.props.translatorId}
                            taskId={taskId}
                            taskFlowsToShowList={taskFlowsToShowList}
                        />
                    ))}
                </CardColumns>

                {this.state.isReassignMode ? (
                    <div className="translator-reassign">
                        <h2>
                            <strong>Choose translator for reassigning the task</strong>
                        </h2>
                        <form className="translator-reassign-dropdown">
                            <DropdownWithFilter
                                title="Choose translator"
                                items={this.state.translatorsList.map((key) => ({
                                    value: key,
                                    display: key,
                                }))}
                                selected={this.state.selected || ""}
                                onSelect={(selected: any) => {
                                    this.setState({ selected });
                                }}
                                variant="outline-info"
                            />
                            {hasNewestVariationSelected &&
                            newestVariationSelected &&
                            currentVariation ? (
                                <ModalWindow
                                    modalButtonName="Create a new task"
                                    isModalButtonDisabled={!this.state.selected}
                                    modalTitle="You're currently reassigning a task!"
                                    modalBody={
                                        <>
                                            <p className="info-text-updated-version">
                                                <FontAwesomeIcon size="lg" icon={faInfoCircle} />
                                                &nbsp;There is an <strong>updated</strong> version
                                                of the app.
                                            </p>
                                            <SectionsListTaskReassignModal
                                                newestVariation={newestVariationSelected}
                                                currentVariation={currentVariation}
                                            />

                                            {missingFlowsNewestVariationSelected &&
                                            missingFlowsNewestVariationSelected?.length ? (
                                                <>
                                                    <Accordion>
                                                        <Card>
                                                            <Card.Header>
                                                                <Accordion.Toggle
                                                                    as={Button}
                                                                    variant="link"
                                                                    eventKey="0">
                                                                    <FontAwesomeIcon
                                                                        size="lg"
                                                                        icon={faExclamationCircle}
                                                                    />
                                                                    &nbsp;Click to see the flow
                                                                    {missingFlowsNewestVariationSelected.length >
                                                                    1
                                                                        ? "s "
                                                                        : " "}
                                                                    missing from the
                                                                    <strong> updated </strong>
                                                                    version!
                                                                </Accordion.Toggle>
                                                            </Card.Header>
                                                            <Accordion.Collapse eventKey="0">
                                                                <Card.Body>
                                                                    <Table
                                                                        className="missing-flows"
                                                                        size="sm">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>ID</th>
                                                                                <th>Flow name</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {missingFlowsNewestVariationSelected.map(
                                                                                (
                                                                                    flow: (
                                                                                        | boolean
                                                                                        | React.ReactChild
                                                                                        | React.ReactFragment
                                                                                        | React.ReactPortal
                                                                                        | null
                                                                                        | undefined
                                                                                    )[],
                                                                                ) => {
                                                                                    return (
                                                                                        <tr
                                                                                            key={`missing-flow-${flow[0]}`}>
                                                                                            <td>
                                                                                                <strong>
                                                                                                    {
                                                                                                        flow[0]
                                                                                                    }
                                                                                                </strong>
                                                                                            </td>
                                                                                            <td>
                                                                                                {
                                                                                                    flow[1]
                                                                                                }
                                                                                            </td>
                                                                                        </tr>
                                                                                    );
                                                                                },
                                                                            )}
                                                                        </tbody>
                                                                    </Table>
                                                                </Card.Body>
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </>
                                            ) : null}
                                        </>
                                    }
                                    variant="info"
                                    buttonSize="lg"
                                    modalCustomClass="reassign">
                                    <p>
                                        <strong>
                                            What version would you like to use for a new reassigned
                                            task?
                                        </strong>
                                    </p>
                                    <Button
                                        variant="info"
                                        value={currentVariation.id}
                                        onClick={this.handleTaskReassign}>
                                        Keep current version
                                    </Button>
                                    <Button
                                        variant="success"
                                        value={newestVariationSelected.id}
                                        onClick={this.handleTaskReassign}>
                                        Use updated version
                                    </Button>
                                </ModalWindow>
                            ) : (
                                <>
                                    <p className="absence-of-updated-version">
                                        <FontAwesomeIcon size="lg" icon={faExclamationCircle} /> No
                                        updated version available, task will be created with current
                                        version
                                    </p>
                                    <Button
                                        block={true}
                                        size="lg"
                                        variant="info"
                                        disabled={!this.state.selected}
                                        value={currentVariation?.id}
                                        onClick={this.handleTaskReassign}>
                                        Create a new task
                                    </Button>
                                </>
                            )}
                        </form>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default withRouter(SectionsList);
