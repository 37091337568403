import React, { Component } from "react";
import ReactLoading from "react-loading";
import ReactModal from "react-modal";
import { LinkContainer } from "react-router-bootstrap";

import appState from "appState";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { getSections, getSectionsByTCId } from "models/getSections";
import { getFlow } from "models/getFlow";
import { getScreenshotCompare } from "models/getScreenshotCompare";
import { getScreenshotsVerifications, getVerification } from "models/getVerifications";

import parseSearchString from "utils/parseSearchString";
import encodeFlowParams from "utils/encodeFlowParams";
import encodeIntoQueryParams from "utils/encodeIntoQueryParams";
import formatDate from "utils/formatDate";
import makeTextLinksClickable from "utils/makeTextLinksClickable";
import checkObjectsShallowEquality from "utils/checkObjectsShallowEquality";

import FlowPageFlowDescription from "./FlowPageFlowDescription";
import BreadcrumbsNavigation from "components/BreadcrumbsNavigation";
import ErrorMessage from "components/ErrorMessage";
import FlowSelector from "components/FlowSelector";
import getScreenshotsList from "components/getScreenshotsList";
import ScreenshotComparison from "components/ScreenshotComparison";
import ScrollToTop from "components/ScrollToTop";
import ModePanel from "components/ModePanel";
import { Button, Col, Container, Row } from "react-bootstrap";

import "./FlowPage.scss";

ReactModal.setAppElement("#root");

class FlowPage extends Component {
    constructor() {
        super();
        this.downloadFlow = this.downloadFlow.bind(this);
        this.changeMode = this.changeMode.bind(this);
        this.onClickScreenshot = this.onClickScreenshot.bind(this);
        this.navigateToPreviousScreenshot = this.navigateToPreviousScreenshot.bind(this);
        this.navigateToNextScreenshot = this.navigateToNextScreenshot.bind(this);
        this.handleKey = this.handleKey.bind(this);
        this.setScreenshotsVerificationData = this.setScreenshotsVerificationData.bind(this);
        this.setFlowPageStateFlowStatus = this.setFlowPageStateFlowStatus.bind(this);
        this.setIsTaskFinishedStateStatus = this.setIsTaskFinishedStateStatus.bind(this);

        this.state = {
            loading: true,
            fullScreenModalComponent: {},
            screenshotsVerificationData: {},
            taskId: null,
            currentFlowVerification: {},
            isFlowVerificationLoading: true,
            isScreenshotsVerificationLoading: true,
            isTaskFinished: false,
            showLexemes: false,
            tcBuildId: null,
            hasLexemes: false,
        };
    }

    handleKey(evt) {
        if (this.isFullScreen()) {
            if (evt.key === "ArrowLeft" && !this.isFirstScreenshot()) {
                this.navigateToPreviousScreenshot();
            } else if (evt.key === "ArrowRight" && !this.isLastScreenshot()) {
                this.navigateToNextScreenshot();
            }
        }
    }

    setFlowPageStateFlowStatus(status: string) {
        this.setState((prevState) => ({
            ...prevState,
            currentFlowVerification: {
                ...prevState.currentFlowVerification,
                status,
            },
        }));
    }

    setIsTaskFinishedStateStatus(status: string) {
        this.setState({ isTaskFinished: status === "finished" });
    }

    updateScreenshotVerifications(taskId: number) {
        const taskFlowData = {
            flow_id: Number(this.props.match.params.flowId),
            task_id: taskId,
            is_parent_task: false,
        };
        getScreenshotsVerifications(taskFlowData)
            .then(
                (verifiedScreenshots: {
                    verifications: {
                        screenshot_id: number;
                        comment: string;
                        status: string;
                        flow_id: number;
                        screenshot_name: string;
                    }[];
                    parent_task_id: number;
                }) => {
                    const verifiedScreenshotsData: {
                        [key: string]: {
                            status: string;
                            comment: string;
                            name: string;
                        };
                    } = {};
                    verifiedScreenshots.verifications.forEach((screen) => {
                        const { screenshot_id, comment, status, screenshot_name } = screen;

                        verifiedScreenshotsData[screenshot_id] = {
                            comment,
                            status,
                            name: screenshot_name,
                        };
                    });

                    if (!!verifiedScreenshots.parent_task_id) {
                        // we need it for keeping comments for reassigned screenshots
                        taskFlowData.task_id = verifiedScreenshots.parent_task_id;
                        taskFlowData.is_parent_task = true;

                        getScreenshotsVerifications(taskFlowData).then(
                            (verifiedParentScreenshots: {
                                verifications: {
                                    screenshot_id: number;
                                    comment: string;
                                    status: string;
                                    flow_id: number;
                                    screenshot_name: string;
                                }[];
                                parent_task_id: number | null;
                            }) => {
                                const selectRejectedByName =
                                    (name: string) =>
                                    (el: {
                                        screenshot_name: string;
                                        status: string;
                                        comment: string | null;
                                        flow_id: number | null;
                                    }) =>
                                        el.screenshot_name === name &&
                                        el.status === "rejected" &&
                                        el.comment &&
                                        el.flow_id === Number(this.props.match.params.flowId);

                                this.state.flow?.screenshots?.forEach(
                                    (screen: { id: number; name: string }) => {
                                        const verData =
                                            this.state.screenshotsVerificationData[screen.id];

                                        // flows with different IDs can have screenshots that are named the same but have different screenshot IDs
                                        if (!verData) {
                                            const checkedReassignScreenshots =
                                                verifiedParentScreenshots.verifications?.filter(
                                                    selectRejectedByName(screen.name),
                                                );

                                            let reassignedScreenshotComment = "";

                                            if (checkedReassignScreenshots.length === 1) {
                                                const checkedReassignScreenshot =
                                                    verifiedParentScreenshots.verifications?.filter(
                                                        selectRejectedByName(screen.name),
                                                    )[0];

                                                reassignedScreenshotComment =
                                                    checkedReassignScreenshot.comment;
                                            } else if (checkedReassignScreenshots.length > 1) {
                                                reassignedScreenshotComment =
                                                    checkedReassignScreenshots.reduce(
                                                        (acc, curr, index) => {
                                                            if (curr && curr.comment) {
                                                                if (index === 0) {
                                                                    return `${acc}${curr.comment}`;
                                                                } else {
                                                                    return `${acc}\n\n${curr.comment}`;
                                                                }
                                                            }
                                                            return acc;
                                                        },
                                                        "",
                                                    );
                                            }

                                            /*
                                            We could have the same name for several screenshots inside one flow.
                                            Because the ID of a screenshot could also be different next time the task is reassigned
                                            there is no way that we can use a unique ID as a reliable source of receiving comments,
                                            that's why we use screenshot_name.
                                            In a situation where we have several rejected screenshots with the same name inside the same flow, we assume that
                                            all screenshots were evaluated the same and we use data from the first found screenshot
                                            with the name and with existing comment and "rejected" status
                                            for all screenshots with this particular name on the page on a page. */
                                            if (
                                                checkedReassignScreenshots.length &&
                                                reassignedScreenshotComment
                                            ) {
                                                verifiedScreenshotsData[screen.id] = {
                                                    comment: reassignedScreenshotComment,
                                                    status: "",
                                                    name: screen.name,
                                                };
                                            }
                                        }
                                    },
                                );

                                this.setState({
                                    isScreenshotsVerificationLoading: false,
                                });
                            },
                        );
                    } else {
                        this.setState({
                            isScreenshotsVerificationLoading: false,
                        });
                    }
                    this.setScreenshotsVerificationData(verifiedScreenshotsData);
                },
            )
            .catch((error: { error: string }) => {
                this.setState({
                    error,
                });
            });
    }

    updateFlowVerification(flowId: number) {
        getVerification(flowId)
            .then(
                (res: {
                    verifications: {
                        comments: string | null;
                        flow_id: number | null;
                        status: string | null;
                    };
                    task_status: string;
                }) => {
                    this.setState({
                        isFlowVerificationLoading: false,
                        currentFlowVerification: res.verifications,
                        isTaskFinished: res.task_status === "finished",
                    });
                },
            )
            .catch((error: { error: string }) => {
                this.setState({
                    error,
                });
            });
    }

    componentDidMount() {
        const params = parseSearchString();
        const taskId = params.get("taskId");
        const tcBuildId = params.get("tcBuildId");

        if (taskId) this.setState({ taskId });
        if (tcBuildId) this.setState({ tcBuildId });

        this.fetchFlow(this.props);

        document.addEventListener("keydown", this.handleKey);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKey);
    }

    componentDidUpdate(prevProps) {
        if (!checkObjectsShallowEquality(this.props, prevProps)) {
            this.fetchFlow(this.props);
        }
    }

    onClickScreenshot(index) {
        this.setState((prevState) => {
            const selectedScreenshot = prevState.flow.screenshots[index];
            return {
                fullScreenModalComponent: {
                    index,
                    src: selectedScreenshot.file,
                },
            };
        });
    }

    navigateToPreviousScreenshot() {
        this.onClickScreenshot(this.state.fullScreenModalComponent.index - 1);
    }

    navigateToNextScreenshot() {
        this.onClickScreenshot(this.state.fullScreenModalComponent.index + 1);
    }

    isFullScreen() {
        return !!this.state.fullScreenModalComponent.src;
    }

    isFirstScreenshot() {
        return this.isFullScreen() && this.state.fullScreenModalComponent.index === 0;
    }

    isLastScreenshot() {
        return (
            this.isFullScreen() &&
            this.state.fullScreenModalComponent.index === this.state.flow.screenshots.length - 1
        );
    }

    setScreenshotsVerificationData(screenshotsData: {
        [key: string]: { [key: string]: { status: string; comment: string; name: string } };
    }) {
        this.setState({ screenshotsVerificationData: screenshotsData });
    }

    fetchFlow(props) {
        const { flowId, screenshotId, comparedField } = props.match.params;
        const { showLexemes } = this.state;

        const params = parseSearchString();
        const taskId = params.get("taskId");
        const tcBuildId = params.get("tcBuildId");

        const selectedFilters = appState.get("flow");

        const filtersWithLexemish = { ...selectedFilters, locale: "lx-lx" };
        const promises = [
            getFlow(flowId, selectedFilters, true, tcBuildId, taskId),
            getFlow(flowId, filtersWithLexemish, false),
            tcBuildId
                ? getSectionsByTCId(tcBuildId, selectedFilters)
                : getSections(selectedFilters, Number(taskId), false),
        ];

        if (screenshotId && comparedField) {
            const screenshotFields = { ...selectedFilters };

            // we don't want to filter by the field we want to compare against
            if (comparedField === "resolution") {
                // resolution is device based on server
                delete screenshotFields.device;
            } else {
                delete screenshotFields[comparedField];
            }

            promises.push(getScreenshotCompare(screenshotId, screenshotFields));
        }

        Promise.all(promises)
            .then(([flow, lexemishFlow, sectionInfo, screenshots]) => {
                this.setState({
                    flow,
                    sectionInfo,
                    screenshots,
                    comparedField,
                    loading: false,
                    hasLexemes: lexemishFlow.screenshots.length > 0,
                    showLexemes: showLexemes && selectedFilters.locale === "lx-lx",
                });

                if (taskId) {
                    this.updateScreenshotVerifications(Number(taskId));
                    this.updateFlowVerification(flow.id);
                }
            })
            .catch((error) => {
                this.setState({
                    error,
                    loading: false,
                });
            });
    }

    downloadFlow() {
        window.gtag("event", "click", {
            event_label: "DownloadFlow",
        });

        const flow = appState.get("flow");
        const flowParams = encodeFlowParams(flow);
        window.open(
            `${process.env.LIVESHOTS_APP_URL}/api/run/${
                this.state.flow.run_id
            }/zip?${encodeIntoQueryParams(flowParams)}`,
            "Download",
        );
    }

    changeMode(props) {
        const { showLexemes } = props;
        const state = {
            showLexemes,
        };

        this.setState(state);

        const currentFilter = appState.get("flow");

        if (showLexemes && currentFilter.locale !== "lx-lx") {
            appState.set({
                flow: {
                    ...currentFilter,
                    locale: "lx-lx",
                },
            });
        }
    }

    render() {
        if (this.state.loading) {
            return (
                <ReactLoading
                    type="bubbles"
                    color="#888"
                    className="AbsoluteCentered"
                    delay={300}
                />
            );
        }
        if (this.state.error) {
            return <ErrorMessage error={this.state.error} />;
        }
        const {
            screenshots,
            flow: currentFlow,
            flow: { task_flow_list },
            sectionInfo: { sections },
            showLexemes,
            hasLexemes,
            screenshotsVerificationData,
            taskId,
            currentFlowVerification,
            isTaskFinished,
            tcBuildId,
        } = this.state;

        const { flowId } = this.props.match.params;

        const search = this.props.location.search;
        const selectedFilters = appState.get("flow");

        const crumbs = getCrumbsFromSections(sections, currentFlow, search, task_flow_list);

        const hasScreenshots = currentFlow.screenshots.length > 0;

        const screenshotList = getScreenshotsList({
            screenshots: currentFlow.screenshots,
            suggestions: currentFlow.suggestions,
            baseUrl: `/flow/${flowId}`,
            currentFilter: selectedFilters,
            searchQuery: this.props.location.search,
            showLexemes,
            encodeFilterRoute(filter) {
                return `/flow/${flowId}?${appState.encodeState({
                    flow: filter,
                })}`;
            },
            onClickScreenshot: this.onClickScreenshot,
            flowId,
            taskId,
            setScreenshotsVerificationData: this.setScreenshotsVerificationData,
            screenshotsVerificationData,
            setFlowPageStateFlowStatus: this.setFlowPageStateFlowStatus,
            setIsTaskFinishedStateStatus: this.setIsTaskFinishedStateStatus,
            isTaskFinished,
            isScreenshotsVerificationLoading: this.state.isScreenshotsVerificationLoading,
        });

        let flowLastUpdateDate = "Unknown";
        if (currentFlow.screenshots.length > 0) {
            const dates = screenshotList.map((element) => {
                return element.props.flowItem.created;
            });
            // takes max creation date from screenshot
            flowLastUpdateDate = formatDate(Math.max(...dates), "d mmm yyyy");
        }

        const flowDescriptionWithLinks = makeTextLinksClickable(currentFlow.description);
        const taskParameters = appState.get("flow");

        return (
            <Container className="FlowPage">
                <Row>
                    {taskId ? null : (
                        <Col xs={12} md={2} className="StickyFlow">
                            <FlowSelector
                                flowKey="flow"
                                allowChoosingPods={true}
                                tcBuildId={tcBuildId}
                            />
                            <LinkContainer
                                to={`/flow-comparison/${flowId}/${flowId}${search}`}
                                onClick={trackFlowComparison}>
                                <Button
                                    className="flow-actions"
                                    as="button"
                                    variant="dark"
                                    block={true}>
                                    Compare flows
                                </Button>
                            </LinkContainer>

                            <Button
                                className="flow-actions"
                                variant="dark"
                                block={true}
                                onClick={this.downloadFlow}>
                                Download Flow
                            </Button>
                            <ModePanel
                                hasLexemes={hasLexemes}
                                showLexemes={showLexemes}
                                onChange={this.changeMode}
                            />
                        </Col>
                    )}
                    <Col xs={11} md={taskId ? 12 : 10}>
                        {hasScreenshots || (crumbs?.length && tcBuildId) ? (
                            <BreadcrumbsNavigation
                                crumbs={crumbs}
                                isFullScreen={this.isFullScreen()}
                                taskId={taskId}
                                currentFlowVerification={currentFlowVerification}
                                isFlowVerificationLoading={this.state.isFlowVerificationLoading}
                            />
                        ) : null}

                        {hasScreenshots && currentFlow.description ? (
                            <FlowPageFlowDescription
                                flowDescriptionWithLinks={flowDescriptionWithLinks}
                                flowId={flowId}
                                flowComment={currentFlow.flow_comment}
                                rerenderFlow={this.setIsCommentUpdated}
                                flowLastUpdateDate={flowLastUpdateDate}
                                flowDateAdded={currentFlow.date_added}
                                flowLocation={currentFlow.flow_location}
                                taskParameters={taskId ? taskParameters : null}
                            />
                        ) : null}

                        <Row className="ScreenshotList">{screenshotList}</Row>
                    </Col>
                </Row>
                {this.isFullScreen() ? (
                    <ReactModal
                        contentLabel="Modal"
                        className="FullScreenshot"
                        overlayClassName="FullScreenshotOverlay"
                        isOpen={true}>
                        <div
                            className="ScreenshotContent"
                            style={{
                                backgroundImage: `url('${this.state.fullScreenModalComponent.src}')`,
                            }}
                            onClick={() => this.setState({ fullScreenModalComponent: {} })}
                        />
                        <Button
                            variant="dark"
                            className="fullscreen-button"
                            onClick={this.navigateToPreviousScreenshot}
                            disabled={this.isFirstScreenshot()}>
                            <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                        </Button>
                        <Button
                            variant="dark"
                            className="fullscreen-button float-right"
                            onClick={this.navigateToNextScreenshot}
                            disabled={this.isLastScreenshot()}>
                            <FontAwesomeIcon size="lg" icon={faArrowRight} />
                        </Button>
                    </ReactModal>
                ) : null}
                <ScreenshotComparison
                    selectedFilters={selectedFilters}
                    screenshots={screenshots}
                    comparedField={this.state.comparedField}
                    backUrl={`/flow/${flowId}${search}`}
                    history={this.props.history}
                />
                <ScrollToTop />
            </Container>
        );
    }
}

export default FlowPage;

function getCrumbsFromSections(sections, currentFlow, search, task_flow_list) {
    const taskFlowsToShowList = task_flow_list;
    return filteredSections(sections, taskFlowsToShowList).map(({ name, id, flows }) => ({
        name,
        id,
        isCurrent: name === currentFlow.section,
        url: `/flow/${flows[0].id}${search}`,

        crumbs: filteredFlows(flows, taskFlowsToShowList).map(
            ({ name: childName, id: childId }) => ({
                name: childName,
                id: childId,
                isCurrent: childName === currentFlow.name,
                url: `/flow/${childId}${search}`,
            }),
        ),
        key: `${name}-${id}`,
    }));
}

function trackFlowComparison() {
    window.gtag("event", "click", {
        event_label: "CompareFlows",
    });
}

function filteredSections(sections, filter) {
    return filter
        ? sections.filter((section) =>
              section.flows.map((flow) => flow.id).some((flow) => filter.includes(flow)),
          )
        : sections;
}

function filteredFlows(flows, filter) {
    return filter ? flows.filter((flow) => filter.includes(flow.id)) : flows;
}
