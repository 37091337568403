export function cache(fn: Function) {
    const store = {};

    return function (...args) {
        const key = JSON.stringify(args);

        if (!store[key]) {
            store[key] = fn.apply(this, args);
        }

        return store[key];
    };
}
