import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import ReactLoading from "react-loading";

import { post } from "../../api";

import { getAllTasks } from "models/getTasks";

import prettyBrand from "utils/prettyBrand";
import prettyPlatform from "utils/prettyPlatform";
import prettyLocale from "utils/prettyLocale";
import prettyString from "utils/prettyString";

import { ITasksTablePropsTypes, ITaskRowItemTypes, ITaskToDeleteTypes } from "./interfaces";

import TasksTableActionButton from "./TasksTableActionButton";
import { Badge } from "react-bootstrap";

import "./TasksTable.scss";

const TasksTable: React.FunctionComponent<ITasksTablePropsTypes> = ({
    hasNewTasks,
    setHasNewTasks,
    setPageError,
}) => {
    const [tasks, setTasks] = useState<ITaskRowItemTypes[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    // This component is visible for translator managers and admins only, so we get All the non-deleted tasks.

    const history = useHistory();
    const fetchTasks = useCallback(() => {
        getAllTasks()
            .then((allTasks: ITaskRowItemTypes[]) => {
                setTasks(allTasks);
                setIsLoading(false);
            })
            .catch((error: { status: number; error: string }) => {
                setIsLoading(false);
                // eslint-disable-next-line no-console
                console.error(error);
                setPageError(error);
            });
    }, [setPageError]);

    useEffect(() => {
        fetchTasks();
    }, [fetchTasks]);

    useEffect(() => {
        if (hasNewTasks) {
            fetchTasks();
            setHasNewTasks(false);
        }
    }, [fetchTasks, hasNewTasks, setHasNewTasks]);

    const deleteTask = useCallback(
        (taskToDelete: ITaskToDeleteTypes) => {
            const { id, assignee_name, product, platform, locale } = taskToDelete;
            const confirm = window.confirm(
                `Are you sure you want to delete a task for ${assignee_name} to verify ${prettyBrand(
                    product,
                )} ${prettyPlatform(platform)} in ${prettyLocale(locale)}?`,
            );
            if (confirm === true) {
                post(`tasks/delete/${id}`, {}).then(() => {
                    fetchTasks();
                });
            }
        },
        [fetchTasks],
    );

    const handleTaskOpen = (taskId: number) => () => {
        history.push(`/task/${taskId}`);
    };

    const handleContextMenu = (taskId: number) => (evt: { preventDefault: () => void }) => {
        evt.preventDefault(); // Prevent default right-click behavior
        window.open(`/task/${taskId}`, "_blank"); // Open link in new tab
    };

    const taskRow = (taskRowItem: ITaskRowItemTypes) => {
        const { id, product, platform, locale, assignee_name, status, date_created } = taskRowItem;

        // there are 3 statuses: assigned, in_progress, finished
        let labelVariant = "info"; // default and status "assigned" included
        if (status === "in_progress") labelVariant = "warning";
        else if (status === "finished") labelVariant = "success";

        const prettyStringStatus = prettyString(status);
        const dateCreated = new Date(date_created);

        const dateString = `${dateCreated.getDate()}/${
            dateCreated.getMonth() + 1
        }/${dateCreated.getFullYear()}`;
        return (
            <tr
                key={`task-${id}`}
                className="tr-body"
                onClick={handleTaskOpen(id)}
                onContextMenu={handleContextMenu(id)}>
                <td>{id}</td>
                <td>{dateString}</td>
                <td>
                    <Badge aria-label={product} className={`task-product task-product--${product}`}>
                        {prettyBrand(product)}
                    </Badge>
                </td>
                <td>{prettyPlatform(platform)}</td>
                <td>{prettyLocale(locale)}</td>
                <td>{assignee_name}</td>
                <td className="td-icon">
                    {
                        <Badge aria-label={prettyStringStatus} variant={labelVariant}>
                            {prettyStringStatus}
                        </Badge>
                    }
                </td>
                <td className="td-icon">
                    <TasksTableActionButton task={taskRowItem} onDelete={deleteTask} />
                </td>
            </tr>
        );
    };
    const header = (
        <tr key="header" className="tr-header">
            <th>ID</th>
            <th>Created</th>
            <th>Product</th>
            <th>Platform</th>
            <th>Locale</th>
            <th>Assignee</th>
            <th className="th-icon">Status</th>
            <th className="th-icon">Action</th>
        </tr>
    );

    const tasksTable = tasks.map((task) => taskRow(task));

    return isLoading ? (
        <div className="loader-container">
            <ReactLoading type="bubbles" color="#888" />
        </div>
    ) : (
        <table className="TasksTable task-table">
            <tbody>
                {header}
                {tasksTable}
            </tbody>
        </table>
    );
};

export default TasksTable;
