import React, { useEffect, useState } from "react";

import { Redirect } from "react-router-dom";

import appState from "appState";

import { getLastTask } from "models/getTasks";
import { ITaskPagePropsTypes, ITaskToShow } from "./interfaces";

const TaskPage: React.FunctionComponent<ITaskPagePropsTypes> = ({ match, location }) => {
    const [isReassign] = useState(!!location.state?.isReassignMode);

    const [redirectToFlows, setRedirectToFlows] = useState(false);

    const taskId = match.params.taskId;

    useEffect(() => {
        getLastTask(taskId)
            .then((task: ITaskToShow) => {
                const { platform, product, version, locale, device, os, branch } = task;
                appState.set(
                    {
                        flow: {
                            platform,
                            product,
                            version,
                            locale,
                            device,
                            os,
                            branch,
                        },
                        taskId,
                    },
                    true,
                    "",
                );
                setRedirectToFlows(true);
            })
            .catch((err: { error: string }) => {
                alert(err.error);
                setRedirectToFlows(false);
                window.history.back();
            });
    }, [isReassign, taskId]);

    return redirectToFlows ? (
        <Redirect
            to={{
                pathname: `/flows`,
                search: `${location?.search}&isReassignMode=${isReassign}`,
            }}
        />
    ) : null;
};

export default TaskPage;
