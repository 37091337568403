import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import Spacer from "components/Spacer";
import { Card } from "react-bootstrap";

export default function FlowWarning({ isFinished }: { isFinished: boolean }) {
    return isFinished ? null : (
        <>
            <Card body={true} bg="warning">
                <FontAwesomeIcon size="lg" icon={faExclamationCircle} /> Please note that the
                current version has fewer flows than the previous one. We might still be collecting
                data for this version.
            </Card>
            <Spacer bottom="xlg" />
        </>
    );
}
