import encodeIntoQueryParams from "utils/encodeIntoQueryParams";
import Auth from "utils/services/Auth";

export function get(request, params, triesLeft = 3) {
    const queryParams = `?${encodeIntoQueryParams(params)}`;
    const url = process.env.LIVESHOTS_APP_URL || "";
    const apiKey = process.env.API_KEY || "";
    const headers = {};

    if (apiKey !== "") {
        headers["X-Api-Key"] = apiKey;
    }

    return fetch(`${url}/api/${request}${queryParams || ""}`, {
        credentials: "include",
        headers,
    })
        .then((body) => body.json())
        .then((response) => {
            if (response.error) {
                triesLeft = 0;
                if (response.status === 401) {
                    Auth.logout(function () {
                        window.location.reload();
                    });
                }
                throw response;
            }

            return response;
        })
        .catch((err) => {
            if (triesLeft > 0) {
                return get(request, params, triesLeft - 1);
            } else {
                throw err;
            }
        });
}

export function post(request, params) {
    const url = process.env.LIVESHOTS_APP_URL;
    const apiKey = process.env.API_KEY || "";
    const headers = {};

    if (apiKey !== "") {
        headers["X-Api-Key"] = apiKey;
    }

    return fetch(`${url}/api/${request}`, {
        method: "POST",
        credentials: "include",
        body: JSON.stringify(params),
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    })
        .then((body) => body.json())
        .then((response) => {
            if (response.error) {
                if (response.status === 401) {
                    Auth.logout(function () {
                        window.location.reload();
                    });
                }
                throw response;
            }
            return response;
        });
}

export function del(request) {
    const url = process.env.LIVESHOTS_APP_URL;
    const apiKey = process.env.API_KEY || "";
    const headers = {};

    if (apiKey !== "") {
        headers["X-Api-Key"] = apiKey;
    }

    return fetch(`${url}/api/${request}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            ...headers,
        },
    })
        .then((body) => body.json())
        .then((response) => {
            if (response.error) {
                if (response.status === 401) {
                    Auth.logout(function () {
                        window.location.reload();
                    });
                }
                throw response;
            }
            return response;
        })
        .catch((error) => {
            console.error(error);
            alert(error.message);
        });
}
