import React, { useCallback } from "react";

import { IFlowExplorerDropdownItemPropsTypes } from "./interfaces";

import { Highlighter } from "react-bootstrap-typeahead";
import { Dropdown } from "react-bootstrap";

import "./FlowExplorerDropdownItem.scss";

export const FlowExplorerDropdownItem: React.FunctionComponent<
    IFlowExplorerDropdownItemPropsTypes
> = ({ searchText, name, link, navigate }) => {
    const handleClick = useCallback(() => navigate(link), [link, navigate]);

    return (
        <Dropdown.Item onClick={handleClick}>
            <Highlighter search={searchText}>{name}</Highlighter>
        </Dropdown.Item>
    );
};

export default FlowExplorerDropdownItem;
