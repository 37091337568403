import React from "react";

import appState from "appState";

import { Col } from "react-bootstrap";

import calcFlowImagePadding from "utils/calcFlowImagePadding";
import { IScreenshotWithoutPane } from "./interfaces";

import Screenshot from "components/Screenshot";

const ScreenshotWithoutPane = ({ colWidth, ...props }: IScreenshotWithoutPane) => {
    const { width, height, id } = props;
    const imageHeightPadding = calcFlowImagePadding(width, height);

    let maxImageHeightPadding = appState.get("maxImagePadding");
    if (!maxImageHeightPadding || imageHeightPadding > maxImageHeightPadding) {
        appState.set({ maxImagePadding: imageHeightPadding });
        maxImageHeightPadding = imageHeightPadding;
    }

    const areScreenshotsHaveDifferentRatio = appState.get("areScreenshotsHaveDifferentRatio");
    const imagesHightDifference = maxImageHeightPadding - imageHeightPadding;

    return (
        <Col
            className={"ScreenshotWithoutPane"}
            xs={12}
            md={colWidth}
            key={id}
            style={
                areScreenshotsHaveDifferentRatio
                    ? {
                          paddingBottom: `${imagesHightDifference}%`,
                      }
                    : {}
            }>
            <Screenshot {...props} />
        </Col>
    );
};

export default ScreenshotWithoutPane;
