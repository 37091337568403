import React from "react";
import ReactLoading from "react-loading";

import {
    faCheck,
    faEllipsisH,
    faExclamation,
    faMinus,
    faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { flattenCurrentCrumbs } from "./helpers";

import BreadcrumbDropdown from "components/BreadcrumbDropdown";
import BreadcrumbNavigationButtons from "./BreadcrumbNavigationButtons";

import { IBreadcrumbNavigationBreadcrumbPropsTypes } from "./interfaces";

import { Breadcrumb } from "react-bootstrap";

export const BreadcrumbNavigationBreadcrumb: React.FunctionComponent<
    IBreadcrumbNavigationBreadcrumbPropsTypes
> = ({
    isComparison,
    isFirst,
    isLast,
    search,
    showSearch,
    navigateToPreviousFlow,
    navigateToNextFlow,
    isTask,
    crumbs,
    flow,
    currentFlowVerification,
    isFlowVerificationLoading,
}) => {
    const currentCrumb = crumbs.find((crumb: { isCurrent: boolean }) => crumb.isCurrent);
    const isCurrentCrumb = !!currentCrumb;

    const homeCrumb = (
        <Breadcrumb.Item key="home" href={`/flows${isTask ? "" : search}`}>
            Home
        </Breadcrumb.Item>
    );

    let breadcrumb = (
        <Breadcrumb>
            <Breadcrumb.Item
                key={`breadcrumb-default-${flow ? flow?.section : "section"}`}
                active={true}>
                {flow?.section}
            </Breadcrumb.Item>
            <Breadcrumb.Item key={`breadcrumb-default-${flow ? flow?.name : "flow"}`} active={true}>
                {flow?.name}
            </Breadcrumb.Item>
        </Breadcrumb>
    );

    const flattenCrumbs = flattenCurrentCrumbs(crumbs);

    let flowStatusIcon = <FontAwesomeIcon size="lg" fontVariant="info" icon={faMinus} />;
    // eslint-disable-next-line default-case
    switch (currentFlowVerification?.status) {
        case "approved":
            flowStatusIcon = <FontAwesomeIcon size="lg" icon={faCheck} />;
            break;

        case "failed":
            flowStatusIcon = <FontAwesomeIcon size="lg" icon={faTimes} />;
            break;
        case "reassigned":
            flowStatusIcon = <FontAwesomeIcon size="lg" icon={faExclamation} />;
            break;
        case "in_progress":
        case "reassigned_in_progress":
            flowStatusIcon = <FontAwesomeIcon size="lg" icon={faEllipsisH} />;
            break;
    }

    let flowStatus = null;

    if (isTask && !isFlowVerificationLoading) {
        flowStatus = (
            <div className={`breadcrumbs-flow-status ${currentFlowVerification?.status || ""}`}>
                {flowStatusIcon}
            </div>
        );
    }
    if (isTask && isFlowVerificationLoading) {
        flowStatus = (
            <div className="loader-container">
                <ReactLoading type="bubbles" color="#888" />
            </div>
        );
    }

    if (isCurrentCrumb) {
        breadcrumb = (
            <>
                <Breadcrumb>
                    {!isComparison ? homeCrumb : null}
                    {flattenCrumbs?.length
                        ? flattenCurrentCrumbs(crumbs).map((crumbsList, idx) => {
                              /* Breadcrumb.Item component has issue wit inserting spaces into input field, because of that I use bootstrap class
"breadcrumb-item" for <li> item but not the component Breadcrumb.Item itself */

                              if (crumbsList.length === 1) {
                                  return (
                                      <Breadcrumb.Item
                                          key={`breadcrumbs-first-${idx}`}
                                          active={true}>
                                          {crumbsList[0].name}
                                      </Breadcrumb.Item>
                                  );
                              } else if (crumbsList.length > 1) {
                                  return (
                                      <li
                                          key={`breadcrumbs-list-${idx}`}
                                          className="breadcrumb-item">
                                          <BreadcrumbDropdown crumbsList={crumbsList} />
                                      </li>
                                  );
                              }
                              return "Flow doesn't exist for selected filter";
                          })
                        : null}
                </Breadcrumb>
                {flowStatus}
                {!isComparison && !showSearch ? (
                    <BreadcrumbNavigationButtons
                        isFirst={isFirst}
                        isLast={isLast}
                        navigateToPreviousFlow={navigateToPreviousFlow}
                        navigateToNextFlow={navigateToNextFlow}
                    />
                ) : null}
            </>
        );
    } else if (!isComparison) {
        breadcrumb = <Breadcrumb>{homeCrumb}</Breadcrumb>;
    }

    return breadcrumb;
};

export default BreadcrumbNavigationBreadcrumb;
