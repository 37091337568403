import React from "react";
import prettyLocale from "utils/prettyLocale";
import { IFSectionsListTaskReassignModalPropsTypes } from "./interfaces";
import { Table } from "react-bootstrap";

const SectionsListTaskReassignModal: React.FunctionComponent<
    IFSectionsListTaskReassignModalPropsTypes
> = ({ currentVariation, newestVariation }) => {
    return (
        <Table className="SectionsListTaskReassignModal" size="sm" bordered={true}>
            <thead>
                <tr>
                    <th>Current</th>
                    <th>Updated</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{currentVariation.platform}</td>
                    <td>{newestVariation.platform}</td>
                </tr>
                <tr>
                    <td>{currentVariation.product}</td>
                    <td>{newestVariation.product}</td>
                </tr>

                <tr>
                    <td>{prettyLocale(currentVariation.locale)}</td>
                    <td>{prettyLocale(newestVariation.locale)}</td>
                </tr>
                <tr>
                    <td>{currentVariation.device}</td>
                    <td>{newestVariation.device}</td>
                </tr>
                <tr>
                    <td>{currentVariation.version}</td>
                    <td>{newestVariation.version}</td>
                </tr>
                <tr>
                    <td>{currentVariation.branch || "Branch is unknown"}</td>
                    <td>{newestVariation.branch || "Branch is unknown"}</td>
                </tr>
                <tr>
                    <td>{currentVariation.os || "OS is unknown"}</td>
                    <td>{newestVariation.os || "OS is unknown"}</td>
                </tr>
            </tbody>
        </Table>
    );
};

export default SectionsListTaskReassignModal;
