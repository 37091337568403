const BRAND_MAP = {
    badoo: "Badoo",
    bumble: "Bumble",
    fiesta: "Fiesta",
    hon: "Hot or Not",
    chat_and_date: "Chat and Date",
    lumen: "Lumen",
    fruitz: "Fruitz",
    maya: "Maya",
};

const prettyBrand = (brand: string) => {
    return BRAND_MAP[brand.toLowerCase()] || brand;
};

export default prettyBrand;
