import React, { Component } from "react";

import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Screenshot from "components/Screenshot";
import ScreenshotLexemes from "components/ScreenshotLexemes";
import ScreenshotStatPane from "./ScreenshotStatPane";

import { Button, Col } from "react-bootstrap";
import "./ScreenshotWithPane.scss";

function withStats(WrappedScreenshot) {
    return class WrappedScreenshotWithPane extends Component {
        constructor(props) {
            super(props);

            this.showLexemePane = this.showLexemePane.bind(this);
            this.toggleLexemePane = this.toggleLexemePane.bind(this);

            this.screenshotRef = React.createRef();

            this.state = {
                isExpanded: false,
                isLoading: false,
                content: null,
            };
        }

        componentDidMount() {
            const { showLexemes } = this.props;

            if (showLexemes) {
                this.showLexemePane();
            }
        }

        UNSAFE_componentWillReceiveProps(nextProps) {
            const { showLexemes } = nextProps;

            if (showLexemes) {
                this.showLexemePane(false, nextProps);
            } else if (!showLexemes) {
                this.setState({
                    isExpanded: false,
                });
            }
        }

        collapseExpandedPaneOfType(paneType) {
            if (this.state.isExpanded === true && this.state.paneType === paneType) {
                this.setState({
                    isExpanded: false,
                });

                return true;
            }

            return false;
        }

        toggleLexemePane(shouldScrollToElement) {
            window.gtag("event", "click", {
                event_label: "ToggleLexemePane",
            });

            if (!this.collapseExpandedPaneOfType("lexeme")) {
                this.showLexemePane(shouldScrollToElement);
            }
        }

        showLexemePane(shouldScrollToElement, props) {
            const { lexemes } = props || this.props;

            const content = <ScreenshotLexemes lexemes={lexemes} />;

            this.setState(
                {
                    content,
                    paneType: "lexeme",
                    isExpanded: true,
                },
                () => {
                    if (shouldScrollToElement) {
                        this.screenshotRef.current.scrollIntoViewIfNeeded();
                    }
                },
            );
        }

        render() {
            const { isExpanded, isLoading, content } = this.state;
            const { overlayItems = [], lexemes, showLexemes } = this.props;

            const { colWidth } = this.props;

            const existingLexemesButton = overlayItems.filter(
                (element: { props: { title: string } }) => element.props.title === "Lexemes",
            )[0];
            if (
                (lexemes && lexemes.length && !existingLexemesButton) ||
                (lexemes && !lexemes.length && !existingLexemesButton && showLexemes)
            ) {
                overlayItems.push(
                    <Button
                        key="view-lexemes-screenshot-with-pane"
                        title="Lexemes"
                        size="lg"
                        onClick={this.toggleLexemePane}>
                        <FontAwesomeIcon size="lg" icon={faList} />
                    </Button>,
                );
            } else if (lexemes && !lexemes.length && existingLexemesButton && !showLexemes) {
                overlayItems.pop();
            }

            return (
                <Col
                    xs={12}
                    md={colWidth}
                    className={`ScreenshotWithPane ${isExpanded ? "expanded" : ""}`}>
                    <div className="ScreenshotContainer" ref={this.screenshotRef}>
                        <WrappedScreenshot
                            {...this.props}
                            {...this.state}
                            overlayItems={overlayItems}
                        />
                    </div>

                    <ScreenshotStatPane isExpanded={isExpanded} isLoading={isLoading}>
                        {content}
                    </ScreenshotStatPane>
                </Col>
            );
        }
    };
}

const ScreenshotWithPane = withStats(Screenshot);

export default ScreenshotWithPane;
