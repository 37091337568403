import React from "react";
import { LinkContainer } from "react-router-bootstrap";

import encodeIntoQueryParams from "utils/encodeIntoQueryParams";

import { IFlowComparisonColumnPropsTypes } from "./interfaces";

import { trackFlowPick } from "./trackAnalytics";

import FlowSelector from "components/FlowSelector";
import ModePanel from "components/ModePanel";
import { Col, Button } from "react-bootstrap";

const FlowComparisonColumn: React.FunctionComponent<IFlowComparisonColumnPropsTypes> = ({
    filter,
    flowId,
    flowKey,
    changeMode,
    anyComparable,
    changeFlowId,
    altFlow,
    areFlowsTheSame,
    isScreenshotsIdentical,
    differentOnly,
}) => {
    return (
        <Col xs={12} md={2} className="StickyFlow">
            <FlowSelector
                flowKey={flowKey}
                filter={filter}
                isComparison={true}
                changeFlowId={changeFlowId}
                allowChoosingPods={true}
                altFlow={altFlow}
                differentOnly={differentOnly}
            />
            <LinkContainer
                block={true}
                className="flow-actions"
                to={`/flow/${flowId}?${encodeIntoQueryParams({ flow: filter })}`}
                onClick={trackFlowPick}>
                <Button>Pick this flow</Button>
            </LinkContainer>
            {changeMode === undefined && anyComparable === undefined ? null : (
                <ModePanel
                    isComparison={true}
                    onChange={changeMode}
                    anyComparable={anyComparable}
                    areFlowsTheSame={areFlowsTheSame}
                    isScreenshotsIdentical={isScreenshotsIdentical}
                />
            )}
        </Col>
    );
};

export default FlowComparisonColumn;
