import React, { Component } from "react";
import _ from "lodash";

import ScreenshotWithDropdown from "./ScreenshotWithDropdown";

import { Container, Row } from "react-bootstrap";

class ScreenshotsWithDropdown extends Component {
    componentDidMount() {
        this.updateScreens(this.props);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.updateScreens(nextProps);
    }

    updateScreens(props) {
        const { screenshots, first, field } = props;

        const screens = getUniqueFields(screenshots, field).sort((uniqueField) =>
            uniqueField === first ? -1 : 1,
        );

        this.setState({
            screens,
            selectedScreens: _.slice(screens, 0, 4),
        });
    }

    onSelect(idx, value) {
        const { screens, selectedScreens } = this.state;

        if (screens[idx]) {
            const newLangs = [...selectedScreens];
            newLangs[idx] = value;

            this.setState({
                selectedScreens: newLangs,
            });
        }
    }

    render() {
        if (!this.state) {
            return null;
        }

        const { screens, selectedScreens } = this.state;

        const { screenshots, pretty, field, secondField } = this.props;

        return (
            <Container fluid={true}>
                <Row className="Comparison">
                    {selectedScreens.map((screen, idx) => {
                        const screenshot =
                            _.find(screenshots, {
                                [field]: screen,
                            }) || screenshots[0];

                        return (
                            <ScreenshotWithDropdown
                                key={idx}
                                field={field}
                                secondField={secondField}
                                screens={screens.map((screen) => ({
                                    value: screen,
                                    display: pretty(screen),
                                }))}
                                screenshot={screenshot}
                                onSelect={this.onSelect.bind(this, idx)}
                            />
                        );
                    })}
                </Row>
            </Container>
        );
    }
}

export default ScreenshotsWithDropdown;

function getUniqueFields(list, field) {
    return _.uniq(list.map((listItem) => listItem[field]));
}
