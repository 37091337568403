import React from "react";
import { ISelectAll } from "./interfaces";
import { InputGroup } from "react-bootstrap";

const SelectAll: React.FunctionComponent<ISelectAll> = ({
    handleSelectAll,
    checked,
    translatorId,
}) => {
    return (
        <div className="select-all">
            <InputGroup as="label">
                <InputGroup.Checkbox
                    id={`select-all${translatorId || ""}`}
                    checked={!!checked}
                    onChange={handleSelectAll}
                />
                <InputGroup.Text>Select all</InputGroup.Text>
            </InputGroup>
        </div>
    );
};

export default SelectAll;
