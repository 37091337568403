export const filterSectionsByFlowsArray = (
    sections: { name: string; id: number; flows: { name: string; id: number }[] }[],
    flowsIds: number[],
) => {
    return sections
        .map((item: { name: string; id: number; flows: { name: string; id: number }[] }) => {
            return {
                ...item,
                flows: item.flows.filter((fl) => flowsIds.includes(fl.id)),
            };
        })
        .filter((item: { flows: { name: string; id: number }[] }) => item.flows.length > 0);
};
