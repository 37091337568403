import React, { Component } from "react";
import ReactLoading from "react-loading";

import { getScreenshot } from "models/getScreenshot";

import ErrorMessage from "components/ErrorMessage";
import Screenshot from "components/Screenshot";
import { Container, Row, Col } from "react-bootstrap";

class ScreenshotPage extends Component {
    constructor() {
        super();

        this.state = {
            screenshot: {},
            error: false,
            loading: true,
        };
    }

    componentDidMount() {
        this.fetchScreenshot(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.fetchScreenshot(props);
    }

    fetchScreenshot(props) {
        const { screenshotId } = props.match.params;

        getScreenshot(screenshotId)
            .then((screenshot) => {
                this.setState({
                    screenshot,
                    error: false,
                    loading: false,
                });
            })
            .catch((error) => {
                this.setState({
                    error,
                    loading: false,
                });
            });
    }

    render() {
        if (this.state.loading) {
            return (
                <ReactLoading
                    type="bubbles"
                    color="#888"
                    className="AbsoluteCentered"
                    delay={300}
                />
            );
        }

        if (this.state.error) {
            return (
                <Container>
                    <Row>
                        <Col xs={12} md={10}>
                            <ErrorMessage error={this.state.error} />
                        </Col>
                    </Row>
                </Container>
            );
        }

        const { screenshot } = this.state;

        return (
            <Container className="ScreenshotPage">
                <Row>
                    <Col xs={3} md={3}>
                        <Screenshot {...screenshot} />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default ScreenshotPage;
