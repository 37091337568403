import { get } from "../api";
import { cache } from "utils/cache";

export const getTasksCreatedByMe = cache(function () {
    return get(`tasks/my_created`);
});

export const getLastTaskStatus = function () {
    return get(`tasks/my_last_status`);
};

export const getLastTask = cache(function (taskId: number) {
    return get(`tasks/my/${taskId}`);
});

export const getAllTasks = function () {
    return get(`tasks/all_not_deleted`);
};
