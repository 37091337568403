import React, { useCallback } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusCircle, faRedo } from "@fortawesome/free-solid-svg-icons";

import { ITaskTableActionButtonPropsTypes } from "./interfaces";

import { Button } from "react-bootstrap";

const TasksTableActionButton: React.FunctionComponent<ITaskTableActionButtonPropsTypes> = ({
    task,
    onDelete,
}) => {
    const { id, status, has_failed } = task;

    const isTaskFinished = status === "finished";

    const handleDelete = useCallback(
        (evt) => {
            evt.stopPropagation();
            onDelete(task);
        },
        [onDelete, task],
    );
    const hasReassign = isTaskFinished && has_failed;
    const isHiddenDelete = isTaskFinished && !has_failed;

    const history = useHistory();

    const handleReassign = useCallback(
        (evt) => {
            evt.stopPropagation();
            history.push({
                pathname: `task/${id}`,
                state: { isReassignMode: true },
            });
        },
        [history, id],
    );

    const deleteButton = isHiddenDelete ? null : (
        <Button onClick={handleDelete} aria-label="Delete" variant="danger">
            <FontAwesomeIcon size="lg" icon={faMinusCircle} />
        </Button>
    );

    const reassignLink = (
        <Button onClick={handleReassign} aria-label="Reassign" variant="info">
            <FontAwesomeIcon size="lg" icon={faRedo} />
        </Button>
    );

    return hasReassign ? reassignLink : deleteButton;
};

export default TasksTableActionButton;
