import React, { Component } from "react";

import { getUsers, saveRoles } from "models/getUsers";

import AdminTable from "components/AdminTable";
import ErrorMessage from "components/ErrorMessage";
import { Button, Col, Container, Row } from "react-bootstrap";

import "./AdminPage.scss";

class AdminPage extends Component {
    constructor(props) {
        super(props);
        this.setChangesToState = this.setChangesToState.bind(this);
        this.saveChangesToServer = this.saveChangesToServer.bind(this);
        this.changeDropdownValue = this.changeDropdownValue.bind(this);
        this.updateSavedUsers = this.updateSavedUsers.bind(this);

        this.state = {
            changes: {},
            users: null,
            pageError: null,
        };
    }

    componentDidMount() {
        getUsers()
            .then((users) => {
                this.setState({ users });
                this.updateSavedUsers();
            })
            .catch((err) => {
                console.error(err);
                this.setState({ pageError: err });
            });
    }

    render() {
        const { users, changes, pageError } = this.state;

        if (pageError) return <ErrorMessage error={pageError} />;

        return (
            <Container className="AdminPage">
                <Row className="admin-table-row">
                    <Col xs={12} md={9} className="admin-table-column">
                        {users ? (
                            <AdminTable
                                users={users}
                                changeDropdownValue={this.changeDropdownValue}
                            />
                        ) : null}
                    </Col>
                </Row>
                <Row>
                    <Button
                        className="save-changes"
                        disabled={isEmpty(changes)}
                        onClick={this.saveChangesToServer}>
                        Save changes
                    </Button>
                </Row>
            </Container>
        );
    }

    changeDropdownValue(userId, selectedValue) {
        this.setState(
            (prevState) => {
                const users = Object.assign({}, prevState.users);
                users[userId] = { name: users[userId].name, role: selectedValue };
                return { users };
            },
            () => this.checkChangesAndUpdateButton(),
        );
    }

    checkChangesAndUpdateButton() {
        const changes = {};
        const savedUsers = this.state.savedUsers;
        const users = this.state.users;
        Object.keys(users).forEach((key) => {
            if (users[key].role !== savedUsers[key].role) {
                changes[key] = users[key].role;
            } else {
                delete changes[key];
            }
        });
        this.setChangesToState(changes);
    }

    updateSavedUsers() {
        const users = this.state.users;
        const savedUsers = JSON.parse(JSON.stringify(users));
        this.setState({ savedUsers });
    }

    setChangesToState(changes) {
        this.setState({ changes });
    }

    saveChangesToServer() {
        if (!isEmpty(this.state.changes)) {
            saveRoles(this.state.changes);
            this.setState({ changes: {} }, this.updateSavedUsers);
        }
    }
}

export default AdminPage;

function isEmpty(obj) {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return true;
}
