import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import Auth from "utils/services/Auth";

import ReactLoading from "react-loading";
import { Button, Card } from "react-bootstrap";

import "./LoginPage.scss";

class LoginPage extends Component {
    constructor() {
        super();

        this.state = {
            isLoading: false,
            redirectToReferrer: false,
        };

        this.login = this.login.bind(this);
        this.createPopup = this.createPopup.bind(this);
    }

    componentWillUnmount() {
        if (this.externalWindow) {
            this.externalWindow.close();
        }
    }

    login(code) {
        this.setState({
            isLoading: true,
        });

        Auth.login(code, () => {
            this.setState(
                () => ({
                    redirectToReferrer: true,
                }),
                () => {
                    try {
                        const returnTo = JSON.parse(localStorage.getItem("returnTo")) || {
                            pathname: "/",
                            search: window.location.search,
                        };

                        localStorage.removeItem("returnTo");
                        this.props.history.push(returnTo);
                    } catch (e) {
                        console.warn(e.message());
                    }
                },
            );
        });
    }

    createPopup() {
        if (this.externalWindow) {
            this.externalWindow.close();
        }

        const client_id = process.env.LIVESHOTS_STAFFPASS_CLIENT_ID;
        const redirect_uri = process.env.LIVESHOTS_STAFFPASS_REDIRECT_URI;
        const url = `https://staffpass.com/oauth2/auth.phtml?client_id=${client_id}&redirect_uri=${redirect_uri}&response_type=code&scope=base-info`;

        this.externalWindow = window.open(url);

        this.codeCheck = setInterval(() => {
            if (this.externalWindow.closed) {
                clearInterval(this.codeCheck);
                return;
            }

            try {
                const params = new URL(this.externalWindow.location).searchParams;
                const code = params.get("code");
                if (!code) {
                    return;
                }
                clearInterval(this.codeCheck);
                this.externalWindow.close();
                this.login(code);
            } catch (e) {
                // CORS violation will raise errors until we will be redirected back to LiveShots on success
            }
        }, 20);
    }

    render() {
        const { from } = this.props.location.state || { from: { pathname: "/" } };
        const { redirectToReferrer, isLoading } = this.state;

        if (redirectToReferrer === true) {
            return <Redirect to={from} />;
        }

        return (
            <Card className={"LoginPanel"}>
                <Card.Body>
                    <h1>LiveShots</h1>
                    <Button
                        variant={"success"}
                        className={"LoginButton"}
                        onClick={this.createPopup}
                        disabled={isLoading}>
                        {isLoading ? (
                            <ReactLoading
                                className={"AbsoluteCentered"}
                                width={20}
                                height={20}
                                type="bubbles"
                                delay={0}
                            />
                        ) : (
                            `Login`
                        )}
                    </Button>
                </Card.Body>
            </Card>
        );
    }
}

export default LoginPage;
