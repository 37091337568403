import React from "react";

import TripleRow from "components/TripleRow";
import { Container } from "react-bootstrap";

const TripleColumns = ({ leftItems, middleItems, rightItems, className }) => {
    const out = [];

    const maxLen = Math.max(leftItems.length, rightItems.length);

    for (let k = 0; k < maxLen; k++) {
        out.push(
            <TripleRow
                key={k}
                left={leftItems[k]}
                middle={middleItems?.[k] || null}
                right={rightItems[k]}
            />,
        );
    }
    return (
        <Container fluid={true} className={className || ""}>
            {out}
        </Container>
    );
};

export default TripleColumns;
