import appState from "appState";
import React, { Component } from "react";
import { hot } from "react-hot-loader";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

import parseSearchString from "utils/parseSearchString";

import PrivateRoute from "components/PrivateRoute";

import AdminPage from "pages/AdminPage";
import QaapiPage from "pages/QaapiPage/QaapiPage";

import LoginPage from "pages/LoginPage";
import AuthCallbackPage from "pages/AuthCallbackPage/";

import FlowComparisonPage from "pages/FlowComparisonPage";
import FlowExplorerPage from "pages/FlowExplorerPage";
import FlowPage from "pages/FlowPage";

import RunResultPage from "pages/RunResultPage";
import SearchResultPage from "pages/SearchResultPage";

import SectionPage from "pages/SectionPage";
import SectionsListPage from "pages/SectionsListPage";
import SectionsListByTCIdPage from "pages/SectionsListByTCIdPage";

import ScreenshotPage from "pages/ScreenshotPage";

import TaskManagerPage from "pages/TaskManagerPage";
import TaskPage from "pages/TaskPage";

import "./App.scss";

class App extends Component {
    constructor({ history }) {
        super();
        appState.setHistory(history);
    }

    componentDidMount() {
        this.updateBodyClass();
    }

    componentDidUpdate() {
        this.updateBodyClass();
    }

    updateBodyClass() {
        const selectedFilters = appState.get("flow");
        const product = selectedFilters ? selectedFilters.product : null;

        document.body.classList.forEach((item) => {
            if (item.startsWith("theme-")) {
                document.body.classList.remove(item);
            }
        });

        if (product) {
            document.body.classList.add(`theme-${product}`);
        }
    }

    render() {
        const trackingDetailsGA = {
            page_location: `http://${window.location.host}${window.location.pathname}`,
            page_path: window.location.pathname,
            dimension1: JSON.stringify(appState.get("flow")),
            dimension2: JSON.stringify({
                showLexemes: appState.get("showLexemes"),
            }),
            send_page_view: true,
        };

        // GA page tracking
        window.gtag("config", "G-JGVLXRXYMJ", trackingDetailsGA); // stage http://liveshots.d4/

        window.gtag("config", "G-2KSW7JLF1L", trackingDetailsGA); // prod https://liveshots.staffpass.com/

        if (redirectToNewProduction()) {
            return null;
        }

        return (
            <div className="App">
                <Switch>
                    <Redirect
                        from="/jira/:search"
                        to={{
                            pathname: "/search/:search",
                            state: {
                                from: this.props.location,
                                platform: parseSearchString().get("platform"),
                                product: parseSearchString().get("product"),
                            },
                        }}
                    />
                    <Route path="/login" component={LoginPage} />
                    <Route path="/auth/callback" component={AuthCallbackPage} />
                    <PrivateRoute path="/" component={SectionsListPage} title="Home" exact={true} />
                    <PrivateRoute
                        path="/flows/tcbuild/:tcBuildId"
                        component={SectionsListByTCIdPage}
                        title="Home"
                    />
                    <PrivateRoute path="/flows" component={SectionsListPage} title="Home" />
                    <PrivateRoute
                        path="/section/:sectionId/"
                        component={SectionPage}
                        title="Section"
                    />
                    <PrivateRoute
                        path="/flow/:flowId/:screenshotId?/:comparedField?"
                        component={FlowPage}
                        title="Flow"
                    />
                    <PrivateRoute
                        path="/flow-comparison/:firstFlowId/:secondFlowId"
                        component={FlowComparisonPage}
                        title="Flow Comparison"
                    />
                    <PrivateRoute
                        path="/search/:search"
                        component={SearchResultPage}
                        title="Search"
                    />
                    <PrivateRoute path="/run/:runId" component={RunResultPage} title="Run" />
                    <PrivateRoute
                        path="/flow-explorer/:flows?"
                        component={FlowExplorerPage}
                        title="Flow Explorer"
                    />
                    <PrivateRoute
                        path="/screenshot/:screenshotId?"
                        component={ScreenshotPage}
                        title="Screenshot"
                    />
                    <PrivateRoute path="/admin" component={AdminPage} title="Admin" />
                    <PrivateRoute
                        path="/taskmanager"
                        component={TaskManagerPage}
                        title="Tasks List"
                    />
                    <PrivateRoute path={"/task/:taskId"} component={TaskPage} title="Task" />
                    <PrivateRoute path={"/qaapi"} component={QaapiPage} title="QAAPI" />
                </Switch>
            </div>
        );
    }
}

function redirectToNewProduction() {
    const oldDomain = "http://liveshots.mlan";
    const newDomain = "https://liveshots.staffpass.com";

    if (window.location.href.indexOf(oldDomain) !== -1) {
        window.location.href = newDomain + window.location.href.substring(oldDomain.length);
        return true;
    }

    return false;
}

// eslint-disable-next-line no-undef
export default hot(module)(withRouter(App));
