export const statusToStyle = (status: string) => {
    let style;
    if (status) {
        switch (status) {
            case "approved":
                style = "success";
                break;
            case "failed":
                style = "danger";
                break;
            case "in_progress":
            case "reassigned_in_progress":
                style = "warning";
                break;
            case "reassigned":
                style = "info";
                break;
            default:
                style = "";
                break;
        }
    }
    return style;
};
