export const pixelDiffRatioThreshold = 10e-7;

export const openDataUrl = (dataUrl: string) => {
    // https://stackoverflow.com/a/58625431/540085

    const newWindow = window.open("about:blank", "difference");
    const image = new Image();
    image.src = dataUrl;
    image.style.height = "100%";

    setTimeout(function () {
        if (newWindow) {
            newWindow.document.write(image.outerHTML);
        }
    }, 0);
};

export const getDifferenceIndicatorColour = (
    comparisonData: {
        comment?: string;
        dataUrl: any;
        diffPixelRatio?: number;
        isComparable?: boolean;
    },
    isComparable: boolean,
    diffPixelRatio: number,
) => {
    let indicatorColour = "";
    if (comparisonData) {
        indicatorColour = " u-bg-grey"; // waiting for data

        if (comparisonData.dataUrl)
            indicatorColour = ` u-bg-${
                isComparable && diffPixelRatio > pixelDiffRatioThreshold ? "red" : "green"
            }`;
    }
    return indicatorColour;
}; // no colour
