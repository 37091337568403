import React, { ChangeEvent, ReactElement } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Toggle from "components/Toggle";

import "./PanelToggle.scss";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function PanelToggle({
    title,
    onChange,
    isToggled,
    isDisabled,
    className,
    hasTooltip,
    tooltipText,
    tooltipId,
}: {
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    title: string;
    isToggled: boolean;
    isDisabled: boolean;
    className?: string;
    hasTooltip?: boolean;
    tooltipText?: ReactElement<any, any>;
    tooltipId?: string;
}) {
    let clName = "PanelToggle";
    if (className) {
        clName += ` ${className}`;
    }
    return (
        <div className={clName}>
            <strong>{title}</strong>
            <Toggle onChange={onChange} isChecked={isToggled} isDisabled={isDisabled} />
            {hasTooltip && tooltipText && tooltipId ? (
                <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id={tooltipId}>{tooltipText}</Tooltip>}>
                    <FontAwesomeIcon
                        className="different-only-info"
                        size="lg"
                        icon={faInfoCircle}
                    />
                </OverlayTrigger>
            ) : null}
        </div>
    );
}
