import React from "react";

import { KEYS_TO_COMPARE } from "./variables";
import { IFlowComparisonPageIdenticalFiltersMessagePropsTypes } from "./interfaces";

import Spacer from "components/Spacer";
import { Row, Col, Card } from "react-bootstrap";

const FlowComparisonPageIdenticalFiltersMessage: React.FunctionComponent<
    IFlowComparisonPageIdenticalFiltersMessagePropsTypes
> = ({ filter }) => {
    return (
        <Row className="FlowComparisonPageIdenticalFiltersMessage">
            <Col>
                <Card bg="warning">
                    <Card.Body>
                        <span>
                            (☉_ ☉)
                            <br />
                            uh-oh, you are comparing the same data in both filters:
                        </span>
                        <br />
                        <ul className="filter-values">
                            {KEYS_TO_COMPARE.map((key) => {
                                const value =
                                    key === "pod" && !filter[key] ? "All pods" : filter[key];
                                return <li key={`filter-${value}`}>{value}</li>;
                            })}
                        </ul>
                    </Card.Body>
                </Card>
                <Spacer key="spacer" bottom="lg" />
            </Col>
        </Row>
    );
};

export default FlowComparisonPageIdenticalFiltersMessage;
