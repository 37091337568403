import React, { Component } from "react";
import appState from "appState";

import checkObjectsShallowEquality from "utils/checkObjectsShallowEquality";

import PanelToggle from "components/PanelToggle";
import { Card } from "react-bootstrap";

class ModePanel extends Component {
    constructor(props) {
        super(props);

        this.handleToggleLexemes = this.handleToggleLexemes.bind(this);
        this.handleToggleShowComparison = this.handleToggleShowComparison.bind(this);
        this.handleToggleExcludeIdentical = this.handleToggleExcludeIdentical.bind(this);
        this.handleShowComparisonKey = this.handleShowComparisonKey.bind(this);
        this.handleExcludeIdenticalKey = this.handleExcludeIdenticalKey.bind(this);
        this.handleDifferentOnlyKey = this.handleDifferentOnlyKey.bind(this);
        this.handleToggleDifferentOnly = this.handleToggleDifferentOnly.bind(this);

        this.toggleSwitchHandlers = [
            this.handleShowComparisonKey,
            this.handleExcludeIdenticalKey,
            this.handleDifferentOnlyKey,
        ];

        const showLexemes = appState.get("showLexemes") || false;
        const showComparison = appState.get("showComparison") || false;
        const excludeIdentical = appState.get("excludeIdentical") || false;
        const differentOnly = appState.get("differentOnly") || false;

        this.state = {
            showLexemes,
            showComparison,
            excludeIdentical,
            differentOnly,
        };
    }

    componentDidMount() {
        const { showLexemes, showComparison, excludeIdentical, differentOnly } = this.state;
        const { onChange } = this.props;

        onChange({
            showLexemes,
            showComparison,
            excludeIdentical,
            differentOnly,
        });

        this.toggleSwitchHandlers.forEach((toggleSwitch) => {
            document.addEventListener("keydown", toggleSwitch);
        });
    }

    componentWillUnmount() {
        this.toggleSwitchHandlers.forEach((toggleSwitch) => {
            document.removeEventListener("keydown", toggleSwitch);
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const { showLexemes, showComparison, excludeIdentical, differentOnly } = this.state;
        const { onChange } = this.props;

        if (!checkObjectsShallowEquality(this.state, prevState)) {
            onChange({
                showLexemes,
                showComparison,
                excludeIdentical,
                differentOnly,
            });
        }
        if (this.props.showLexemes !== prevProps.showLexemes) {
            this.setState({ showLexemes: this.props.showLexemes });
        }
    }

    handleToggleLexemes() {
        window.gtag("event", "click", {
            event_label: "LexemesToggle",
        });

        const state = {
            showLexemes: !this.state.showLexemes,
        };

        appState.set(state);
        this.setState(state);
    }

    handleStateToggle(state) {
        const { onChange } = this.props;

        appState.set(state);
        this.setState(state);
        onChange(state);
    }

    handleToggleShowComparison() {
        const { showComparison, excludeIdentical, differentOnly } = this.state;

        const state = {
            showComparison: !showComparison,
            /* turn off Exclude Identical when a user turned off Show Comparison */
            excludeIdentical: showComparison && excludeIdentical ? false : excludeIdentical,
            differentOnly: showComparison && differentOnly ? false : differentOnly,
        };

        this.handleStateToggle(state);
    }

    handleToggleExcludeIdentical() {
        window.gtag("event", "click", {
            event_label: "ExcludeIdenticalToggle",
        });
        const { excludeIdentical } = this.state;

        const state = {
            ...this.state,
            excludeIdentical: !excludeIdentical,
        };

        this.handleStateToggle(state);
    }

    handleToggleDifferentOnly() {
        window.gtag("event", "click", {
            event_label: "DifferentOnlyToggle",
        });

        const { differentOnly } = this.state;
        const state = {
            ...this.state,
            differentOnly: !differentOnly,
        };

        this.handleStateToggle(state);
    }

    handleShowComparisonKey(evt) {
        const showComparisonSwitch = document.querySelector(".show-comparison-toggle input");
        // key code 83 is English "s"
        if (evt.keyCode === 83 && showComparisonSwitch && !showComparisonSwitch.disabled)
            this.handleToggleShowComparison();
    }

    handleExcludeIdenticalKey(evt) {
        const excludeIdenticalSwitch = document.querySelector(".exclude-identical-toggle input");

        // key code 69 is English "e"
        if (evt.keyCode === 69 && excludeIdenticalSwitch && !excludeIdenticalSwitch.disabled)
            this.handleToggleExcludeIdentical();
    }

    handleDifferentOnlyKey(evt) {
        const differentOnlySwitch = document.querySelector(".different-only-toggle input");
        // key code 68 English "d"
        if (evt.keyCode === 68 && differentOnlySwitch && !differentOnlySwitch.disabled)
            this.handleToggleDifferentOnly();
    }

    render() {
        const { showLexemes, showComparison, excludeIdentical, differentOnly } = this.state;
        const { hasLexemes, isComparison, anyComparable, areFlowsTheSame, isScreenshotsIdentical } =
            this.props;

        return (
            <Card className="flow-actions">
                <Card.Body>
                    {isComparison ? (
                        <>
                            <PanelToggle
                                className="show-comparison-toggle"
                                title="Show Comparison"
                                onChange={this.handleToggleShowComparison}
                                isToggled={showComparison}
                                isDisabled={!anyComparable || areFlowsTheSame}
                                key="show-comparison"
                            />
                            <PanelToggle
                                className="exclude-identical-toggle"
                                title="Exclude identical"
                                onChange={this.handleToggleExcludeIdentical}
                                isToggled={excludeIdentical}
                                isDisabled={!showComparison || !anyComparable || areFlowsTheSame}
                                key="exclude-identical"
                            />
                            <PanelToggle
                                className="different-only-toggle"
                                title="Different Only"
                                onChange={this.handleToggleDifferentOnly}
                                isToggled={differentOnly}
                                isDisabled={!showComparison || !anyComparable || areFlowsTheSame}
                                hasTooltip={true}
                                tooltipText={
                                    <>
                                        <p>
                                            This is an EXPERIMENTAL feature. Enable this toggle to
                                            download and compare screenshots for all flows based on
                                            your filters.
                                        </p>
                                        <p> Please, filter by POD to decrease loading time. </p>
                                        <p>
                                            It only processes flows that exist for both compared
                                            parameters, have screenshots, and show differences.
                                        </p>
                                        <p>
                                            Use this with the &ldquo;Exclude Identical&rdquo; option
                                            to view only differing screenshots.
                                        </p>
                                        <p>
                                            Feedback is welcome in{" "}
                                            <strong>#mp-quality-services-public</strong>!
                                        </p>
                                    </>
                                }
                                tooltipId="different-only"
                                key="different-only"
                            />
                        </>
                    ) : (
                        <PanelToggle
                            title="Lexemes"
                            onChange={this.handleToggleLexemes}
                            isToggled={showLexemes && hasLexemes}
                            isDisabled={!hasLexemes}
                            key="lexemes"
                        />
                    )}
                </Card.Body>
            </Card>
        );
    }
}

export default ModePanel;
