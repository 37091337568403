import React from "react";

import Flow from "components/Flow";
import { CardColumns } from "react-bootstrap";
import { IFlowsListPropsTypes } from "./interfaces";

const FlowsList: React.FunctionComponent<IFlowsListPropsTypes> = ({ flows, search }) => {
    return (
        <CardColumns>
            {flows.map(
                (flow: { description: string; id: number; name: string }, idx: React.Key) => (
                    <Flow key={idx} flow={flow} search={search} />
                ),
            )}
        </CardColumns>
    );
};

export default FlowsList;
