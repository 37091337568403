const LOCALE_MAP = {
    "en-gb": "English (UK)",
    "en-us": "English (US)",
    "en-au": "English (Australia)",
    "en-in": "English (India)",
    en: "English",
    de: "German",
    fr: "French",
    "fr-ca": "French (Canadian)",
    es: "Spanish",
    it: "Italian",
    "pt-br": "Portuguese (Brazil)",
    ru: "Russian",
    zh: "Chinese",
    af: "Afrikaans",
    id: "Indonesian",
    bs: "Bosnian",
    ca: "Catalan",
    cs: "Czech",
    ht: "Creole",
    cy: "Welsh",
    da: "Danish",
    et: "Estonian",
    "es-mx": "Spanish (Mexican)",
    "es-ar": "Spanish (Argentinian)",
    "es-co": "Spanish (Colombian)",
    eo: "Esperanto",
    eu: "Basque",
    fo: "Faroese",
    ga: "Irish",
    gv: "Manx",
    gl: "Galician",
    hr: "Croatian",
    haw: "Hawaiian",
    is: "Icelandic",
    kl: "Kalaallisut",
    kw: "Cornish",
    sw: "Swahili",
    kuk: "Kurdish (Kurmanji)",
    kus: "Kurdish (Sorani)",
    lv: "Latvian",
    lb: "Luxembourgish",
    lt: "Lithuanian",
    hu: "Hungarian",
    mt: "Maltese",
    ms: "Malay",
    nl: "Dutch",
    nb: "Norwegian (Bokmal)",
    nn: "Norwegian (Nynorsk)",
    om: "Oromo",
    pl: "Polish",
    pt: "Portuguese",
    "pt-pt": "Portuguese (Portugal)",
    aa: "Afar",
    ro: "Romanian",
    smi: "Sami",
    sq: "Albanian",
    sid: "Sidamo",
    sgn: "Sign language",
    sl: "Slovenian",
    sk: "Slovak",
    so: "Somali",
    sr: "Serbian",
    sh: "Serbo-Croatian",
    fi: "Finnish",
    sv: "Swedish",
    tl: "Tagalog",
    ber: "Berber",
    vi: "Vietnamese",
    tr: "Turkish",
    val: "Valencian",
    el: "Greek",
    az: "Azerbaijani",
    be: "Belarusian",
    bg: "Bulgarian",
    ky: "Kirghiz",
    kk: "Kazakh",
    mk: "Macedonian",
    mn: "Mongolian",
    tt: "Tatar",
    uk: "Ukrainian",
    uz: "Uzbek",
    ka: "Georgian",
    hy: "Armenian",
    arc: "Aramaic",
    he: "Hebrew",
    ur: "Urdu",
    ar: "Arabic",
    ps: "Pashto",
    fa: "Persian",
    syr: "Syriac",
    dv: "Divehi",
    ti: "Tigrinya",
    am: "Amharic",
    kok: "Konkani",
    ne: "Nepali",
    mr: "Marathi",
    sa: "Sanskrit",
    hi: "Hindi",
    asm: "Assamese",
    bn: "Bengali",
    pa: "Punjabi",
    gu: "Gujarati",
    ori: "Oriya",
    ta: "Tamil",
    te: "Telugu",
    kn: "Kannada",
    ml: "Malayalam",
    th: "Thai",
    lo: "Lao",
    dz: "Dzongkha",
    zht: "Chinese (traditional)",
    "zh-hant": "Chinese (traditional)",
    ja: "Japanese",
    ko: "Korean",
    "lx-lx": "Lexemish", // The fake language for showing lexeme ids
};

const prettyLocale = (locale: string) => {
    if (locale === null) {
        return;
    }
    return LOCALE_MAP[locale.toLowerCase()] || locale;
};

export default prettyLocale;
