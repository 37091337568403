const checkObjectsShallowEquality = (
    objA: Record<string, any>,
    objB: Record<string, any>,
): boolean => {
    if (objA === objB) {
        return true;
    }

    if (typeof objA !== "object" || objA === null || typeof objB !== "object" || objB === null) {
        return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
        return false;
    }

    for (let i = 0; i < keysA.length; i++) {
        if (objA[keysA[i]] !== objB[keysA[i]]) {
            return false;
        }
    }

    return true;
};

export default checkObjectsShallowEquality;
