import React from "react";

import { IFlowFlowPageFlowDescriptionPropsTypes } from "./interfaces";

import FlowDateAdded from "components/FlowDateAdded";
import { Card } from "react-bootstrap";
import prettyLocale from "utils/prettyLocale";

import FlowPageFlowDescriptionFlowComment from "./FlowPageFlowDescriptionFlowComment";
const FlowPageFlowDescription: React.FunctionComponent<IFlowFlowPageFlowDescriptionPropsTypes> = ({
    flowDescriptionWithLinks,
    flowLastUpdateDate,
    flowDateAdded,
    flowId,
    taskParameters,
    flowLocation,
}) => {
    const flowLocationPath = flowLocation ? (
        <Card.Footer className="flow-location">
            <pre>{flowLocation}</pre>
        </Card.Footer>
    ) : null;

    return (
        <Card className="FlowPageFlowDescription">
            <Card.Body>
                <div>
                    {flowDescriptionWithLinks}
                    {taskParameters ? null : <FlowPageFlowDescriptionFlowComment flowId={flowId} />}
                </div>

                <div className="flow-dates">
                    <span>
                        Last updated:&#32;<strong>{flowLastUpdateDate}</strong>
                    </span>
                    <br />
                    <FlowDateAdded date={flowDateAdded} isFlowForTask={false} />
                </div>
            </Card.Body>
            {taskParameters ? (
                <Card.Footer className="task-parameters">
                    <span>{`${taskParameters.product} • ${taskParameters.platform} • ${
                        taskParameters.device
                    } • ${prettyLocale(taskParameters.locale)} • ${taskParameters.version}`}</span>
                </Card.Footer>
            ) : (
                flowLocationPath
            )}
        </Card>
    );
};

export default FlowPageFlowDescription;
