import { get } from "../api";
import { cache } from "utils/cache";

export const getFlowExplorerTree = cache(function (selectedFlowName, selectedFilters) {
    const flowNameParam = selectedFlowName ? `/${selectedFlowName}` : "";
    return get(`tree${flowNameParam}`, selectedFilters).then((foundFlows) => {
        const exploredFlows = {
            selectedFlowName,
            foundFlows,
        };

        exploredFlows.hasLexemes = foundFlows.some(
            (flow) => flow.metadata && flow.metadata.lexemes && flow.metadata.lexemes.length > 0,
        );

        return exploredFlows;
    });
});
