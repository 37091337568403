import React, { Component } from "react";
import appState from "appState";

import { getSuggestions } from "models/getSuggestions";

import { Highlighter, Typeahead } from "react-bootstrap-typeahead";
import { Badge } from "react-bootstrap";
import "./SearchBar.scss";

class SearchBar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            suggestions: [],
            ...props,
        };

        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.fetchSuggestions = this.fetchSuggestions.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }

    handleInputChange(value) {
        if (value.length > 1) {
            this.fetchSuggestions(value);
        } else {
            this.setState({
                suggestions: [],
            });
        }
        this.setState({
            search: value,
        });
    }

    handleKeyDown(event) {
        const { activeItem } = this.typeahead.state;

        if (event.key === "Enter" && activeItem === null) {
            const search = this.typeahead.getInput().value;
            this.handleSearch(search);
        }
    }

    handleSelect(options) {
        if (options.length > 0) {
            this.handleSearch(options[0].label);
        }
    }

    handleSearch(search) {
        this.props.history.replace({
            pathname: `/search/${search}`,
            search: this.props.location.search,
        });
    }

    override componentDidMount() {
        if (this.state.search) {
            this.fetchSuggestions(this.state.search);
        }
    }

    fetchSuggestions(query) {
        const selectedFilters = appState.get("flow");

        getSuggestions(query, selectedFilters)
            .then((suggestions) => {
                this.setState({
                    suggestions,
                });
            })
            .catch((error) => {
                this.setState({
                    error,
                });
            });
    }

    makeOptions() {
        const { suggestions } = this.state;
        const options = [];

        Object.keys(suggestions).forEach((key) => {
            suggestions[key].forEach((suggestion) => {
                options.push({
                    label: suggestion,
                    isAvailableInLiveshots: true,
                    tags: [getPrettyType(key)],
                });
            });
        });

        return options;
    }

    render() {
        const { search } = this.state;
        const { showSearch } = this.props;

        const options = this.makeOptions();

        options.sort((prev, next) =>
            prev.label.toLowerCase().localeCompare(next.label.toLowerCase()),
        );

        if (showSearch) {
            return (
                <div className="SearchBar">
                    <Typeahead
                        id="search-bar"
                        ref={(typeahead) => (this.typeahead = typeahead)}
                        type="text"
                        placeholder="Search"
                        defaultInputValue={search}
                        options={options}
                        onInputChange={this.handleInputChange}
                        onKeyDown={this.handleKeyDown}
                        onChange={this.handleSelect}
                        renderMenuItemChildren={renderSuggestionItem}
                        maxResults={20}
                        emptyLabel={
                            this.state.search?.length > 2
                                ? "No matches found."
                                : "Give me at least 2 letters, friend ٩(◕‿◕)۶"
                        }
                    />
                </div>
            );
        }
        return null;
    }
}

function renderSuggestionItem(option, props) {
    const className = option.isAvailableInLiveshots ? null : "notAvailableInLiveshots";
    return (
        <span className={className}>
            <span className="highlighter">
                <Highlighter search={props.text}>{option.label}</Highlighter>
            </span>
            {option.tags.map((tag, index) => {
                return (
                    <span key={index} className={"pull-right type"}>
                        <Badge variant="primary">{tag}</Badge>
                    </span>
                );
            })}
        </span>
    );
}

function getPrettyType(type) {
    const types = {
        screenshots: "Screen",
        sections: "Section",
        flows: "Flow",
    };
    return types[type];
}

export default SearchBar;
