import { IFilterWithLocaleTypes, IResolvedDefaultFilterTypes } from "./interfaces";

import { getDefaultFilters } from "models/getDefaultFilters";

export const handleSelectAllChange =
    (
        currentTranslator: string,
        currentSelectedFlows: number[],
        handleSelectAll: {
            (translator: string, input: { id: string; checked: boolean }, newFlows: number[]): void;
            (arg0: string, arg1: { id: string; checked: boolean }, arg2: number[]): any;
        },
    ) =>
    (event: { target: { id: string; checked: boolean } }) =>
        handleSelectAll(currentTranslator, event.target, currentSelectedFlows);

export const setInitialSelectAllValue = (
    selectedFlowsForTask: { [key: string]: number[] },
    translatorFlowsData: { [key: string]: number[] },
) => {
    const newObject: {
        [key: string]: boolean;
    } = {};

    Object.keys(translatorFlowsData).forEach((translator) => {
        const areAppliedFlowsExist = !!(
            selectedFlowsForTask[translator]?.length === translatorFlowsData[translator]?.length
        );
        newObject[translator] = areAppliedFlowsExist;
    });
    return newObject;
};

export const createSelectedFiltersFromDefaultForEachTranslator = (
    currentFilters: {
        translatorsLocales: { [key: string]: string };
        platform: string;
        product: string;
        locale?: string;
    },
    setTranslatorsDefaultFilters: Function,
) => {
    const { translatorsLocales, platform, product } = currentFilters;

    const filterWithLocale: IFilterWithLocaleTypes = {
        platform,
        product,
        locale: "",
        translator: "",
    };
    Promise.all(
        Object.entries(translatorsLocales || {}).map(([key, value]) => {
            filterWithLocale.locale = value;
            filterWithLocale.translator = key;

            return getDefaultFilters(filterWithLocale)
                .then<IResolvedDefaultFilterTypes>(
                    (defaultFilter: IResolvedDefaultFilterTypes) => ({
                        key,
                        value: defaultFilter,
                    }),
                )
                .catch((error: Error) => {
                    console.error(error);
                    return { key, value: { ...error } };
                });
        }),
    )
        .then((results) => {
            const translatorsFiltersObject = results.reduce(
                (acc: { [x: string]: any }, { key, value }: any) => {
                    acc[key] = value;
                    return acc;
                },
                {},
            );
            setTranslatorsDefaultFilters(translatorsFiltersObject);
        })
        .catch((err) => {
            console.error(err);
        });
};
