import React from "react";

import { INavigationButtonPropsTypes } from "./interfaces";

import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import NavigationTooltip from "components/NavigationTooltip";
import { Button, OverlayTrigger } from "react-bootstrap";

import "./NavigationButton.scss";

const NavigationButton: React.FunctionComponent<INavigationButtonPropsTypes> = ({
    flowSide,
    onClick,
    disabled,
    hasTooltip,
}) => {
    let buttonDirection = null;

    switch (flowSide) {
        case "left":
            buttonDirection = (
                <>
                    <FontAwesomeIcon size="lg" icon={faArrowLeft} />
                    <span>Prev</span>
                </>
            );
            break;
        case "right":
            buttonDirection = (
                <>
                    <span>Next</span>
                    <FontAwesomeIcon size="lg" icon={faArrowRight} />
                </>
            );
            break;
        default:
            break;
    }

    const navButtonWithDirection = (
        <Button key="nav-button" variant="light" size="lg" onClick={onClick} disabled={disabled}>
            {buttonDirection}
        </Button>
    );

    const navButton = (
        <div className={`NavigationButton ${flowSide}`}>
            {hasTooltip ? (
                <OverlayTrigger
                    overlay={<NavigationTooltip tooltipId={`breadcrumb-tooltip-${flowSide}`} />}
                    placement={flowSide || "bottom"}>
                    {navButtonWithDirection}
                </OverlayTrigger>
            ) : (
                navButtonWithDirection
            )}
        </div>
    );

    return navButton;
};
export default NavigationButton;
