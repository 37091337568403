const encodeIntoQueryParams = (obj) => {
    if (typeof obj !== "object" || obj === null) {
        return "";
    }

    return Object.entries(obj)
        .map(([key, value]) => {
            // Encode key and value
            key = encodeURIComponent(key);
            value = encodeURIComponent(typeof value === "object" ? JSON.stringify(value) : value);
            return `${key}=${value}`;
        })
        .join("&");
};

export default encodeIntoQueryParams;
