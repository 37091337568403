import React, { RefObject } from "react";

import { INavigationTooltipPropsTypes } from "./interfaces";

import { Tooltip } from "react-bootstrap";
import "./NavigationTooltip.scss";

const NavigationTooltip: React.FunctionComponent<INavigationTooltipPropsTypes> = React.forwardRef(
    ({ tooltipId, ...props }, ref) => {
        return (
            <Tooltip id={tooltipId} ref={ref as RefObject<HTMLDivElement>} {...props}>
                <p className="navigation-tooltip-paragraph">
                    Use <strong>Ctrl</strong> and <strong>&lt;</strong>&nbsp;or&nbsp;
                    <strong>&gt;</strong>
                </p>
            </Tooltip>
        );
    },
);

export default NavigationTooltip;
