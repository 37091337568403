import appState from "appState";

import { IFlowComparisonDataTypes, IFlowComparisonPageFilterTypes } from "./interfaces";
import { KEYS_TO_COMPARE } from "./variables";
import { calcStatusBarHeight, compareImages } from "utils/compareScreenshots";
import { getSections } from "models/getSections";
import { getFlow } from "models/getFlow";
import { pixelDiffRatioThreshold } from "components/Screenshot/helpers";

export const areFiltersIdentical = (
    firstFilter: IFlowComparisonPageFilterTypes,
    secondFilter: IFlowComparisonPageFilterTypes,
) => {
    return KEYS_TO_COMPARE.every((key) => firstFilter[key] === secondFilter[key]);
};

export const allScreenshotsAreIdentical = (comparisonData: IFlowComparisonDataTypes[]) => {
    //  looking at least one NOT identical screenshot
    if (comparisonData && comparisonData.length) {
        for (let i = 0; i < comparisonData.length; i++) {
            if (comparisonData[i].diffPixelRatio > pixelDiffRatioThreshold) return false;
        }
    }
    return true;
};

export const isFlowInTheColumn = (
    sectionsToCheck: { name: string; flows: { name: string }[] }[],
    flowSection: string,
    flowName: string,
): boolean => {
    return !!sectionsToCheck
        ?.filter((section: { name: string }) => section.name === flowSection)?.[0]
        ?.flows?.filter((fl: { name: string }) => fl.name === flowName)[0];
};

export const addSectionForEmptyFlow = (
    firstSections: { name: string; flows: { name: string }[] }[],
    secondSections: { name: string; flows: { name: string }[] }[],
    emptyFlow: { section: string; name: string },
): { name: string; flows: { name: string }[] }[] => {
    const newSections = [...secondSections];
    const newCurrentSection = firstSections?.filter(
        (section: { name: string }) => section.name === emptyFlow.section,
    )?.[0];

    if (newCurrentSection) {
        const sameSectionInTheAltColumn = secondSections?.filter(
            (section: { name: string }) => section.name === emptyFlow.section,
        )?.[0];

        if (sameSectionInTheAltColumn) {
            const index = newSections.findIndex(
                (section) => section.name === sameSectionInTheAltColumn.name,
            );

            newSections.splice(index, 1, newCurrentSection);
        } else {
            newSections.push(newCurrentSection);
        }
    }
    return newSections;
};

export const updateSections = (sectionsData: {
    isFlowInCurrentColumn: boolean;
    isFlowInSavedColumn: boolean;
    isFlowInOppositeSavedColumn: boolean;
    isFlowInOppositeColumn: boolean;
    currentsSections: { name: string; flows: { name: string }[] }[];
    currentAltSections: { name: string; flows: { name: string }[] }[];
    previousSections: { name: string; flows: { name: string }[] }[];
    previousAltSections: { name: string; flows: { name: string }[] }[];
    currentFlow: { name: string; section: string };
}): { name: string; flows: { name: string }[] }[] => {
    const {
        isFlowInCurrentColumn,
        isFlowInSavedColumn,
        isFlowInOppositeSavedColumn,
        isFlowInOppositeColumn,
        currentsSections,
        currentAltSections,
        previousSections,
        previousAltSections,
        currentFlow,
    } = sectionsData;

    let updatedSections: { name: string; flows: { name: string }[] }[] = [...currentsSections];

    if (!isFlowInCurrentColumn) {
        if (isFlowInSavedColumn) {
            updatedSections = previousSections;
        } else {
            if (isFlowInOppositeColumn && currentsSections?.length) {
                updatedSections = addSectionForEmptyFlow(
                    currentAltSections,
                    currentsSections,
                    currentFlow,
                );
            } else if (isFlowInOppositeColumn && !currentsSections?.length) {
                updatedSections = currentAltSections; // enable choosing from all flows. The choice is - one option, or all existing from alternative column,
                // because if currentsSections is empty, we can't find any breadcrumbs
            } else if (
                !isFlowInOppositeColumn &&
                isFlowInOppositeSavedColumn &&
                currentsSections?.length
            ) {
                updatedSections = addSectionForEmptyFlow(
                    previousAltSections,
                    currentsSections,
                    currentFlow,
                );
            } else if (
                !isFlowInOppositeColumn &&
                isFlowInOppositeSavedColumn &&
                !currentsSections?.length
            ) {
                updatedSections = previousAltSections; // enable choosing from all flows. The choice is - one option, or all existing from alternative column,
                // because if currentsSections is empty, we can't find any breadcrumbs
            }
        }
    }

    return updatedSections;
};
export const makeProgress = <T,>(
    allPromises: Promise<T>[],
    progressCallback: (progress: number) => void,
): Promise<T[]> => {
    let completed = 0;
    const totalPromises = allPromises.length;

    const reportProgress = () => {
        const progress = (completed / totalPromises) * 100;
        progressCallback(progress);
    };

    return Promise.all(
        allPromises.map((promise) =>
            promise.then(
                (result) => {
                    completed += 1;
                    reportProgress();
                    return result;
                },
                (error) => {
                    completed += 1;
                    reportProgress();
                    throw error;
                },
            ),
        ),
    );
};
async function getBatchedRequests<T, S>(
    ids: S[],
    requestFn: (id: S) => T,
    batchSize: number,
    componentEntity: { setState: (arg0: { progress: number }) => void },
) {
    const results = [];
    for (let i = 0; i < ids.length; i += batchSize) {
        const batch = ids.slice(i, i + batchSize);
        // eslint-disable-next-line no-await-in-loop
        const batchResults = await Promise.all(batch.map(requestFn));
        results.push(...batchResults);
        componentEntity.setState({
            progress: Math.ceil((results.length / ids.length) * 100),
        });
    }

    return results;
}

export const loadDifferentOnly = async (componentEntity: any) => {
    alert(
        "｡°⚠︎°｡｡°⚠︎°｡｡°⚠︎°｡\n\nPlease be aware that if \ntest failed / missing / liveshots flow is broken / flow is new and didn't exist in a previous version\nyou won't be able to see it after toggling on \"Different Only\". \n\nCheck test runs in TeamCity to be sure that you have all needed flows.",
    );
    const { firstSectionInfo, firstFilter, secondFilter } = componentEntity.state;

    componentEntity.setState({ progress: 0 });
    const updatedDiffFlows: number[] = [];

    const flowIds = firstSectionInfo?.sections.flatMap((section: { flows: { id: number }[] }) =>
        section.flows.map((flow: { id: number }) => flow?.id),
    );

    const batchRequest = async (id: string) => {
        const firstPromise = getFlow(id, firstFilter);
        const secondPromise = getFlow(id, secondFilter);

        type Flow = {
            screenshots: { height: string; width: string }[];
            id: number;
        };

        const promises: Promise<Flow | undefined>[] = [firstPromise, secondPromise];

        const [fFlow, sFlow] = await Promise.all(promises);
        const isBothHaveScreenshots = fFlow?.screenshots.length && sFlow?.screenshots.length;
        const isBothTheSameSize =
            fFlow?.screenshots?.[0]?.width === sFlow?.screenshots?.[0]?.width &&
            fFlow?.screenshots?.[0]?.height === sFlow?.screenshots?.[0]?.height;

        const incomparableReasons = [];
        const firstFlowScreens = fFlow?.screenshots;

        const secondFlowScreens = sFlow?.screenshots;

        if (firstFilter.platform !== secondFilter.platform) {
            incomparableReasons.push(
                `${firstFilter.platform} and ${secondFilter.platform} are incomparable`,
            );
        }
        if (firstFilter.device !== secondFilter.device) {
            incomparableReasons.push(
                `${firstFilter.device} and ${secondFilter.device} are incomparable`,
            );
        }

        const cropTopPx = calcStatusBarHeight(firstFilter.platform, firstFilter.device);

        await compareImages(firstFlowScreens, secondFlowScreens, cropTopPx, incomparableReasons)
            .then((compData) => {
                if (compData?.length) {
                    // We might need to take into account pixelDiffRatioThreshold here,
                    // something like (data.diffPixelRatio > pixelDiffRatioThreshold);
                    const haveDifference = compData?.some((data) => data.diffPixelRatio > pixelDiffRatioThreshold);

                    if (
                        isBothHaveScreenshots &&
                        isBothTheSameSize &&
                        compData?.length &&
                        haveDifference
                    ) {
                        if (!updatedDiffFlows?.includes(fFlow?.id)) {
                            updatedDiffFlows?.push(fFlow?.id);
                        }
                    }
                }
            })
            .catch((error) => {
                console.error("Sorry, no compare magic for today :(\n", error);
            });
    };

    await getBatchedRequests(flowIds, batchRequest, 20, componentEntity);
    componentEntity.setState({ differentFlows: updatedDiffFlows });
};

const compareImagesOnFly = (componentEntity: { props?: any; setState: any; state?: any }) => {
    const { firstFlow, secondFlow } = componentEntity.state;

    if (firstFlow && secondFlow) {
        const incomparableReasons = [];
        const currentAppState = appState.get();

        const firstFilter = currentAppState.flow;
        const firstFlowScreens = componentEntity.state.firstFlow?.screenshots;

        const secondFilter = currentAppState.altFlow || firstFilter;
        const secondFlowScreens = componentEntity.state.secondFlow?.screenshots;

        if (firstFilter.platform !== secondFilter.platform) {
            incomparableReasons.push(
                `${firstFilter.platform} and ${secondFilter.platform} are incomparable`,
            );
        }
        if (firstFilter.device !== secondFilter.device) {
            incomparableReasons.push(
                `${firstFilter.device} and ${secondFilter.device} are incomparable`,
            );
        }

        try {
            const cropTopPx = calcStatusBarHeight(firstFilter.platform, firstFilter.device);
            compareImages(firstFlowScreens, secondFlowScreens, cropTopPx, incomparableReasons).then(
                (comparisonData) => {
                    componentEntity.setState({ comparisonData });
                },
            );
        } catch (error) {
            console.error("Sorry, no compare magic for today :(\n", error);
        }
    }
};

export const fetchAll = (componentEntity: {
    props: any;
    setState: (
        arg0: {
            firstFilter: { [key: string]: string };
            secondFilter: { [key: string]: string };
            firstFlow: any;
            secondFlow: any;
            firstSectionInfo: { sections: any[]; finished: any };
            secondSectionInfo: { sections: any[]; finished: any };
        },
        arg1: () => void,
    ) => void;
}) => {
    const { firstFlowId, secondFlowId } = componentEntity.props.match.params;

    const currentAppState = appState.get();
    const firstFilter = currentAppState.flow;
    const secondFilter = currentAppState.altFlow || firstFilter;

    const promises = [
        getFlow(firstFlowId, firstFilter),
        getFlow(secondFlowId, secondFilter),
        getSections(firstFilter, null, false),
        getSections(secondFilter, null, false),
    ];

    Promise.all(promises).then(([firstFlow, secondFlow, firstSectionInfo, secondSectionInfo]) => {
        let existingLeftSections = [...firstSectionInfo.sections];
        let existingRightSections = [...secondSectionInfo.sections];

        const isFlowInLeftColumn = isFlowInTheColumn(
            existingLeftSections,
            firstFlow.section,
            firstFlow.name,
        );

        const isFlowInRightColumn = isFlowInTheColumn(
            existingRightSections,
            secondFlow.section,
            secondFlow.name,
        );

        const isFlowInOppositeLeftColumn = isFlowInTheColumn(
            existingLeftSections,
            secondFlow.section,
            secondFlow.name,
        );

        const isFlowInOppositeRightColumn = isFlowInTheColumn(
            existingRightSections,
            firstFlow.section,
            firstFlow.name,
        );

        const savedLeftSections = sessionStorage.getItem("leftSections") || "[]";
        const savedRightSections = sessionStorage.getItem("rightSections") || "[]";

        const previousLeftSections = JSON.parse(savedLeftSections);
        const previousRightSections = JSON.parse(savedRightSections);

        const isFlowInSavedLeftColumn = isFlowInTheColumn(
            previousLeftSections,
            firstFlow.section,
            firstFlow.name,
        );

        const isFlowInSavedRightColumn = isFlowInTheColumn(
            previousRightSections,
            secondFlow.section,
            secondFlow.name,
        );
        const isFlowInSavedOppositeRightColumn = isFlowInTheColumn(
            previousRightSections,
            firstFlow.section,
            firstFlow.name,
        );

        const isFlowInSavedOppositeLeftColumn = isFlowInTheColumn(
            previousLeftSections,
            secondFlow.section,
            secondFlow.name,
        );

        const leftSections = {
            isFlowInCurrentColumn: isFlowInLeftColumn,
            isFlowInSavedColumn: isFlowInSavedLeftColumn,
            isFlowInOppositeSavedColumn: isFlowInSavedOppositeRightColumn,
            isFlowInOppositeColumn: isFlowInOppositeRightColumn,
            currentsSections: existingLeftSections,
            currentAltSections: existingRightSections,
            previousSections: previousLeftSections,
            previousAltSections: previousRightSections,
            currentFlow: firstFlow,
        };

        const rightSections = {
            isFlowInCurrentColumn: isFlowInRightColumn,
            isFlowInSavedColumn: isFlowInSavedRightColumn,
            isFlowInOppositeSavedColumn: isFlowInSavedOppositeLeftColumn,
            isFlowInOppositeColumn: isFlowInOppositeLeftColumn,
            currentsSections: existingRightSections,
            currentAltSections: existingLeftSections,
            previousSections: previousRightSections,
            previousAltSections: previousLeftSections,
            currentFlow: secondFlow,
        };

        existingLeftSections = updateSections(leftSections);
        existingRightSections = updateSections(rightSections);

        componentEntity.setState(
            {
                firstFilter,
                secondFilter,
                firstFlow,
                secondFlow,
                firstSectionInfo: {
                    sections: existingLeftSections,
                    finished: firstSectionInfo?.finished,
                },
                secondSectionInfo: {
                    sections: existingRightSections,
                    finished: secondSectionInfo?.finished,
                },
            },
            () => compareImagesOnFly(componentEntity),
        );
        sessionStorage.setItem("leftSections", JSON.stringify(existingLeftSections));
        sessionStorage.setItem("rightSections", JSON.stringify(existingRightSections));
    });
};

export const fetchFlows = (flowId: number, componentEntity: this) => {
    const { firstFilter, secondFilter, comparisonData } = componentEntity.state;

    if (firstFilter && secondFilter) {
        const firstPromise = getFlow(flowId, firstFilter);
        const secondPromise = getFlow(flowId, secondFilter);
        const promises = [firstPromise, secondPromise];

        // dataUrl should be empty string or "falsy" value
        // because based on that we either generate loader or image
        const resetImageData = {
            comment: null,
            dataUrl: "",
            diffPixelRatio: 0,
            isComparable: true,
        };

        Promise.all(promises).then(([firstFlow, secondFlow]) => {
            // flows min length to generate right amount of reset images
            // (could be max - it's just preference at this point,
            // depends on if we need to show comparison for flows
            // when the same flow in different versions has different
            // amount of screenshots)
            const flowsLength = Math.min(
                firstFlow?.screenshots?.length,
                secondFlow?.screenshots?.length,
            );

            // create array of reset images
            const resetImagesArray = Array.from({ length: flowsLength }, (_, i) => resetImageData);

            const isBothHaveScreenshots =
                firstFlow?.screenshots.length && secondFlow?.screenshots.length;
            const isBothTheSameSize =
                firstFlow?.screenshots?.[0]?.width === secondFlow?.screenshots?.[0]?.width &&
                firstFlow?.screenshots?.[0]?.height === secondFlow?.screenshots?.[0]?.height;

            const resetComparisonData = [];

            // only if there is something to compare (comparisonData exists)
            // and both flows have screenshots (isBothHaveScreenshots: true),
            // and both screenshots are the same size (isBothTheSameSize: true)
            // then we update data in the middle row and add array of reset images,
            // otherwise we just send an empty array which allow us do not render the middle row at all
            // and remove stale images data at the same time
            if (comparisonData?.length && isBothHaveScreenshots && isBothTheSameSize)
                resetComparisonData?.push(...resetImagesArray);

            componentEntity.setState({
                firstFlow,
                secondFlow,
                comparisonData: resetComparisonData,
            });
            appState.setPath(`/flow-comparison/${flowId}/${flowId}`);
        });
    }
};

export const changeFlowId = (
    side: string,
    flowId: number,
    filters: any,
    componentEntity: { state: { firstFlow: any; secondFlow: any } },
) => {
    let newState = "/flow-comparison/";
    const { firstFlow, secondFlow } = componentEntity.state;

    let newFilters = { ...filters };

    if (firstFlow && secondFlow) {
        if (side === "altFlow") {
            newFilters = { altFlow: filters };
            newState += `${componentEntity.state.firstFlow?.id}/${flowId}`;
        } else {
            newFilters = { flow: filters };
            newState += `${flowId}/${componentEntity.state.secondFlow?.id}`;
        }
        appState.set(newFilters, false, newState);
    }
};
