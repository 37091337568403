import React, { useState, useCallback } from "react";

import { IModalWindowPropsTypes } from "./interfaces";

import { Button, Modal } from "react-bootstrap";

import "./ModalWindow.scss";

const ModalWindow: React.FunctionComponent<IModalWindowPropsTypes> = ({
    modalButtonName,
    isModalButtonDisabled,
    modalSize,
    modalTitle,
    modalBody,
    modalCustomClass,
    onApplyChanges,
    variant,
    buttonSize,
    buttonCustomClass,
    children,
}) => {
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toggleModal = useCallback(() => {
        setIsModalOpen((prevIsModalOpen) => !prevIsModalOpen);
    }, []);

    const applyModalChanges = useCallback(() => {
        if (onApplyChanges) onApplyChanges();
        toggleModal();
    }, [onApplyChanges, toggleModal]);

    return (
        <>
            <Button
                key={modalButtonName}
                block={true}
                className={buttonCustomClass || ""}
                disabled={isModalButtonDisabled}
                onClick={toggleModal}
                variant={variant || "primary"}
                size={buttonSize || undefined}>
                {modalButtonName}
            </Button>

            <Modal
                key={modalTitle}
                show={isModalOpen}
                size={modalSize}
                aria-labelledby="contained-modal-title-vcenter"
                className={modalCustomClass ? `modal--${modalCustomClass}` : ""}
                centered={true}
                onHide={toggleModal}>
                <Modal.Header closeButton={true}>
                    <Modal.Title id="contained-modal-title-vcenter">{modalTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{modalBody}</Modal.Body>
                <Modal.Footer>
                    {children || <Button onClick={applyModalChanges}>Apply</Button>}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalWindow;
