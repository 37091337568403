import React, { useEffect, useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";

import "./TripleRow.scss";

const TripleRow = ({ left, middle, right }) => {
    const elementRef = useRef(null);
    const [tripleRowClassName, setTripleRowClassName] = useState("");

    useEffect(() => {
        elementRef.current = left;

        if (elementRef?.current?.props?.className) {
            setTripleRowClassName(` triple-row-${elementRef?.current?.props?.className}`);
        } else if (elementRef?.current?.key) {
            setTripleRowClassName(` triple-row-${elementRef?.current?.key}`);
        }
    }, [left]);

    let mdwidth = 6;

    if (middle) {
        mdwidth = 4;
    }

    return (
        <Row className={`TripleRow${tripleRowClassName}`}>
            <Col xs={12} md={mdwidth}>
                {left}
            </Col>
            {middle ? (
                <Col xs={12} md={mdwidth}>
                    {middle}
                </Col>
            ) : null}
            <Col xs={12} md={mdwidth}>
                {right}
            </Col>
        </Row>
    );
};

export default TripleRow;
