import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import prettyString from "utils/prettyString";
import parseSearchString from "utils/parseSearchString";

import { filterFlows } from "./helpers";
import { ISectionPropsTypes } from "./interfaces";

import FlowItem from "components/FlowItem";
import { Card, ListGroup } from "react-bootstrap";

const Section: React.FunctionComponent<ISectionPropsTypes> = ({
    section,
    search,
    reassignedFlowsStatus,
    handleCheckbox,
    isChooseFlowsModalOpen,
    translatorSelectedFlows,
    translatorId,
    showReassigning,
    taskId,
    taskFlowsToShowList,
}) => {
    const flowsToShow = filterFlows(section.flows, taskFlowsToShowList);

    const params = parseSearchString();
    const isTask = taskId || params.get("taskId");

    const cardHeaderClass = isChooseFlowsModalOpen || isTask ? "non-clickable" : "";

    const cardHeader = (
        <Card.Header className={cardHeaderClass}>
            <strong>{prettyString(section.name)}</strong>
            <div>{section.description}</div>
        </Card.Header>
    );
    return flowsToShow.length ? (
        <Card>
            {isChooseFlowsModalOpen || isTask ? (
                cardHeader
            ) : (
                <LinkContainer to={`/section/${section.id}${search}`}>{cardHeader}</LinkContainer>
            )}

            <ListGroup variant="flush">
                {flowsToShow.map((flowItem) => (
                    <FlowItem
                        key={flowItem.id}
                        isChooseFlowsModalOpen={isChooseFlowsModalOpen}
                        flowItem={flowItem}
                        search={search}
                        reassignedFlowsStatus={reassignedFlowsStatus}
                        handleCheckbox={handleCheckbox}
                        translatorSelectedFlows={translatorSelectedFlows}
                        translatorId={translatorId}
                        showReassigning={showReassigning}
                        taskId={isTask}
                    />
                ))}
            </ListGroup>
        </Card>
    ) : null;
};

export default Section;
