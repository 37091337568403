export const KEYS_TO_COMPARE = [
    "product",
    "platform",
    "device",
    "locale",
    "version",
    "os",
    "branch",
    "pod",
];
