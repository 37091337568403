import encodeIntoQueryParams from "./encodeIntoQueryParams";

export const removeAdditionalParametersFromSearch = (
    search: string | string[][] | Record<string, string> | URLSearchParams | undefined,
) => {
    const searchParams = new URLSearchParams(search);
    const newSearchParams: { [key: string]: string } = {};

    searchParams.delete("altFlow");
    searchParams.delete("tcBuildId");
    searchParams.delete("showComparison");
    searchParams.delete("excludeIdentical");
    searchParams.delete("differentOnly");
    searchParams.delete("isReassignMode");

    searchParams.forEach((value, key) => {
        newSearchParams[key] = value;
    });

    return encodeIntoQueryParams(newSearchParams);
};
