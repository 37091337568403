export const flattenCurrentCrumbs = (
    crumbs: { id: number; isCurrent: boolean; name: string; url: string }[],
) => {
    let remainingCrumbs = crumbs;

    const flatCrumbs = [];

    while (remainingCrumbs) {
        flatCrumbs.push(remainingCrumbs);
        let currentCrumb = remainingCrumbs.find((crumb) => crumb.isCurrent);
        currentCrumb = currentCrumb || remainingCrumbs[0];
        remainingCrumbs = currentCrumb && currentCrumb.crumbs;
    }
    return flatCrumbs;
};
