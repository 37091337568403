import React from "react";
import ReactLoading from "react-loading";

export default function ScreenshotStatPane({ isExpanded, isLoading, children }) {
    return (
        <div className={`ScreenshotStatPane ${isExpanded ? "expanded" : ""}`}>
            {children}
            {isLoading && (
                <ReactLoading
                    type="bubbles"
                    color="#888"
                    className="AbsoluteCentered"
                    delay={300}
                />
            )}
        </div>
    );
}
