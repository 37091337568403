import { get } from "../api";
import { cache } from "utils/cache";

export const getScreenshotCompare = cache(function (screenshotId, selectedFilters) {
    return get(`screenshots/${screenshotId}/compare`, selectedFilters).then((screenshots) => {
        return screenshots.map((screenshot) => {
            return {
                ...screenshot,
                resolution: `${screenshot.resolution.width}x${screenshot.resolution.height}`,
                width: screenshot.resolution.width,
                height: screenshot.resolution.height,
            };
        });
    });
});
