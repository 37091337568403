import _ from "lodash";
import encodeIntoQueryParams from "utils/encodeIntoQueryParams";
import decodeQueryParams from "utils/decodeQueryParams";

const SERIALIZABLE_KEYS = [
    "flow",
    "altFlow",
    "taskId",
    "showComparison",
    "excludeIdentical",
    "differentOnly",
];

class AppState {
    constructor() {
        this.state = {};
    }

    setHistory(history) {
        this.history = history;
    }

    setPath(path) {
        this.history.push({
            pathname: path,
            search: this.history.location.search,
        });
    }

    set(state, shouldReplace, path) {
        const currentState = this.get();
        const newState = Object.assign({}, currentState, state);

        Object.keys(newState).forEach((stateKey) => {
            Object.keys(newState[stateKey]).forEach((key) => {
                if (newState[stateKey][key] === null) delete newState[stateKey][key];
            });
        });

        if (_.isEqual(currentState, newState)) {
            return;
        }

        const urlState = {};
        const storedState = {};

        Object.keys(newState).forEach((key) => {
            if (SERIALIZABLE_KEYS.indexOf(key) === -1) {
                storedState[key] = newState[key];
            } else {
                urlState[key] = newState[key];
            }
        });

        Object.assign(this.state, storedState);

        const historyUpdateNeeded = Object.keys(state).some((key) => {
            return SERIALIZABLE_KEYS.indexOf(key) !== -1;
        });

        if (!historyUpdateNeeded) {
            return;
        }
        if (path === undefined) {
            path = this.history.location.pathname;
        }
        if (shouldReplace === true) {
            this.history.replace({
                pathname: path,
                search: encodeIntoQueryParams(urlState),
            });
        } else {
            this.history.push({
                pathname: path,
                search: encodeIntoQueryParams(urlState),
            });
        }
    }

    get(key) {
        const urlState = decodeQueryParams(this.history.location.search);
        const storedState = this.state;
        const state = Object.assign({}, storedState, urlState);
        return key ? state[key] : state;
    }

    delete(key) {
        const urlState = decodeQueryParams(this.history.location.search);

        delete urlState[key];

        this.history.replace({
            pathname: this.history.location.pathname,
            search: encodeIntoQueryParams(urlState),
        });
    }

    encodeState(newState) {
        const state = this.get();

        if (newState) {
            Object.assign(state, newState);
        }

        const urlState = {};

        Object.keys(state).forEach((key) => {
            if (SERIALIZABLE_KEYS.indexOf(key) !== -1) {
                urlState[key] = state[key];
            }
        });

        return encodeIntoQueryParams(urlState);
    }
}

const appState = new AppState();
export default appState;
