import React, { Component } from "react";
import ReactLoading from "react-loading";
import { withRouter } from "react-router-dom";

import { post } from "../../api";

import decodeQueryParams from "utils/decodeQueryParams";

class QaapiPage extends Component {
    componentDidMount() {
        if (isStaging()) {
            const urlState = decodeQueryParams(this.props.history.location.search);
            if (Object.keys(urlState).includes("role")) {
                changeRole(urlState.role).then(() => {
                    window.history.back();
                });
            }
        }
    }

    render() {
        return (
            <ReactLoading type="bubbles" color="#888" className="AbsoluteCentered" delay={300} />
        );
    }
}

export default withRouter(QaapiPage);

function isStaging() {
    const domain = "liveshots.d4";
    const local = "localhost";
    return (
        window.location.href.indexOf(domain) !== -1 || window.location.href.indexOf(local) !== -1
    );
}

function changeRole(value) {
    const roles = ["user", "translator_manager", "translator_translator"];
    if (roles.includes(value)) {
        return post("qaapi/change_role", { role: value });
    }
}
