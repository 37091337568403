import React, { useCallback, useEffect, useRef, useState } from "react";

import { IScreenshotVerificationPropsTypes } from "./interfaces";

import VerificationButton from "components/VerificationButton";
import { Form } from "react-bootstrap";

const ScreenshotVerification: React.FunctionComponent<IScreenshotVerificationPropsTypes> = ({
    screenshotId,
    screenshotName,
    flowId,
    taskId,
    setScreenshotsVerificationData,
    screenshotsVerificationData,
    screenshotsIds,
    setFlowPageStateFlowStatus,
    setIsTaskFinishedStateStatus,
    isTaskFinished,
}) => {
    const [isAllowedToSave, setIsAllowedToSave] = useState(true);

    const commentRef = useRef<HTMLTextAreaElement | null>(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setCommentHeight = () => {
        if (commentRef.current) {
            commentRef.current.style.height = "auto";
            commentRef.current.style.height = `calc(${commentRef.current.scrollHeight}px + 0.375rem)`;
        }
    };

    useEffect(() => {
        setCommentHeight();
        if (screenshotsVerificationData?.[screenshotId]?.comment) {
            setIsAllowedToSave(true);
        }
    }, [screenshotId, screenshotsVerificationData, setCommentHeight]);

    const handleChange = useCallback(
        (event) => {
            setScreenshotsVerificationData({
                ...screenshotsVerificationData,
                [screenshotId]: {
                    ...screenshotsVerificationData[screenshotId],
                    comment: event.target.value,
                },
            });
        },
        [screenshotId, screenshotsVerificationData, setScreenshotsVerificationData],
    );

    const screenshotVerificationData = {
        screenshotId,
        screenshotName,
        flowId,
        taskId,
        comment: screenshotsVerificationData?.[screenshotId]?.comment,
        setScreenshotsVerificationData,
        screenshotsVerificationData,
        isAllowedToSave,
        setIsAllowedToSave,
        screenshotsIds,
        setFlowPageStateFlowStatus,
        setIsTaskFinishedStateStatus,
    };
    return (
        <Form className={`ScreenshotVerification${isAllowedToSave ? "" : " required"}`}>
            <Form.Control
                as="textarea"
                ref={commentRef}
                placeholder={!isTaskFinished ? "add comment..." : "no comment"}
                aria-label="add comment for screenshot verification"
                value={screenshotsVerificationData?.[screenshotId]?.comment || ""}
                onChange={handleChange}
                rows={1}
                disabled={isTaskFinished}
            />

            {!isTaskFinished ? (
                <Form.Group className="screenshot-verification-buttons">
                    <VerificationButton screenshotVerificationData={screenshotVerificationData} />
                    <VerificationButton
                        isTypeFailed={true}
                        screenshotVerificationData={screenshotVerificationData}
                    />
                </Form.Group>
            ) : null}
        </Form>
    );
};

export default ScreenshotVerification;
