const PLATFORM_MAP = {
    android: "Android",
    ios: "iOS",
    mobileweb: "Mobile Web",
};

const prettyPlatform = (platform: string) => {
    return PLATFORM_MAP[platform.toLowerCase()] || platform;
};
export default prettyPlatform;
