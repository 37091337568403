import React, { useEffect } from "react";
import { Redirect, Route, RouteProps, useHistory, useLocation } from "react-router-dom";

import appState from "appState";

import decodeQueryParams from "utils/decodeQueryParams";
import parseSearchString from "utils/parseSearchString";
import Auth from "utils/services/Auth";

import Navigation from "components/Navigation";
import NavigationTask from "components/NavigationTask";

interface IPrivateRouteProps extends RouteProps {
    component: React.ComponentType<any>;
    title?: string;
    isTask?: boolean; // Add any other props you need
}

const PrivateRoute: React.FC<IPrivateRouteProps> = ({ component: Component, title, ...rest }) => {
    useEffect(() => {
        document.title = `LiveShots - ${title}`;
    }, [title]);

    const params = parseSearchString();
    const isTask = params.get("taskId");

    const history = useHistory();
    const location = useLocation();

    // Support custom URL params (platform and product)
    const urlState = decodeQueryParams(history.location.search);

    if (urlState.platform && urlState.product) {
        appState.set({
            flow: {
                platform: urlState.platform,
                product: urlState.product,
            },
        });
    }
    return (
        <Route
            {...rest}
            render={(props) =>
                Auth.isLoggedIn() ? (
                    <>
                        {isTask ? <NavigationTask /> : <Navigation />}
                        <Component {...props} />
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            search: location.search,
                            state: { from: props.location },
                        }}
                    />
                )
            }
        />
    );
};

export default PrivateRoute;
